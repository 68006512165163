import React, { useRef } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'

import { INRWorkoutSessionExercise, INRWorkoutSessionHit } from 'interfaces/workout'
import { getMaxForce } from 'lib/stats'
import { convertHitsToTimedHits, getPercentage } from 'lib/utilities'
import { useCurrentCount, useCurrentExercise } from 'store/workout/selectors'
import Bar from './bar-exercise'

import './chart-rounds.scss'

const Bars = ({ exercise, currentCount }: { exercise: INRWorkoutSessionExercise; currentCount: number }) => {
  let start = 0
  const end = exercise.seconds - 1

  const barsPerSecond = []
  const maxPercentage = Math.max(...exercise.hits.map((hit: INRWorkoutSessionHit) => getPercentage(hit.force)))
  const timedHits = convertHitsToTimedHits(exercise.hits, exercise.started)

  while (start <= end) {
    const currentStart = start;
    let bars = null
    if (timedHits[start]) {
      bars = timedHits[currentStart].map((hit: INRWorkoutSessionHit, hitIndex: number) => {
        const height = (getPercentage(hit.force) / maxPercentage) * 100;
        return <Bar key={hitIndex} height={height} />;
      });
    } else {
      bars = null
    }

    if ((bars && bars.length) || exercise.seconds - currentCount > start - 1) {
      barsPerSecond.push(
        <div className={`ChartEx__roundssec ${start}`} key={`${start}-${start}`}>
          {bars}
        </div>
      )
    }
    start++
  }

  return <>{barsPerSecond}</>
}

const ChartExercise = () => {
  const container = useRef<HTMLElement>(null)
  const currentExercise = useCurrentExercise()
  const currentCount = useCurrentCount()

  const maxForce = getMaxForce(currentExercise.hits)

  const calcAverage = () => {
    let total: number = 0
    currentExercise.hits.forEach((b: INRWorkoutSessionHit) => (total += getPercentage(b.force)))
    return Math.round(total / currentExercise.hits.length) + '%'
  }

  return (
    <div className="ChartEx history">
      <div className="ChartEx__wrapper">
        <div className="ChartEx__yaxis">
          <span>Force</span>
          <span>{Math.round(maxForce)}</span>
        </div>
        <div className="ChartEx__bars">
          {Array.from({ length: 6 }).map((_, index) => (
            <div key={index} className={`ChartEx__yaxis__line ChartEx__yaxis__line--${index + 1}`}></div>
          ))}
          <div className="ChartEx__average" style={{ bottom: `calc(${calcAverage()} + 7px)` }}></div>
          <ScrollContainer innerRef={container} className="ChartEx__scrollable" vertical={false}>
            <div className="ChartEx__roundsbars">{<Bars currentCount={currentCount} exercise={currentExercise} />}</div>
          </ScrollContainer>
        </div>
      </div>
    </div>
  )
}

export default ChartExercise
