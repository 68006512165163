import { ReactNode, useEffect } from 'react'
import { ScrollRestoration, useNavigate } from 'react-router-dom'
import Popup from 'components/popup/main'
import Snackbar from 'components/snackbar'
import useConnection from 'lib/hooks/useConnection'
import handleStorage, { TOKENNAME } from 'lib/handleStorage'
import { pathsObj } from 'lib/constants/paths'

interface Props {
  children: ReactNode
  className?: string
}

const Main = ({ children, className = '' }: Props) => {
  const navigate = useNavigate()
  useConnection()

  useEffect(() => {
    const { stored: apiKey } = handleStorage(TOKENNAME.api)
    if (!apiKey) navigate(pathsObj.main.api)
  }, [navigate])

  return (
    <main className={className}>
      <Popup />
      <Snackbar />
      <ScrollRestoration getKey={(location) => location.pathname} />
      {children}
    </main>
  )
}

export default Main
