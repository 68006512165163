import React from 'react'
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap-grid.min.css'

import Home from 'pages/home'
import ApiPage from 'pages/api-key-page'
import Workouts from 'pages/workouts'
import WorkoutComplete from 'pages/workout-complete'
import WorkoutOverview from 'pages/workout-overview'
import RoundsExerciseCountdown from 'pages/round-exercise-countdown'
import RoundCurrentOverview from 'pages/round-overview'
import RoundComplete from 'pages/round-complete'
import RoundActive from 'pages/round-active'
import FreestyleQuickstartActive from 'pages/freestyle-quickstart-active'
import PleaseRotate from 'components/please-rotate/please-rotate'
import { pathsObj } from 'lib/constants/paths'
import ErrorBoundary from 'components/error'
import 'styles/globals.scss'
import 'styles/main.scss'

const App = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path={pathsObj.main.home} element={<Home />} />
        <Route path={pathsObj.main.api} element={<ApiPage />} />
        <Route path={pathsObj.workouts.workouts} element={<Workouts />} />
        <Route path={pathsObj.workouts.workoutComplete} element={<WorkoutComplete />} />
        <Route path={pathsObj.workouts.workoutOverview.default} element={<WorkoutOverview />} />
        <Route path={pathsObj.rounds.exerciseCountdown} element={<RoundsExerciseCountdown />} />
        <Route path={pathsObj.rounds.roundOverview} element={<RoundCurrentOverview />} />
        <Route path={pathsObj.rounds.roundComplete} element={<RoundComplete />} />
        <Route path={pathsObj.rounds.roundActive} element={<RoundActive />} />
        <Route path={pathsObj.freestyle.freestyleActive} element={<FreestyleQuickstartActive />} />
      </>
    )
  )

  return (
    <ErrorBoundary>
      <PleaseRotate />
      <RouterProvider router={router} />
    </ErrorBoundary>
  )
}

export default App
