import React, { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useNavigate } from 'react-router-dom'

import { useWeightQuery } from 'api/queries/weight'
import { useHitSubscription } from 'api/subscriptions/hit'
import { useWorkoutActions, useWorkoutSession } from 'store/workout/selectors'
import { useSetPopup } from 'store/config/selectors'
import { useIsloggedIn, useUserWeight } from 'store/auth/selectors'
import useWorkoutComplete from 'lib/hooks/useWorkoutComplete'
import useStartQuickstart from 'lib/hooks/useStartQuickstart';
import { getTotalForce, getWorkoutDuration, getWorkoutHits } from 'lib/stats'
import { getAverage, toFullTime } from 'lib/utilities'
import { getActiveWeight } from 'lib/weight'
import withIdleTimer from 'lib/HOC/withIdleTimer'
import { pathsObj } from 'lib/constants/paths'
import Main from 'layout/main'
import Button from 'components/button'
import Header from 'components/header/header'
import Icon, { IconNames } from 'components/icons/icons'
import GraphWorkoutComplete from 'components/graph-rounds/graph-rounds'
import StatsWorkoutComplete from 'components/stats/stats-workout-complete'
import HitMeBtn from 'components/hit-me-btn/hit-me-btn'
import WorkoutFeedback from 'components/workout-feedback/workout-feedback'
import { getEvaluatedWorkoutIntensity } from 'lib/intensity'

// Results screen after workout of any kind is completed
const WorkoutComplete = () => {
  const navigate = useNavigate()
  const { data } = useHitSubscription()
  const { data: weightData } = useWeightQuery()
  const { setQuitWorkoutPopup, closePopup } = useSetPopup()
  const userWeight = useUserWeight()
  const weight = getActiveWeight(weightData, userWeight)
  const isLoggedIn = useIsloggedIn()
  const workoutSession = useWorkoutSession()
  const { updateWorkoutSession } = useWorkoutActions()
  useWorkoutComplete()
  const { startQuickstart } = useStartQuickstart(false);

  const workoutDuration = getWorkoutDuration(workoutSession)
  const workoutSessionHits = getWorkoutHits(workoutSession)
  const totalForce = Math.round(getTotalForce(workoutSessionHits))
  const formattedDuration = toFullTime(workoutDuration)

  const intensityWorkout = getEvaluatedWorkoutIntensity(workoutSession, weight)
  const avgIntensity = getAverage(intensityWorkout)
  const roundedAvgIntensity = Math.round(avgIntensity * 10) / 10

  const handleIdle = () => {
    if (isLoggedIn) return
    closePopup()
    navigate(pathsObj.main.home)
  }

  useIdleTimer({
    timeout: 1000 * 60 * 15, // 15 min
    onIdle: handleIdle,
    startOnMount: true,
  })

  const [enableHitToQuickstart, setEnableHitToQuickstart] = useState<number | null>(null)
  useEffect(() => {
    // enable return to quickstart-countdown by punching after 60 sec
    const timeout = setTimeout(() => setEnableHitToQuickstart(new Date().getTime()), 60000)
    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    if (!data || !data.hit || !enableHitToQuickstart) return
    const hitTime = new Date(parseInt(data.hit.punchedAt)).getTime()
    if (hitTime > enableHitToQuickstart) {
      closePopup()
      startQuickstart();
      navigate(pathsObj.freestyle.freestyleActive)
    }
  }, [data, enableHitToQuickstart, navigate, closePopup, updateWorkoutSession, startQuickstart])

  return (
    <Main className="nextround">
      <div className="WorkoutComplete">
        <Header title={<h1>Overview workout</h1>} />
        <div className="row workoutComplete__topRow">
          <div className="col-8 WorkoutComplete__head">
            <h5>{workoutSession.rounds.length} rounds completed</h5>
            <div className="WorkoutComplete__workout-details">
              <Icon name={IconNames.CLOCK} size={24} />
              <h4 className="light">
                <span>{formattedDuration}</span>
              </h4>
            </div>
            {enableHitToQuickstart && (
              <h5 className="WorkoutComplete__hitthebag">Hit the bag to start quickstart!</h5>
            )}
          </div>
          <div className="col-4 WorkoutComplete__exit">
            <Button customClickEvent={setQuitWorkoutPopup} color="secondary">
              <h3>Exit</h3>
            </Button>
          </div>
        </div>
        <div className="row WorkoutComplete__workout-info">
          <div className="col-9 WorkoutComplete__workout-info__details">
            <div className="box WorkoutComplete__workout-info__details__bigStats">
              <div>
                <h3 className="gray light">Total Force</h3>
                <h3>{totalForce}</h3>
              </div>
              <div>
                <h3 className="gray light">Avg. Intensity</h3>
                <h3>{roundedAvgIntensity}</h3>
              </div>
              <div>
                <h3 className="gray light">Hits</h3>
                <h3>{workoutSessionHits.length}</h3>
              </div>
            </div>
            <div className="row WorkoutComplete__workout-info__details__graph">
              <div className="col-12">
                <GraphWorkoutComplete />
              </div>
            </div>
            <div className="box WorkoutComplete__workout-info__details__stats">
              <StatsWorkoutComplete />
            </div>
          </div>
          <div className="col-3">
            <WorkoutFeedback />
          </div>
          <div className="col-4 offset-8 save" />
        </div>
      </div>
      <HitMeBtn />
    </Main>
  )
}

export default withIdleTimer(WorkoutComplete)
