import { DifficultyLevel, INRWorkoutSession, WorkoutName, WorkoutType } from 'interfaces/workout'
import { WorkoutStore } from 'store/workout'

export const getInitWorkoutStore = (): Omit<WorkoutStore, 'actions'> => {
  return {
    savedWorkoutId: '',
    workoutIsSaved: false,
    workoutSession: getInitWorkoutSession(),
    currentCount: 0,
    activeRound: 0,
    activeExercise: 0,
  }
}

export const getInitWorkoutSession = (): INRWorkoutSession => {
  return {
    id: null,
    name: null,
    level: DifficultyLevel.PRO,
    workoutType: WorkoutType.BOXING,
    rounds: [
      {
        breakSeconds: 0,
        countdownSeconds: 0,
        exercises: [
          {
            started: null,
            breakSeconds: 0,
            seconds: 0,
            hits: [],
            challenges: null,
          },
        ],
      },
    ],
  }
}

export const getInitQuickstartSession = (): INRWorkoutSession => {
  return {
    id: null,
    name: WorkoutName.QUICKSTART,
    level: DifficultyLevel.PRO,
    workoutType: WorkoutType.BOXING,
    rounds: [
      {
        exercises: [{ seconds: 180, breakSeconds: 0, hits: [], challenges: null, started: null }],
        breakSeconds: 0,
        countdownSeconds: 0,
      },
    ],
  }
}
