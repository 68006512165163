import { useEffect, useState } from 'react'
import {
  DifficultyLevel,
  INRWorkoutSession,
  WorkoutName,
  WorkoutType,
  INRWorkoutSessionRound,
} from 'interfaces/workout'

import { useWorkoutActions } from 'store/workout/selectors'

const useFreestyleSetup = () => {
  const { updateWorkoutSession } = useWorkoutActions()
  const [rounds, setRounds] = useState(2)
  const [seconds, setSeconds] = useState(60)
  const [rest, setRest] = useState(60)

  const addSeconds = () => {
    if (seconds > 14) {
      setSeconds(seconds + 15);
    } else {
      setSeconds(seconds + 1);
    }
  };
  const removeSeconds = () => {
    if (seconds > 15) {
      setSeconds(seconds - 15);
    } else if (seconds > 1) {
      setSeconds(seconds - 1);
    } else {
      setSeconds(1);
    }
  };
  const addRound = () => setRounds(rounds + 1)
  const removeRound = () => (rounds > 1 ? setRounds(rounds - 1) : setRounds(1))
  const addRest = () => {
    if (rest > 14) {
      setRest(rest + 5);
    } else {
      setRest(rest + 1);
    }
  };
  const removeRest = () => {
    if (rest > 15) {
      setRest(rest - 5);
    } else if (rest > 1) {
      setRest(rest - 1);
    } else {
      setRest(1);
    }
  };

  useEffect(() => {
    const roundsArr: INRWorkoutSessionRound[] = []

    for (let index = 0; index < (rounds || 0); index++) {
      roundsArr.push({
        countdownSeconds: 10,
        breakSeconds: index === rounds - 1 ? 15 : rest,
        exercises: [
          {
            breakSeconds: 0,
            seconds,
            hits: [],
            challenges: null,
            started: null,
          },
        ],
      })
    }

    const freestyleSession: INRWorkoutSession = {
      id: null,
      name: WorkoutName.FREESTYLE,
      workoutType: WorkoutType.KICKBOXING,
      level: DifficultyLevel.STARTER,
      rounds: roundsArr,
    }
    updateWorkoutSession(freestyleSession)
  }, [updateWorkoutSession, rest, rounds, seconds])

  return {
    rounds,
    seconds,
    rest,
    addSeconds,
    removeSeconds,
    addRound,
    removeRound,
    addRest,
    removeRest,
    // startFreestyle,
  }
}

export default useFreestyleSetup
