import initApollo from 'api/client'
import { SERVER_TIME } from 'api/queries/server-time'
import { useWorkoutActions } from 'store/workout/selectors'

// exercise started timeStamp is set just before the exercise starts
// this feature allows to time each hit against the exercise start time, allowing for calculations over time like intensity, and rendering the live graph
const useUpdateExerciseStarted = () => {
  const client = initApollo()
  const { updateExerciseTime } = useWorkoutActions()

  const updateExerciseStarted = async () => {
    const { data } = await client.query({
      query: SERVER_TIME,
      fetchPolicy: 'no-cache',
    })
    const serverTime = data.getServerTime.serverTime
    const started = serverTime ? new Date(serverTime) : new Date()
    updateExerciseTime(started)
  }

  return updateExerciseStarted
}

export default useUpdateExerciseStarted
