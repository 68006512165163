export enum PopupNames {
  FREESTYLE_SETUP = 'freestyleSetup',
  IDLE_LOGOUT = 'idleLogout',
  LOGIN = 'login',
  LOGOUT = 'logout',
  PAUSE = 'pause',
  QUIT_WORKOUT = 'quitWorkout',
  REGISTER = 'register',
  SAVE_RESULTS = 'saveResults',
  SAVE_RESULTS_LOGGED_IN = 'saveResultsLoggedIn',
  WORKUT_SAVED = 'workoutSaved',
  ACCOUNT_CREATED = 'accountCreated',
  FORGOT_PASSWORD = 'forgotPassword',
  CHECK_EMAIL = 'checkEmail',
  CONNECTION = 'connection',
}
