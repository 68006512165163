import { useEffect } from 'react'

import { WorkoutName } from 'interfaces/workout'
import { useCreateWorkoutMutation } from 'api/mutations/create-workout'
import { convertSessionToBackend } from '../workoutData'
import { useWorkoutSession } from 'store/workout/selectors'
import { useCreateFreestyleMutation } from 'api/mutations/create-freestyle-workout'

const useWorkoutComplete = () => {
  const workoutSession = useWorkoutSession()

  const isQuickstart = workoutSession.name === WorkoutName.QUICKSTART
  const isFreestyle = workoutSession.name === WorkoutName.FREESTYLE
  const { createWorkoutMutation } = useCreateWorkoutMutation()
  const { createFreestyleMutation } = useCreateFreestyleMutation()

  useEffect(() => {
    if (isQuickstart) return // quickstart cannot be saved

    if (isFreestyle) {
      const backendSessions = convertSessionToBackend(workoutSession)
      createFreestyleMutation(backendSessions)
      return
    }
    const backendSessions = convertSessionToBackend(workoutSession)
    createWorkoutMutation(backendSessions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useWorkoutComplete

