import { useCallback, useState } from 'react'

interface ISaveResultsForm {
  email: string
  password: string
}

const useSaveResultsForm = () => {
  const [saveResultsForm, setSaveResultsForm] = useState<ISaveResultsForm>({
    email: '',
    password: '',
  })

  const updateSaveResultsForm = useCallback((evt: React.BaseSyntheticEvent) => {
    evt.preventDefault()
    const { name, value } = evt.target
    setSaveResultsForm((prevState: ISaveResultsForm) => ({
      ...prevState,
      [name]: value,
    }))
  }, [])

  return { saveResultsForm, updateSaveResultsForm }
}

export default useSaveResultsForm
