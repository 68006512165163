import React from 'react'
import { useNavigate } from 'react-router-dom'

import Button from 'components/button'
import { pathsObj } from 'lib/constants/paths'
import './error.scss'

export default function ErrorMessage({ message = '' }: { message?: string } | any) {
  const navigate = useNavigate()

  const handleReload = () => {
    return window.location.reload()
  }

  const handleBackHome = () => {
    navigate(pathsObj.main.home)
  }

  return (
    <div className="error">
      <h1>Something went wrong.</h1>
      <p>{message}</p>
      <p>Refresh this page to try again or return home</p>
      <div className="error__btn-box">
        <Button customClickEvent={handleReload}>Refresh</Button>
        <Button color={'secondary'} customClickEvent={handleBackHome}>
          Back home
        </Button>
      </div>
    </div>
  )
}
