import { gql, useMutation } from '@apollo/client'
import { useWorkoutActions } from 'store/workout/selectors'

// This is used to save a custom workouts. The template is passed, so that we can connect this workout to a template, in order to access data like challenges from the template, and compare those to the user's performance
// Round are saved beforehand, to decrease the payload. This way not all data needs to be sent at once. An array of round ids is passed in this call to connect the rounds to the workout
const CREATE_WORKOUT_GROUP = gql`
  mutation createWorkoutGroup(
    $name: String
    $workoutType: WorkoutType
    $difficultyLevel: DifficultyLevel
    $rounds: [WorkoutRoundInputSchema!]
    $templateId: String
  ) {
    createWorkoutGroup(
      input: {
        workouts: {
          name: $name
          workoutType: $workoutType
          difficultyLevel: $difficultyLevel
          rounds: $rounds
          workoutTemplate: { id: $templateId }
        }
      }
    ) {
      workouts {
        id
      }
    }
  }
`

export const useCreateWorkoutMutation = () => {
  const { updateSavedWorkoutId } = useWorkoutActions()

  const [createWorkoutGroup] = useMutation(CREATE_WORKOUT_GROUP, {
    onCompleted({ createWorkoutGroup }) {
      if (createWorkoutGroup && createWorkoutGroup.workouts) {
        const workout = createWorkoutGroup.workouts[0]
        updateSavedWorkoutId(workout.id)
      }
    },
  })

  const createWorkoutMutation = (workouts: any) => {
    const { name, workoutType, difficultyLevel, rounds, templateId } = workouts
    createWorkoutGroup({ variables: { name, workoutType, difficultyLevel, rounds, templateId } })
  }

  return { createWorkoutMutation }
}
