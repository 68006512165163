import { PopupNames } from 'interfaces/popup'
import { SnackbarNames } from 'interfaces/snackbar'

import useConfigStore, { ConfigStore } from 'store/config'

export const useIsPaused = () => useConfigStore(({ isPaused }: ConfigStore) => isPaused)

export const useIsSoundActive = () => useConfigStore(({ isSoundActive }: ConfigStore) => isSoundActive)

export const useActivePopup = () => useConfigStore(({ activePopup }: ConfigStore) => activePopup)

export const useConnectionState = () => useConfigStore(({ connectionState }: ConfigStore) => connectionState)

export const useConfigActions = () => useConfigStore(({ actions }: ConfigStore) => actions)

export const usePause = () => {
  const { setPaused } = useConfigActions()

  const pause = () => setPaused(true)
  const unpause = () => setPaused(false)

  return { pause, unpause }
}

export const useSetPopup = () => {
  const { setActivePopup } = useConfigActions()

  const closePopup = () => setActivePopup(null)
  const setFreestylePopup = () => setActivePopup(PopupNames.FREESTYLE_SETUP)
  const setIdleLogoutPopup = () => setActivePopup(PopupNames.IDLE_LOGOUT)
  const setLoginPopup = () => setActivePopup(PopupNames.LOGIN)
  const setLogoutPopup = () => setActivePopup(PopupNames.LOGOUT)
  const setPausePopup = () => setActivePopup(PopupNames.PAUSE)
  const setQuitWorkoutPopup = () => setActivePopup(PopupNames.QUIT_WORKOUT)
  const setRegisterPopup = () => setActivePopup(PopupNames.REGISTER)
  const setSaveResultsPopup = () => setActivePopup(PopupNames.SAVE_RESULTS)
  const setSaveResultsLoggedInPopup = () => setActivePopup(PopupNames.SAVE_RESULTS_LOGGED_IN)
  const setWorkoutSavedPopup = () => setActivePopup(PopupNames.WORKUT_SAVED)
  const setAccountCreatedPopup = () => setActivePopup(PopupNames.ACCOUNT_CREATED)
  const setForgotPasswordPopup = () => setActivePopup(PopupNames.FORGOT_PASSWORD)
  const setCheckEmailPopup = () => setActivePopup(PopupNames.CHECK_EMAIL)
  const setConnectionPopup = () => setActivePopup(PopupNames.CONNECTION)

  return {
    closePopup,
    setFreestylePopup,
    setIdleLogoutPopup,
    setLoginPopup,
    setLogoutPopup,
    setPausePopup,
    setQuitWorkoutPopup,
    setRegisterPopup,
    setSaveResultsPopup,
    setSaveResultsLoggedInPopup,
    setWorkoutSavedPopup,
    setAccountCreatedPopup,
    setForgotPasswordPopup,
    setCheckEmailPopup,
    setConnectionPopup,
  }
}

export const useSnackbar = () => {
  const { setActiveSnackbar } = useConfigActions()

  const closeSnackbar = () => setActiveSnackbar(null)
  const setConnectedSnackbar = () => setActiveSnackbar(SnackbarNames.CONNECTED)
  const setRebootingSnackbar = () => setActiveSnackbar(SnackbarNames.REBOOTING)
  const setConnectionFailedSnackbar = () => setActiveSnackbar(SnackbarNames.CONNECTION_FAILED)

  return { closeSnackbar, setConnectedSnackbar, setRebootingSnackbar, setConnectionFailedSnackbar }
}
