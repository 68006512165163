import React, { useEffect } from 'react'
import Icon, { IconNames } from '../icons/icons'

import AutoplayVideo from './autoplay-video'
import ClickVideo from './click-video'
import './nr-video.scss'

export interface NRVideoProps {
  isAutoPlay: boolean
  src: string
  className?: string
  onVideoEnd?: () => void
  onError?: () => void
}

export const NRVideoPlayIcon = ({
  handlePlayVideo,
}: {
  handlePlayVideo: () => void
}) => {
  return (
    <div className="NRVideo__playIcon--wrapper" onClick={handlePlayVideo}>
      <div className="NRVideo__playIcon--circle">
        <Icon name={IconNames.PLAY} />
      </div>
    </div>
  )
}

export const NRVideoError = ({ onError }: Pick<NRVideoProps, 'onError'>) => {
  useEffect(() => {
    onError && onError()
  }, [onError])

  return (
    <div className="NRVideo__error">
      <h3>Video error</h3>
      <p>Workout will continue</p>
    </div>
  )
}

export default function NRVideo(props: NRVideoProps) {
  const { isAutoPlay, className } = props
  return (
    <div className={className}>
      <div className={`NRVideo__playerWrapper`}>
        {isAutoPlay ? <AutoplayVideo {...props} /> : <ClickVideo {...props} />}
      </div>
    </div>
  )
}
