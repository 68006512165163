import ChallengeResults from 'components/challenge/challenge-results'
import './workout-feedback.scss'
import Cta from 'components/cta/cta'
import { checkIfWorkoutHasChallenges } from 'lib/challenges'
import { useWorkoutSession } from 'store/workout/selectors'
import SaveResults from 'components/workouts/workout-fragments/save-results'
import { useGymSubscriptionQuery } from 'api/queries/gym-subscription'
import Button from 'components/button'
import { WorkoutName } from 'interfaces/workout'

const Unavailable = () => (
  <div className="workoutFeedback__unavailable">
    <p className="light">Save your results is not available on this system. Ask your trainer for more info.</p>
    <Button disabled>
      <h4>Not available</h4>
    </Button>
  </div>
)

// render the ChallengeResults component if the workout has challenges, otherwise render the Cta component, used on workout complete page
const WorkoutFeedback = () => {
  const workoutSession = useWorkoutSession()
  const hasChallenges = checkIfWorkoutHasChallenges(workoutSession)
  const { data, loading } = useGymSubscriptionQuery()

  const hasSubscription = data?.apiKey?.[0].tablet?.[0].hasSubscription ?? false //  only gyms that pay for a subscription can save results
  const renderCta = hasChallenges ? <ChallengeResults /> : <Cta />
  const renderSaveResults = hasSubscription ? <SaveResults /> : <Unavailable />
  const isQuickstart = workoutSession.name === WorkoutName.QUICKSTART

  return (
    <div className="workoutFeedback">
      <h3>
        <span>NextRound</span>
        <span>Club</span>
      </h3>
      {renderCta}
      {!isQuickstart && !loading && renderSaveResults}
    </div>
  )
}

export default WorkoutFeedback
