import { create } from 'zustand'
import { mountStoreDevtool } from 'simple-zustand-devtools'

import {
  INRWorkoutSession,
  INRWorkoutSessionExercise,
  INRWorkoutSessionHit,
  INRWorkoutSessionRound,
} from 'interfaces/workout'
import { getInitWorkoutStore } from 'lib/initial'
import handleStorage, { TOKENNAME } from 'lib/handleStorage'

export interface WorkoutStore {
  savedWorkoutId: string
  workoutIsSaved: boolean
  workoutSession: INRWorkoutSession
  currentCount: number
  activeRound: number
  activeExercise: number
  actions: {
    updateSavedWorkoutId: (savedWorkoutId: string) => void
    updateWorkoutIsSaved: (workoutIsSaved: boolean) => void
    updateCurrentCount: (count: number) => void
    decrementCurrentCount: () => void
    resetWorkout: () => void
    updateActiveRound: (roundNum: number) => void
    updateActiveExercise: (exerciseNum: number) => void
    updateExerciseTime: (started: Date) => void
    updateWorkoutSession: (workoutSession: INRWorkoutSession) => void
    addHit: (hit: INRWorkoutSessionHit) => void
  }
}

const useWorkoutStore = create<WorkoutStore>((set, get) => ({
  savedWorkoutId: getInitWorkoutStore().savedWorkoutId,
  workoutIsSaved: getInitWorkoutStore().workoutIsSaved,
  workoutSession: getInitWorkoutStore().workoutSession,
  currentCount: getInitWorkoutStore().currentCount,
  activeRound: getInitWorkoutStore().activeRound,
  activeExercise: getInitWorkoutStore().activeExercise,
  actions: {
    // TODO extract actions
    // TODO edit localstorage util
    updateSavedWorkoutId: (savedWorkoutId) => set(() => ({ savedWorkoutId })),
    updateWorkoutIsSaved: (workoutIsSaved) => set(() => ({ workoutIsSaved })),
    updateCurrentCount: (count) => set(() => ({ currentCount: count })),
    decrementCurrentCount: () =>
      set(() => {
        const oldCount = get().currentCount
        return { currentCount: oldCount - 1 }
      }),
    resetWorkout: () =>
      set(() => {
        const { setStored } = handleStorage(TOKENNAME.workoutSession)
        const { removeStored } = handleStorage(TOKENNAME.workoutRounds)
        const { savedWorkoutId, workoutIsSaved, workoutSession, currentCount, activeRound, activeExercise } =
          getInitWorkoutStore()

        setStored(JSON.stringify(workoutSession))
        removeStored()

        return {
          savedWorkoutId,
          workoutIsSaved,
          workoutSession,
          currentCount,
          activeRound,
          activeExercise,
        }
      }),
    updateActiveRound: (roundNum) => set(() => ({ activeRound: roundNum })),
    updateActiveExercise: (exerciseNum) => set(() => ({ activeExercise: exerciseNum })),
    updateExerciseTime: (started: Date) =>
      set(() => {
        const workoutSession = get().workoutSession
        const activeRound = get().activeRound
        const activeExercise = get().activeExercise

        const newWorkoutSession = {
          ...workoutSession,
          rounds: workoutSession.rounds.map((round: INRWorkoutSessionRound, roundIndex: number) => {
            if (roundIndex === activeRound) {
              return {
                ...round,
                exercises: round.exercises.map((exercise: INRWorkoutSessionExercise, exerciseIndex: number) => {
                  if (exerciseIndex === activeExercise) {
                    return {
                      ...exercise,
                      started,
                    }
                  }
                  return exercise
                }),
              }
            }
            return round
          }),
        }

        return { workoutSession: newWorkoutSession }
      }),
    updateWorkoutSession: (workoutSession) =>
      set(() => {
        const { setStored } = handleStorage(TOKENNAME.workoutSession)
        setStored(JSON.stringify(workoutSession))
        return { workoutSession }
      }),
    addHit: (hit) =>
      set(() => {
        const { setStored } = handleStorage(TOKENNAME.workoutSession)
        const { ...workoutSession } = get().workoutSession
        const activeRound = get().activeRound
        const activeExercise = get().activeExercise
        const currentCount = get().currentCount

        if (currentCount === null) return { workoutSession }

        const currentExercise = workoutSession.rounds[activeRound].exercises[activeExercise]

        // push hits to exercise hits array
        currentExercise.hits.push(hit)
        setStored(JSON.stringify(workoutSession))
        return { workoutSession }
      }),
  },
}))

// enable inspect from React dev tools
if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('WORKOUT', useWorkoutStore)
}

export default useWorkoutStore
