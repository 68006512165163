import React from 'react'
import Button from 'components/button'
import PopupWrapper from 'components/popup/wrapper/wrapper'
import useLogout from 'lib/hooks/useLogout'
import { usePause, useSetPopup } from 'store/config/selectors'

const LogoutPopup = () => {
  const { closePopup } = useSetPopup()
  const { unpause } = usePause()
  const logout = useLogout()

  const handleClose = () => {
    unpause()
    closePopup()
  }

  const handleLogout = () => {
    logout()
    unpause()
    closePopup()
  }

  return (
    <PopupWrapper>
      <div className="PopupLogout">
        <h2>Logout</h2>
        <h3>Are you sure you want to log out?</h3>
        <div className="PopupLogout__buttonBox">
          <Button customClickEvent={handleLogout}>
            <h3>Yes</h3>
          </Button>
          <Button color="secondary" customClickEvent={handleClose}>
            <h3>No</h3>
          </Button>
        </div>
      </div>
    </PopupWrapper>
  )
}

export default LogoutPopup
