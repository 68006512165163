import { gql, useMutation } from '@apollo/client'
import { useWorkoutActions } from 'store/workout/selectors'

// This is used to save freestyle workouts. Note that no workout template is passed, as freestyle workouts are not based on a template
// Template represents a pre-defined workout structure, which is used to create a workout in the backoffice. Freestyle workouts are created within this app
const CREATE_WORKOUT_FREESTLYE = gql`
  mutation createWorkoutGroup(
    $name: String
    $workoutType: WorkoutType
    $difficultyLevel: DifficultyLevel
    $rounds: [WorkoutRoundInputSchema!]
  ) {
    createWorkoutGroup(
      input: {
        workouts: { name: $name, workoutType: $workoutType, difficultyLevel: $difficultyLevel, rounds: $rounds }
      }
    ) {
      workouts {
        id
      }
    }
  }
`

export const useCreateFreestyleMutation = () => {
  const { updateSavedWorkoutId } = useWorkoutActions()

  const [createWorkoutGroup] = useMutation(CREATE_WORKOUT_FREESTLYE, {
    onCompleted({ createWorkoutGroup }) {
      if (createWorkoutGroup && createWorkoutGroup.workouts) {
        const workout = createWorkoutGroup.workouts[0]
        updateSavedWorkoutId(workout.id)
      }
    },
  })

  const createFreestyleMutation = (workouts: any) => {
    const { name, workoutType, difficultyLevel, rounds } = workouts
    createWorkoutGroup({ variables: { name, workoutType, difficultyLevel, rounds } })
  }

  return { createFreestyleMutation }
}
