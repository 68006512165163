import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'

import { useIsPaused, useIsSoundActive, usePause } from 'store/config/selectors'
import { NRVideoError, NRVideoProps } from 'components/nr-video'
import { Spinner } from 'components/spinner/spinner'
import './nr-video.scss'

const AutoplayVideo = ({ src, onVideoEnd, onError }: Omit<NRVideoProps, 'className' | 'isAutoPlay'>) => {
  const isPaused = useIsPaused()
  const { pause, unpause } = usePause()
  const soundIsActive = useIsSoundActive()
  const [isError, setIsError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const handleEndVideo = () => {
    onVideoEnd && onVideoEnd()
    unpause()
  }

  useEffect(() => {
    pause()
  }, [])

  return (
    <>
      {isError && <NRVideoError onError={onError} />}
      {isLoading && <Spinner className="NRVideo__loading" />}
      <ReactPlayer
        className="NRVideo__reactPlayer"
        width="100%"
        height="100%"
        url={src}
        playing={isPaused}
        volume={soundIsActive ? 1 : 0}
        playsinline
        onReady={() => setIsLoading(false)}
        onError={() => setIsError(true)}
        onEnded={handleEndVideo}
      />
    </>
  )
}

export default AutoplayVideo
