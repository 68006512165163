import { ReactNode } from 'react'

import useConfigStore from 'store/config'
import { PopupNames } from 'interfaces/popup'
import FreestyleSetup from '../freestyle-setup/freestyle-setup'
import IdleLogoutPopup from '../idle-logout/idle-logout'
import LoginPopup from '../login/login'
import LogoutPopup from '../logout/logout'
import PausePopup from '../pause/pause'
import QuitWorkoutPopup from '../quit-workout/quit-workout'
import RegisterPopup from '../register/register'
import SaveResultsPopup from '../save-results/save-results'
import SaveRusulsLoggedInPopup from '../save-results-logged-in/save-results-logged-in'
import WorkoutSavedPopup from '../workout-saved/workout-saved'
import ForgotPasswordPopup from '../forgot-password/forgot-password'
import CheckEmailPopup from '../check-email/check-email'
import AccountCreatedPopup from '../account-created/account-created'
import ConnectionPopup from '../connection/connection'

const Popup = () => {
  const { activePopup } = useConfigStore()

  const popupObj: Record<PopupNames, ReactNode> = {
    [PopupNames.FREESTYLE_SETUP]: <FreestyleSetup />,
    [PopupNames.IDLE_LOGOUT]: <IdleLogoutPopup />,
    [PopupNames.LOGIN]: <LoginPopup />,
    [PopupNames.LOGOUT]: <LogoutPopup />,
    [PopupNames.PAUSE]: <PausePopup />,
    [PopupNames.QUIT_WORKOUT]: <QuitWorkoutPopup />,
    [PopupNames.REGISTER]: <RegisterPopup />,
    [PopupNames.SAVE_RESULTS]: <SaveResultsPopup />,
    [PopupNames.SAVE_RESULTS_LOGGED_IN]: <SaveRusulsLoggedInPopup />,
    [PopupNames.WORKUT_SAVED]: <WorkoutSavedPopup />,
    [PopupNames.ACCOUNT_CREATED]: <AccountCreatedPopup />,
    [PopupNames.FORGOT_PASSWORD]: <ForgotPasswordPopup />,
    [PopupNames.CHECK_EMAIL]: <CheckEmailPopup />,
    [PopupNames.CONNECTION]: <ConnectionPopup />,
  }

  if (!activePopup) return null

  return <>{popupObj[activePopup as PopupNames]}</>
}

export default Popup
