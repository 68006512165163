import React from 'react'
import './please-rotate.scss'

const PleaseRotate: React.FC = () => {
  return (
    <div className="please-rotate">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 81.92 71.73">
        <g id="a28b7a99-9e60-4736-bb44-1a76c60aa01e" data-name="Laag 2">
          <g id="b7c2be8c-961f-4fab-b948-c866510381a1" data-name="Layer 1">
            <path
              className="bb000ec3-fe06-4a62-9efb-112d2f80782f"
              fill="#1bc6c1"
              d="M73.42,71.73H18.7a8.53,8.53,0,0,1-8.5-8.5v-22a8.51,8.51,0,0,1,8.5-8.5H73.42a8.51,8.51,0,0,1,8.5,8.5v22A8.51,8.51,0,0,1,73.42,71.73Zm-54.72-37a6.51,6.51,0,0,0-6.5,6.5v22a6.51,6.51,0,0,0,6.5,6.5H73.42a6.51,6.51,0,0,0,6.5-6.5v-22a6.5,6.5,0,0,0-6.5-6.5Z"
            />
            <path
              className="bb000ec3-fe06-4a62-9efb-112d2f80782f"
              fill="#1bc6c1"
              d="M8.5,71.72A8.51,8.51,0,0,1,0,63.22V8.5A8.51,8.51,0,0,1,8.5,0h22A8.51,8.51,0,0,1,39,8.5V28.8H37V8.5A6.51,6.51,0,0,0,30.5,2H8.5A6.51,6.51,0,0,0,2,8.5V63.22a6.51,6.51,0,0,0,6.5,6.5h.3a5.4,5.4,0,0,0,2.88,2Z"
            />
            <path
              className="a89a17b0-c6ad-46be-acef-ef0be5459dde"
              fill="#b5b5b5"
              d="M71.81,19.56l-2.35,2.5C68.44,7.5,61.49.11,48.75.11v2c11.62,0,17.74,6.54,18.71,19.94L65,19.76l.09,2.88,3.49,3.29,3.29-3.49Z"
            />
          </g>
        </g>
      </svg>
      <div>
        <h2>Please rotate</h2>
        <br />
        <h2>to landscape</h2>
      </div>
    </div>
  )
}

export default PleaseRotate
