import initApollo from 'api/client'
import { CREATE_LEADERBOARD, CREATE_LEADERBOARD_RECORD, UPDATE_LEADERBOARD_RECORD } from 'api/mutations/leaderboards'
import { CHALLENGE_TEMPLATE } from 'api/queries/challenges'
import { ME } from 'api/queries/me'
import { PrivacyLevel } from 'interfaces/user'
import { INRWorkoutLeaderboardRecord } from 'interfaces/workout'
import {
  checkIfExerciseHasChallenges,
  checkIsHighScoreChallenge,
  getChallengeConstriant,
  getEvaluatedConstraint,
  getExerciseChallenge,
} from 'lib/challenges'
import { getExercisesFromWorkout } from 'lib/utilities'
import { useWorkoutSession } from 'store/workout/selectors'

const useSaveLeaderboardRecord = () => {
  const client = initApollo()
  const workoutSession = useWorkoutSession()
  const exercises = getExercisesFromWorkout(workoutSession)
  const exercisesWithChallenges = exercises.filter(checkIfExerciseHasChallenges)
  const exercisesWithHighScoreChallenges = exercisesWithChallenges.filter((exercise) =>
    checkIsHighScoreChallenge(getExerciseChallenge(exercise))
  )

  const handleSaveLeaderboardRecord = async () => {
    const { data } = await client.query({
      query: ME,
      context: { clientName: 'personal' },
    })

    const { id, privacyLevel, email, weightKg } = data.me

    if (privacyLevel !== PrivacyLevel.PUBLIC) return

    for (const exercise of exercisesWithHighScoreChallenges) {
      const challenge = getExerciseChallenge(exercise)
      const constraint = getChallengeConstriant(challenge)
      const { result: score } = getEvaluatedConstraint(exercise, constraint, weightKg)

      const challengeId = challenge?.id ?? ''

      const { data } = await client.query({
        query: CHALLENGE_TEMPLATE,
        variables: { id: challengeId },
      })

      const leaderboard = data.challengeTemplate[0]?.leaderboard

      if (!leaderboard) {
        // If there is no leaderboard yet, we create one, then add the user's record to it
        const { data: createLeaderBoardData } = await client.mutate({
          mutation: CREATE_LEADERBOARD,
          variables: { challengeId, score, userEmail: email },
        })

        const leaderboardId = createLeaderBoardData.createLeaderboard.id

        await client.mutate({
          mutation: CREATE_LEADERBOARD_RECORD,
          variables: { score, userId: id, leaderboardId },
        })
        return
      }

      const userRecord = leaderboard.records.find((record: INRWorkoutLeaderboardRecord) => record.user.id === id)

      if (!!userRecord) {
        if (score < userRecord.score) return
        // If the user already has a record, we update it only if the new score is higher
        const userRecordId = userRecord?.id ?? ''

        await client.mutate({
          mutation: UPDATE_LEADERBOARD_RECORD,
          variables: { score, recordId: userRecordId, userId: id },
        })

        return
      }

      // if there is a leaderboard, but the user doesn't have a record, we add one
      await client.mutate({
        mutation: CREATE_LEADERBOARD_RECORD,
        variables: { score, userId: id, leaderboardId: leaderboard.id },
      })

      return
    }
  }

  return handleSaveLeaderboardRecord
}

export default useSaveLeaderboardRecord
