import { gql, useMutation } from '@apollo/client'
import { useState } from 'react'

import { handleAuthError, validateForm } from 'lib/auth-helpers'
import { useSetPopup } from 'store/config/selectors'

export const SIGNUP = gql`
  mutation signup($email: String!) {
    signup(email: $email)
  }
`

const useSignupMutation = () => {
  const { setAccountCreatedPopup } = useSetPopup()
  const [error, setError] = useState<string>('')

  const handleSignupError = handleAuthError(setError)

  const [signup] = useMutation(SIGNUP, {
    onError: (err) => setError(err.message),
    onCompleted() {
      setAccountCreatedPopup()
    },
  })

  const signupMutation = (email: string) => {
    const wrongInput = validateForm({ email })

    if (!!wrongInput) {
      handleSignupError(wrongInput)
    } else {
      signup({ variables: { email } })
    }
  }

  return {
    error,
    signupMutation,
  }
}

export default useSignupMutation
