import { useState } from 'react'
import { gql, useMutation } from '@apollo/client'

import { INRRound } from 'interfaces/workout'

// This saves rounds to the database.
const CREATE_WORKOUT_ROUND = gql`
  mutation createWorkoutRound($breakSeconds: Int, $countdownSeconds: Int, $exercises: [WorkoutExerciseInputSchema!]) {
    createWorkoutRound(
      input: { breakSeconds: $breakSeconds, countdownSeconds: $countdownSeconds, exercises: $exercises }
    ) {
      id
    }
  }
`

export const useCreateRoundMutation = () => {
  const [roundId, setRoundId] = useState<string>('')
  const [isLoading, setLoading] = useState<boolean>(false)
  const [createWorkoutRound] = useMutation(CREATE_WORKOUT_ROUND, {
    onCompleted({ createWorkoutRound }) {
      if (createWorkoutRound && createWorkoutRound.id) {
        setRoundId(createWorkoutRound.id)
      }
      setLoading(false)
    },
    onError(error) {
      console.error('Error creating round:', error)
      setLoading(false)
    },
  })

  const createRoundMutation = (round: INRRound) => {
    const { breakSeconds, countdownSeconds, exercises } = round
    setLoading(true)
    createWorkoutRound({
      variables: { breakSeconds, countdownSeconds, exercises },
    })
  }

  return { roundId, createRoundMutation, isLoading }
}
