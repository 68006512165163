import React from 'react'

import getCounterSize from 'lib/get-counter-size'
import { useNextStep } from 'lib/hooks/useNextStep'
import useAddHit from 'lib/hooks/useAddHit'
import Main from 'layout/main'
import { useActiveRound, useCurrentExercise } from 'store/workout/selectors'
import HeaderActive from 'components/header/active'
import Header from 'components/header/header'
import ChartExercise from 'components/chart-exercise/chart-exercise'
import { ChallengeCard } from 'components/challenge/challenge-card'
import Intensity from 'components/intensity/intensity'
import HitMeBtn from 'components/hit-me-btn/hit-me-btn'
import { ExerciseActiveNumbers } from 'components/rounds-fragments/round-active-numbers'
import CurrentCounter from 'components/counter/counter'
import useCountdown from 'lib/hooks/useCountdown'
import { useSingleBellSound } from 'lib/hooks/useSounds'

// Displaying active exercise and its stats / graph
const RoundActive = () => {
  const playSound = useSingleBellSound()
  const currentExercise = useCurrentExercise()
  const activeRound = useActiveRound()
  const nextStep = useNextStep()

  const handleNextStep = () => {
    playSound()
    nextStep()
  }

  useAddHit()
  useCountdown({
    initCount: currentExercise.seconds,
    cb: handleNextStep,
  })

  const screenWidth = document.documentElement.clientWidth
  const counterSize = getCounterSize(screenWidth)

  const hasChallenges = currentExercise?.challenges?.length! > 0

  const renderCard = hasChallenges ? (
    <ChallengeCard />
  ) : (
    <div className="RoundActive__stats__exercise__image col-8">
      <div className="exerciseImage__stats">
        <h3>
          Exercise <strong>| {currentExercise.seconds} sec</strong>
        </h3>
      </div>
    </div>
  )

  return (
    <Main className="nextround">
      <div className="RoundActive">
        <Header title={<h1>{`Round ${activeRound + 1}`}</h1>} />
        <HeaderActive />
        <HitMeBtn />
        <div className="row RoundActive__content">
          <div className="col-7 RoundActive__stats">
            <div className="RoundActive__stats__exercise">
              {renderCard}
              <div className="RoundActive__stats__exercise__punch col-4">
                <ExerciseActiveNumbers />
              </div>
            </div>
            <div className="RoundActive__stats__chart">
              <ChartExercise />
            </div>
          </div>
          <div className="col-5 RoundActive__right">
            <Intensity isColumn />
            <CurrentCounter size={counterSize} endCounter={nextStep} initCount={currentExercise.seconds} />
          </div>
        </div>
      </div>
    </Main>
  )
}

export default RoundActive
