import { create } from 'zustand'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { UserWeight } from 'interfaces/user'
import handleStorage, { TOKENNAME } from 'lib/handleStorage'

export interface AuthStore {
  isLoggedIn: boolean
  userWeight: UserWeight
  actions: {
    login: (userToken: TOKENNAME.userToken) => void
    logout: () => void
    setUserWeight: (userWeight: UserWeight) => void
  }
}

const useAuthStore = create<AuthStore>((set, get) => ({
  isLoggedIn: false,
  userWeight: UserWeight.MEDIUM,
  actions: {
    login: (userToken: TOKENNAME.userToken) =>
      set(() => {
        const { setStored } = handleStorage(TOKENNAME.userToken)
        setStored(userToken)

        return { isLoggedIn: true }
      }),
    logout: () =>
      set(() => {
        const { removeStored } = handleStorage(TOKENNAME.userToken)
        removeStored()

        return { isLoggedIn: false }
      }),
    setUserWeight: (userWeight) => set({ userWeight }),
  },
}))

// enable inspect from React dev tools
if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('AUTH', useAuthStore)
}

export default useAuthStore
