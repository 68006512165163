import Button from 'components/button'
import SetupFreestyleRounds from './setup-rounds'
import PaginationDots from 'components/pagination-dots/pagination-dots'
import SetupFreestyleIntensity from './setup-intensity'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import useFreestyleSetup from 'lib/hooks/useFreestyleSetup'
import usePagination from 'lib/hooks/usePagination'

interface NotLoggedInProps {
  startFreestyle: () => void
}

const NotLoggedIn = ({ startFreestyle }: NotLoggedInProps) => {
  const { rounds, seconds, rest, addSeconds, removeSeconds, addRound, removeRound, addRest, removeRest } =
    useFreestyleSetup()
  const { activeElement, handleNext, handlePrev, handleSelectActiveElement } = usePagination()

  const elements = [
    <SetupFreestyleRounds
      rounds={rounds}
      seconds={seconds}
      rest={rest}
      addSeconds={addSeconds}
      removeSeconds={removeSeconds}
      addRound={addRound}
      removeRound={removeRound}
      addRest={addRest}
      removeRest={removeRest}
    />,
    <SetupFreestyleIntensity />,
  ]

  const prevBtn =
    activeElement > 0 ? (
      <Button customClickEvent={handlePrev} className="popupFreestyle__buttons--left">
        <h3>
          <FontAwesomeIcon icon={faChevronLeft} />
          Go back
        </h3>
      </Button>
    ) : (
      <div />
    )

  const nextBtn =
    activeElement < elements.length - 1 ? (
      <Button fullColor customClickEvent={handleNext} className="popupFreestyle__buttons--right">
        <h3>
          Next
          <FontAwesomeIcon icon={faChevronRight} />
        </h3>
      </Button>
    ) : (
      <Button fullColor customClickEvent={startFreestyle} className="popupFreestyle__buttons--right">
        <h3>
          Start Freestyle
          <FontAwesomeIcon icon={faChevronRight} />
        </h3>
      </Button>
    )

  return (
    <>
      {elements[activeElement]}
      <div className="popupFreestyle__buttons">
        {prevBtn}
        <PaginationDots
          activeElement={activeElement}
          handleSelectActiveElement={handleSelectActiveElement}
          numOfElements={elements.length}
        />
        {nextBtn}
      </div>
    </>
  )
}

export default NotLoggedIn
