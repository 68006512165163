import { gql, useQuery } from '@apollo/client'

export const GET_TABLETS_AND_BAGS = gql`
  query gymSubscription {
    apiKey {
      tablet {
        hasSubscription
      }
    }
  }
`

export const useGymSubscriptionQuery = () => useQuery(GET_TABLETS_AND_BAGS)
