import { useState, useEffect } from 'react'

interface WindowSize {
  width: undefined | number
  height: undefined | number
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      }
      window.addEventListener('resize', handleResize)
      handleResize()
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [])

  return windowSize
}

export const getBreakPoints = (width: number | undefined) => {
  if (!width) return

  if (width >= 1440) {
    return 'xlarge'
  } else if (width >= 1280 && width < 1440) {
    return 'large'
  } else if (width >= 1024 && width < 1280) {
    return 'medium'
  } else {
    return 'small'
  }
}
