import { BaseSyntheticEvent } from 'react'

interface Props {
  className?: string
  type: string
  name: string
  id?: string
  placeholder: string
  value: string
  onChange: (evt: BaseSyntheticEvent) => void
  autoCapitalize?: string
  autoComplete?: string
}

const Input = ({
  className = '',
  type,
  name,
  id = '',
  placeholder,
  value,
  onChange,
  autoCapitalize = 'none',
  autoComplete = 'off',
}: Props) => {
  return (
    <input
      className={`input ${className}`}
      data-filled={value ? 'true' : 'false'}
      type={type}
      name={name}
      id={id}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      autoCapitalize={autoCapitalize}
      autoComplete={autoComplete}
    />
  )
}

export default Input
