export const checkEmptyFields = (formObj: { [key: string]: string }) => {
  const formArray = Object.keys(formObj).map((key) => {
    return formObj[key]
  })

  return formArray.some((el) => !el)
}

export const checkValidEmail = (email: string) => {
  const mailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

  return mailFormat.test(email)
}

export const validateForm = (formObj: { [key: string]: string }) => {
  const hasEmptyFields = checkEmptyFields(formObj)
  const isValidEmail = checkValidEmail(formObj?.email)

  switch (true) {
    case !!hasEmptyFields:
      return 'Please fill in all fields'
    case !isValidEmail:
      return 'Invalid email address'
    default:
      return ''
  }
}

export const handleAuthError = (setError: React.Dispatch<React.SetStateAction<string>>) => (errMsg: string) => {
  setError(errMsg)
  let time = setTimeout(() => {
    setError('')
    clearTimeout(time)
  }, 5000)
}
