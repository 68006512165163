import React from 'react'
import './spinner.scss'

export const Spinner = ({ className = '' }: { className?: string }) => {
  return (
    <div className={`spinner ${className}`}>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}
