import React, { MutableRefObject, useRef } from 'react'

import { INRWorkoutSessionRound } from 'interfaces/workout'
import { getXaxisTicks, getGraphWidth, getAverageForcePercent } from 'lib/graphs'
import { getEvaluatedExerciseIntensity, getEvaluatedWorkoutIntensity } from 'lib/intensity'
import useTabsPanel from 'lib/hooks/useTabsPanel'
import { useWindowSize } from 'lib/hooks/useWindowSize'
import TabsPanel from 'components/tabs-panel/tabs-panel'
import CustomYAxis from 'components/graph-rounds/custom-y-axis'
import CustomXAxis from 'components/graph-rounds/custom-x-axis'
import ForceGraphRounds from 'components/graph-rounds/force-graph-round'
import IntensityGraphRounds from 'components/graph-rounds/intensity-graph-round'
import { getMaxForce, getRoundDuration, getWorkoutHits } from 'lib/stats'
import { useWorkoutSession } from 'store/workout/selectors'
import { calcPercentage, getAverage } from 'lib/utilities'
import { useUserWeight } from 'store/auth/selectors'
import { useWeightQuery } from 'api/queries/weight'
import { classifyUserWeight, getActiveWeight } from 'lib/weight'
import RoundSeperator from './round-seperator'

const GraphWorkoutComplete = () => {
  const { width } = useWindowSize()
  const graphWidth = getGraphWidth(width)
  const container = useRef() as MutableRefObject<HTMLInputElement>

  const workoutSession = useWorkoutSession()
  const userWeight = useUserWeight()
  const { data } = useWeightQuery()
  const weight = getActiveWeight(data, userWeight)
  const weightClass = classifyUserWeight(weight)

  const workoutSessionHits = getWorkoutHits(workoutSession)
  const maxForce = getMaxForce(workoutSessionHits)
  const avgForcePercent = getAverageForcePercent(workoutSessionHits)

  const intensityWorkout = getEvaluatedWorkoutIntensity(workoutSession, weight)
  const avgIntensity = getAverage(intensityWorkout)
  const avgIntensityPercent = Math.round(calcPercentage({ partialValue: avgIntensity, totalValue: 7 }))

  const allRoundTimes = workoutSession.rounds.map((round: INRWorkoutSessionRound) => getRoundDuration(round))

  const tabs = ['Force', 'Intensity']
  const { activeTab, selectTab } = useTabsPanel()
  const isForceGraph = activeTab === 0

  const scrollRight = () => {
    if (!container) return
    const element = container.current
    if (element) {
      element.scrollLeft += 20
    }
  }

  const renderGraphs = workoutSession.rounds.map((round: INRWorkoutSessionRound, index: number) => {
    const exerciseIntensity = round.exercises.flatMap((exercise) => getEvaluatedExerciseIntensity(exercise, weight))

    const roundTime = getRoundDuration(round)

    const intensityGraphData = exerciseIntensity?.map((value: number, index: number) => ({
      intensity: value,
      index,
    }))

    const prevRoundTimes = allRoundTimes?.filter((_: any, roundIndex: number) => roundIndex < index)

    const firstValue = prevRoundTimes!.reduce((a: number, b: number) => a + b, 0)
    const lastValue = firstValue + allRoundTimes![index]
    const { secondValue, thirdValue, fourthValue } = getXaxisTicks(firstValue, lastValue)

    const xAxisTicks = [firstValue, secondValue, thirdValue, fourthValue, lastValue]

    return (
      <div className="roundsGraph__singleRoundWrapper" key={index}>
        <RoundSeperator roundIndex={index} />
        {isForceGraph ? (
          <ForceGraphRounds roundData={round} maxForce={maxForce} roundTime={roundTime} graphWidth={graphWidth} />
        ) : (
          <IntensityGraphRounds intensityGraphData={intensityGraphData} roundTime={roundTime} graphWidth={graphWidth} />
        )}
        <CustomXAxis displayFirstTick={index === 0} xAxisTicks={xAxisTicks} />
      </div>
    )
  })

  return (
    <div className="roundsGraph">
      <TabsPanel tabs={tabs} activeIndex={activeTab} selectTab={selectTab} />
      <div className="roundsGraph__wrapper">
        <CustomYAxis
          maxForce={Math.round(maxForce)}
          isForceGraph={isForceGraph}
          avg={isForceGraph ? avgForcePercent : avgIntensityPercent}
        />
        <div className="roundsGraph__graphContainer" ref={container}>
          {!isForceGraph && <h3 className="roundsGraph__weightValue">{weightClass}</h3>}
          {renderGraphs}
        </div>
        <div onClick={scrollRight} className="roundsGraph__arrowRight" />
      </div>
    </div>
  )
}

export default GraphWorkoutComplete
