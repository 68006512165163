import { useApolloClient } from '@apollo/client'
import { useNavigate } from 'react-router'
import { pathsObj } from '../constants/paths'
import handleStorage, { TOKENNAME } from '../handleStorage'
import { useAuthActions } from 'store/auth/selectors'

const useLogout = () => {
  const navigate = useNavigate()
  const apolloClient = useApolloClient()
  const { logout: logoutAction } = useAuthActions()
  const { removeStored: removeRememberMe } = handleStorage(TOKENNAME.rememberMe)

  const logout = async () => {
    await apolloClient.clearStore()
    removeRememberMe()
    logoutAction()
    navigate(pathsObj.main.home)
  }

  return logout
}

export default useLogout
