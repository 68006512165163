import PopupWrapper from '../wrapper/wrapper'
import Button from 'components/button'
import { ConnectionInfo, ConnectionLabel } from './fragments'
import './connection.scss'

const ConnectionPopup = () => {
  return (
    <PopupWrapper>
      <div className="connectionPopup">
        <ConnectionLabel />
        <ConnectionInfo />
        <h4>In case you are still running into problems, please see our support page.</h4>
        <Button color="primary">
          <a href="https://nextroundboxing.com/support" target="_blank" rel="noopener noreferrer">
            <h3>Go to support</h3>
          </a>
        </Button>
      </div>
    </PopupWrapper>
  )
}

export default ConnectionPopup
