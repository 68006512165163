import { gql, useMutation } from '@apollo/client'
import { useState } from 'react'
import { useSetPopup } from 'store/config/selectors'
import { useWorkoutActions } from 'store/workout/selectors'

// When the user does not have an account and completes a workout, they may save the workout while creating an account at the same time.
// The account creation can only be completed in the personal app
const SIGNUP_WITH_WORKOUT = gql`
  mutation signup($workoutId: String!, $email: String!) {
    signup(workoutId: $workoutId, email: $email)
  }
`

const useSignupWithWorkoutMutation = () => {
  const [error, setError] = useState<string>('')
  const { setAccountCreatedPopup } = useSetPopup()
  const { updateWorkoutIsSaved } = useWorkoutActions()

  const [signupWithWorkout] = useMutation(SIGNUP_WITH_WORKOUT, {
    onCompleted() {
      setAccountCreatedPopup()
      updateWorkoutIsSaved(true)
    },
    onError: (err) => {
      setError(err.message)
    },
  })

  const signupWithWorkoutMutation = (workoutId: string, email: string) => {
    signupWithWorkout({ variables: { workoutId, email } })
  }

  return {
    error,
    signupWithWorkoutMutation,
  }
}

export default useSignupWithWorkoutMutation
