export interface User {
  weightKg?: number
  email?: string
  id?: string
  privacyLevel?: PrivacyLevel
  pin?: string | number
  userName?: string
}

export interface Me {
  me: User
}

export enum UserWeight {
  LIGHT = 55,
  MEDIUM = 75,
  HEAVY = 95,
}

export enum UserWeightLabel {
  LIGHT = 'Light',
  MEDIUM = 'Medium',
  HEAVY = 'Heavy',
}

export enum PrivacyLevel {
  MYSELF = 'Myself',
  FOLLOWERS = 'Followers',
  PUBLIC = 'Public',
}
