import { AppEnv, EnvConfig } from 'interfaces/env'

const appEnv = process.env.REACT_APP_ENV ?? ''
const startByHitToggleEnv = process.env.REACT_APP_START_BY_HIT_TOGGLE ?? false

export const getValueByEnv = <T>({local, develop, staging, production }: EnvConfig<T>) => {
  switch (appEnv) {
    case AppEnv.PRODUCTION:
      return production
    case AppEnv.STAGING:
      return staging
    case AppEnv.LOCAL:
      return local
    default:
      return develop
  }
}

export const getValueStartByHitToggleEnv = (): boolean => {
  if (startByHitToggleEnv === 'true') {
    return true
  }
  return false
}
