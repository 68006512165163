import React from 'react'
import './icons.scss'

import infoWhite from './../../assets/icons/info-white.svg'
import speaker from './../../assets/icons/speaker.svg'
import challengeGloves from './../../assets/icons/challenge-gloves.svg'
import challengeGlovesRed from './../../assets/icons/challenge-gloves-red.svg'
import challengeGlovesGreen from './../../assets/icons/challenge-gloves-green.svg'
import cross from './../../assets/icons/cross.svg'
import clock from './../../assets/icons/clock.svg'
import glove from './../../assets/icons/glove.svg'
import gloveRed from './../../assets/icons/glove-red.svg'
import crossRed from './../../assets/icons/cross-red.svg'
import info from './../../assets/icons/info.svg'
import arrowRight from './../../assets/icons/arrow_right.svg'
import arrowUp from './../../assets/icons/arrow_up.svg'
import logoSmall from './../../assets/icons/logo-small.svg'
import plus from './../../assets/icons/plus.svg'
import play from './../../assets/icons/play.svg'
import pause from './../../assets/icons/pause.svg'
import minus from './../../assets/icons/minus.svg'
import check from './../../assets/icons/check.svg'
import mute from './../../assets/icons/mute.svg'
import boxhand from './../../assets/icons/box_fist.svg'
import boxbag from './../../assets/icons/boxbag.svg'
import portraitLandscape from './../../assets/icons/portrait-landscape.svg'
import refresh from './../../assets/icons/refresh.svg'
import login from './../../assets/icons/login.svg'
import logout from './../../assets/icons/logout.svg'
import goodConnection from './../../assets/icons/good-connection.svg'
import badConnection from './../../assets/icons/bad-connection.svg'
import noConnection from './../../assets/icons/no-connection.svg'

export enum IconNames {
  SPEAKER = 'speaker',
  CHALLENGE_GLOVES = 'challenge-gloves',
  CHALLENGE_GLOVES_RED = 'challenge-gloves-red',
  CHALLENGE_GLOVES_GREEN = 'challenge-gloves-green',
  CROSS = 'cross',
  CROSS_RED = 'cross-red',
  CLOCK = 'clock',
  GLOVE = 'glove',
  GLOVE_RED = 'glove-red',
  INFO = 'info',
  INFO_WHITE = 'info-white',
  ARROW_RIGHT = 'arrow-right',
  ARROW_UP = 'arrow-up',
  LOGO_SMALL = 'logo-small',
  BOXHAND = 'boxhand',
  BOXBAG = 'boxbag',
  CHECK = 'check',
  PLUS = 'plus',
  PLAY = 'play',
  PAUSE = 'pause',
  MINUS = 'minus',
  MUTE = 'mute',
  PORTRAIT_LANDSCAPE = 'portrait-landscape',
  REFRESH = 'refresh',
  LOGIN = 'login',
  LOGOUT = 'logout',
  GOOD_CONNECTION = 'good-connection',
  BAD_CONNECTION = 'bad-connection',
  NO_CONNECTION = 'no-connection',
}

interface IconProps {
  name: IconNames
  size?: number
  color?: null | string
  customClickEvent?: () => void
}

const initialIcon: IconProps = {
  name: IconNames.SPEAKER,
  size: 24,
  color: null,
  customClickEvent: () => {},
}

const icons: { [key in IconNames]: string } = {
  speaker,
  'challenge-gloves': challengeGloves,
  'challenge-gloves-red': challengeGlovesRed,
  'challenge-gloves-green': challengeGlovesGreen,
  cross,
  'cross-red': crossRed,
  clock,
  glove,
  'glove-red': gloveRed,
  info,
  'info-white': infoWhite,
  'logo-small': logoSmall,
  plus,
  play,
  pause,
  boxbag,
  minus,
  check,
  mute,
  boxhand,
  'arrow-right': arrowRight,
  'arrow-up': arrowUp,
  'portrait-landscape': portraitLandscape,
  refresh,
  login,
  logout,
  'good-connection': goodConnection,
  'bad-connection': badConnection,
  'no-connection': noConnection,
}

const Icon = ({ name, color, customClickEvent, size }: IconProps) => {
  const iconColor: string = color || ''
  return (
    <div onClick={customClickEvent} className={'icon ' + iconColor} style={{ height: size, width: size }}>
      <img alt="icon" src={icons[name]} />
    </div>
  )
}

Icon.defaultProps = initialIcon

export default Icon
