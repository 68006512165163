import React from 'react'

export interface BarItemProps {
  height: number
}

const Bar: React.FC<BarItemProps> = ({ height }) => (
  <div className="ChartEx__bars__bar" style={{ height: height + '%' }}></div>
)

export default Bar
