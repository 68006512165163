import React from 'react'
import { ResponsiveContainer, ComposedChart, XAxis, YAxis, Area } from 'recharts'

import { IntensityGraphRoundProps } from 'interfaces/graph'

const IntensityGraphRound = ({ roundTime, intensityGraphData, graphWidth }: IntensityGraphRoundProps) => {
  return (
    <ResponsiveContainer width={graphWidth} height="100%">
      <ComposedChart data={intensityGraphData}>
        <XAxis tick={false} dataKey="index" type="number" domain={[0, roundTime]} />
        <YAxis tick={false} hide={true} domain={[0, 7]} />
        <Area dataKey="intensity" type="basis" fill="#FB7558" fillOpacity={0.5} stroke="#FB7558" strokeWidth={4} />
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default IntensityGraphRound
