import { gql, useMutation } from '@apollo/client'
import { useState } from 'react'

import handleStorage, { TOKENNAME } from 'lib/handleStorage'
import useSaveLeaderboardRecord from 'lib/hooks/useSaveLeaderboardRecord'
import { useAuthActions } from 'store/auth/selectors'
import { useSetPopup } from 'store/config/selectors'
import { useSavedWorkoutId, useWorkoutActions } from 'store/workout/selectors'
import { LOGIN } from 'api/mutations/login'

// When the user is not logged in and completes a workout, they may save the workout while loggin in at the same time.
export const CONNECT_WORKOUT = gql`
  mutation connectWorkout($workoutId: String!) {
    connectWorkout(workoutId: $workoutId)
  }
`
const useLoginWithWorkoutMutation = () => {
  const { login: loginAction } = useAuthActions()
  const savedWorkoutId = useSavedWorkoutId()
  const [error, setError] = useState<string>('')
  const { removeStored: removeToken } = handleStorage(TOKENNAME.userToken)
  const { setWorkoutSavedPopup } = useSetPopup()
  const { updateWorkoutIsSaved } = useWorkoutActions()
  const handleSaveLeaderboardRecord = useSaveLeaderboardRecord()

  const [login] = useMutation(LOGIN, {
    context: { clientName: 'personal' },
    variables: { email: '', password: '' },
    onCompleted(data) {
      loginAction(data.login)
      connectWorkout({ variables: { workoutId: savedWorkoutId } })
      handleSaveLeaderboardRecord()
    },
    onError: (err) => {
      setError(err.message)
    },
  })

  const loginWithWorkoutMutation = (email: string, password: string) => {
    removeToken()
    login({ variables: { email, password } })
  }

  const [connectWorkout] = useMutation(CONNECT_WORKOUT, {
    context: { clientName: 'personal' },
    onCompleted() {
      setWorkoutSavedPopup()
      updateWorkoutIsSaved(true)
    },
    onError: (err) => {
      setError(err.message)
    },
  })

  return { error, loginWithWorkoutMutation }
}

export default useLoginWithWorkoutMutation
