import { ReactNode } from 'react'
import './snackbar.scss'

interface Props {
  status?: 'success' | 'failed'
  children: ReactNode
  showSnackbar?: boolean
}

const SnackbarWrapper = ({ status, children, showSnackbar = true }: Props) => {
  return (
    <div className={`snackbarWrapper ${showSnackbar ? '' : 'snackbarWrapperSlideOut'}`} data-status={status}>
      {children}
    </div>
  )
}

export default SnackbarWrapper
