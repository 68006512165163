import React from 'react'

import { WorkoutName } from 'interfaces/workout'
import useAddHit from 'lib/hooks/useAddHit'
import { useActiveRound, useCurrentExercise, useWorkoutSession } from 'store/workout/selectors'
import Main from 'layout/main'
import StatsRoundActive from 'components/stats/stats-round-active'
import ChartExercise from 'components/chart-exercise/chart-exercise'
import Header from 'components/header/header'
import HeaderActive from 'components/header/active'
import Intensity from 'components/intensity/intensity'
import HitMeBtn from 'components/hit-me-btn/hit-me-btn'
import useCountdown from 'lib/hooks/useCountdown'
import { useNextStep } from 'lib/hooks/useNextStep'
import { useSingleBellSound } from 'lib/hooks/useSounds'

// This screen is only used for the freestyle or quickstart workout
const FreestyleQuickstartActive = () => {
  const playSound = useSingleBellSound()
  const { name } = useWorkoutSession()
  const currentExercise = useCurrentExercise()
  const activeRound = useActiveRound()
  const nextStep = useNextStep()

  const handleNextStep = () => {
    playSound()
    nextStep()
  }

  useAddHit()
  useCountdown({
    initCount: currentExercise.seconds,
    cb: handleNextStep,
  })

  const headerTitle = name === WorkoutName.QUICKSTART ? 'Quickstart' : `Freestyle Round ${activeRound + 1}`

  return (
    <Main className="nextround">
      <div className="freestyleQuickstartActive">
        <Header title={<h1>{headerTitle}</h1>} />
        <HeaderActive />
        <StatsRoundActive />
        <div className="freestyleQuickstartActive__chart">
          <ChartExercise />
        </div>
        <div className="freestyleQuickstartActive__footer">
          <Intensity />
          <HitMeBtn />
        </div>
      </div>
    </Main>
  )
}

export default FreestyleQuickstartActive
