import React from 'react'

import './graph-rounds.scss'

interface AvgLineProps {
  avg: number
  roundComplete?: boolean
}

const AverageLine = ({ avg, roundComplete }: AvgLineProps) => {
  // We need to prevent the line of getting out of bounds of the relative parent - thus to prevent bottom 100% or top 100%
  const linePositionStyle = (avg: number) => {
    if (avg > 50) {
      // revert percentage value because we position from top
      const newAvg = 100 - avg
      return { top: `calc(${newAvg}%)` }
    }
    return { bottom: `calc(${avg}%)` }
  }

  return (
    <div className="roundsGraph__y-axis__avg" style={linePositionStyle(avg)}>
      <div>
        <hr
          className={`roundsGraph__y-axis__avgLine ${
            roundComplete ? 'roundsGraph__y-axis__avgLine--roundComplete' : ''
          }`}
        />
        <p>Avg</p>
      </div>
    </div>
  )
}

export default AverageLine
