import { useState } from 'react'

const usePagination = () => {
  const [activeElement, setActiveElement] = useState(0)
  const handleNext = () => setActiveElement(activeElement + 1)
  const handlePrev = () => setActiveElement(activeElement - 1)
  const handleSelectActiveElement = (i: number) => setActiveElement(i)

  return { activeElement, handleNext, handlePrev, handleSelectActiveElement }
}

export default usePagination
