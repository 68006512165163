import { useEffect } from 'react'
import { gql, useSubscription } from '@apollo/client'

import { INRWorkoutSessionHit } from 'interfaces/workout'
import { useWorkoutActions } from 'store/workout/selectors'

const HIT = gql`
  subscription addHit {
    hit {
      force
      angle
      height
      punchedAt
    }
  }
`

const useAddHit = () => {
  const { data } = useSubscription<{ hit: INRWorkoutSessionHit }>(HIT)
  const { addHit } = useWorkoutActions()

  useEffect(() => {
    if (data && data.hit) addHit(data.hit)
  }, [data, addHit])
}

export default useAddHit
