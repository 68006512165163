import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useWorkoutTemplateQuery } from 'api/queries/workout-template'
import { useWorkoutSession } from 'store/workout/selectors'
import { useIsloggedIn } from 'store/auth/selectors'
import { pathsObj } from 'lib/constants/paths'
import Main from 'layout/main'
import Header from 'components/header/header'
import RoundsOverview from 'components/rounds-overview'
import Button from 'components/button'
import WeightButtons from 'components/weight-buttons/weight-buttons'
import NRMedia from 'components/nr-media/nr-media'

// Overview of a custom workout, before starting it
const WorkoutOverview: React.FC = () => {
  const navigate = useNavigate()
  const isLoggedIn = useIsloggedIn()
  const { id } = useParams<string>()
  const { loading } = useWorkoutTemplateQuery(id ?? '')
  const workoutSession = useWorkoutSession()

  const startWorkout = () => navigate(pathsObj.rounds.roundOverview)

  return (
    <Main className="nextround">
      <div className="workoutOverview">
        <div className="workoutOverview__scroll">
          <Header title={<h1>{workoutSession?.name || 'Loading'}</h1>} />
          <div className="row">
            <div className="col-12 workoutOverview__back">
              <Link to="/workouts">
                <h3>&lt; All workouts</h3>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <RoundsOverview />
              <NRMedia
                className={`workoutOverview__media ${loading ? 'workoutOverview__media--loading' : ''}`}
                imageLink={workoutSession.overviewImage?.downloadLocation}
                videoUrl={workoutSession.videoUrl}
                isVideoAutoPlay={false}
                loading={loading}
              />
            </div>
          </div>
        </div>
        <div className="workoutOverview__footer">
          {!isLoggedIn && (
            <>
              <h4 className="light">Select intensity level</h4>
              <WeightButtons />
            </>
          )}
          <div className="start-workout-Btn">
            <Button customClickEvent={startWorkout}>
              <h3>Start workout</h3>
            </Button>
          </div>
        </div>
      </div>
    </Main>
  )
}

export default WorkoutOverview
