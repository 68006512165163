import React from 'react'

import Icon, { IconNames } from '../icons/icons'
import { INRWorkoutTemplate } from 'interfaces/workout'
import { getWorkoutTemplateDuration } from 'lib/utilities'
import './workout.scss'

interface Props {
  template: INRWorkoutTemplate
  customClick: () => void
}

const WorkoutCard = ({ template, customClick }: Props) => {
  const workoutTime = getWorkoutTemplateDuration(template)
  const backgrImg = template.image?.downloadLocation

  return (
    <div className="workoutCard" onClick={customClick}>
      <div className="workoutCard__container">
        <div
          className="workoutCard__header"
          style={{
            backgroundImage: `url('${backgrImg}')`,
          }}
        ></div>
        <div className="workoutCard__content">
          <div className="workoutCard__content__title">{template?.name}</div>
          <div className="workoutCard__content__duration">
            <Icon name={IconNames.CLOCK}></Icon>
            <h5 className="light">{workoutTime}</h5>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkoutCard
