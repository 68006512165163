import { useCurrentCount } from 'store/workout/selectors'

export const LetsGoText = () => {
  const currentCount = useCurrentCount()

  return currentCount <= 3 ? (
    <h1 className="counter__text" data-size="lg">
      Let's go!
    </h1>
  ) : null
}

export const HitToStartText = () => <h1 className="counter__text">Hit the bag to start the round!</h1>
