import { useQuery, gql } from '@apollo/client'

export const ME = gql`
  query me {
    me {
      userName
      pin
      id
      privacyLevel
      email
      weightKg
    }
  }
`

export const useMeQuery = () => {
  return useQuery(ME, {
    context: { clientName: 'personal' },
  })
}
