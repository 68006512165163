import React from 'react'
import AnimatedNumber from 'animated-number-react'
import { getTotalForce } from 'lib/stats'
import { useCurrentExercise } from 'store/workout/selectors'

export const ExerciseActiveNumbers = () => {
  const { hits } = useCurrentExercise()
  const totalForce = Math.round(getTotalForce(hits))

  return (
    <div className="RoundActive__stats__exercise__box">
      <div>
        <h3>Hits</h3>
        <h1>{hits.length}</h1>
      </div>
      <div>
        <h3>Total force</h3>
        <h1>
          <AnimatedNumber value={totalForce} formatValue={(value: number) => value.toFixed(0)} duration={300} />
        </h1>
      </div>
    </div>
  )
}
