import { ReactNode } from 'react'
import { QRCodeSVG } from 'qrcode.react'

import { useSetPopup } from 'store/config/selectors'
import phoneImg from 'assets/images/phone.png'
import Button from 'components/button'
import { useSavedWorkoutId } from 'store/workout/selectors'
import { getValueByEnv } from 'lib/env'

interface InstructionCardProps {
  figure: ReactNode
  number: number
  title: ReactNode
  description: string
}

const InstructionCard = ({ figure, number, title, description }: InstructionCardProps) => {
  return (
    <div className="saveResultsPopup__instructions__card">
      {figure}
      <div className="saveResultsPopup__instructions__card__content">
        <h1>{number}.</h1>
        <div className="saveResultsPopup__instructions__card__text">
          {title}
          <h5 className="light">{description}</h5>
        </div>
      </div>
    </div>
  )
}

const Instructions = ({ handleNext }: { handleNext: () => void }) => {
  const savedWorkoutId = useSavedWorkoutId()
  const { setRegisterPopup } = useSetPopup()

  const appUrlByEnv = getValueByEnv<string>({
    production: 'app.nextroundboxing.com',
    staging: 'app.staging.nextroundboxing.com',
    develop: 'app.develop.nextroundboxing.com',
    local: process.env.REACT_APP_LOCAL_APP_URL ?? 'localhost:3002'
  })

  const appEnv = process.env.REACT_APP_ENV ?? ''

  if (appEnv !== 'production'){
    console.log(`https://${appUrlByEnv}/save/${savedWorkoutId}`)
  }

  return (
    <>
      <div className="saveResultsPopup__instructions__noAccount">
        <h4 className="light">No account yet?</h4>
        <Button color="text" customClickEvent={setRegisterPopup}>
          <h4>Start here</h4>
        </Button>
      </div>
      <div className="saveResultsPopup__instructions__cardsWrapper">
        <InstructionCard
          figure={<img src={phoneImg} alt="phone" />}
          number={1}
          title={<h5>Log in to the app</h5>}
          description="Open the NR Club app at app.nextroundboxing.com"
        />
        <InstructionCard
          figure={
            <QRCodeSVG
              size={150}
              value={`https://${appUrlByEnv}/save/${savedWorkoutId}`}
              imageSettings={{
                src: '/logo.png',
                width: 40,
                height: 20,
              }}
            />
          }
          number={2}
          title={
            <h5>
              Logged in? <br />
              Scan this QR-code
            </h5>
          }
          description="to save your results to the NR club app"
        />
      </div>
      <div className="saveResultsPopup__instructions__noPhone">
        <h3>No phone?</h3>
        <Button color="text" customClickEvent={handleNext}>
          <h3 className="light">Start here</h3>
        </Button>
      </div>
    </>
  )
}

export default Instructions
