interface Props {
  labelText: string
  isChecked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const CheckBox = ({ labelText, isChecked, onChange }: Props) => {
  return (
    <div className="checkBox">
      <input type="checkbox" onChange={onChange} />
      <p data-checked={isChecked ? 'true' : 'false'}>{labelText}</p>
    </div>
  )
}

export default CheckBox
