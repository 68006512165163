import React, { useState } from 'react'

import handleStorage, { TOKENNAME } from 'lib/handleStorage'
import withIdleTimer from 'lib/HOC/withIdleTimer'
import Main from 'layout/main'
import Button from 'components/button'

// This page is used to save the API key in the local storage
const ApiKeyPage = () => {
  const [key, setKey] = useState<string>('')
  const { setStored } = handleStorage(TOKENNAME.api)

  const saveApiKey = () => {
    if (key) {
      setStored(JSON.stringify(key))
      window.location.assign('/')
      return false
    }
  }

  const handleKeyPress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      saveApiKey()
    }
  }

  return (
    <Main className="nextround">
      <div className="apiKeyPage">
        Enter the API key:
        <form>
          <input
            type="text"
            placeholder={'API Key'}
            value={key}
            onKeyPress={handleKeyPress}
            onChange={(ev) => setKey(ev.target.value)}
            autoCapitalize="none"
          />
        </form>
        <Button customClickEvent={() => saveApiKey()}>Save & Refresh</Button>
      </div>
    </Main>
  )
}

export default withIdleTimer(ApiKeyPage)
