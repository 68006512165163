import React from 'react'

import { useWorkoutSession } from 'store/workout/selectors'
import { getExercisesFromWorkout } from 'lib/utilities'
import { getEvaluatedChallenge, getExerciseChallenge } from 'lib/challenges'
import { useWeightQuery } from 'api/queries/weight'
import Icon, { IconNames } from 'components/icons/icons'
import './challenge-results.scss'

interface SingleChallengeProps {
  isSuccess: boolean
  title?: string
}

const SingleChallenge: React.FC<SingleChallengeProps> = ({ isSuccess, title = '' }) => {
  const iconName = isSuccess ? IconNames.CHECK : IconNames.CROSS_RED
  const contentClass = isSuccess ? '' : 'challenge-results__constraint--failed'

  return (
    <div className="challenge-results__constraint">
      <Icon name={iconName} size={20} />
      <h5 className={contentClass}>{title}</h5>
    </div>
  )
}

const ChallengeResults = () => {
  const { data } = useWeightQuery()
  const workoutSession = useWorkoutSession()
  const exerciseArray = getExercisesFromWorkout(workoutSession)
  const challengeExercises = exerciseArray.filter((exercise) => !!exercise.challenges?.length)
  const challengeResults = challengeExercises.map((exercise) => {
    const challenge = getExerciseChallenge(exercise)
    return getEvaluatedChallenge(exercise, challenge, data?.me?.weightKg)
  })

  const successFulChallenges = challengeResults.filter((result) => !!result.showSuccess)

  return (
    <div className="challenge-results">
      <span className="challenge-results__challenges">
        <h3 className="light challenge-results__challenges__key">Challenges</h3>
        <h3>
          {successFulChallenges.length}/{challengeResults.length}
        </h3>
      </span>
      {challengeResults.map(({ showSuccess, title }, index: number) => (
        <SingleChallenge key={index} isSuccess={showSuccess} title={title} />
      ))}
    </div>
  )
}

export default ChallengeResults
