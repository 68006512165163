import { ComponentType, useEffect } from 'react'
import { useIdleTimer } from 'react-idle-timer'

import { useIsloggedIn } from 'store/auth/selectors'
import { useSetPopup } from 'store/config/selectors'
import handleStorage, { TOKENNAME } from 'lib/handleStorage'

// This HOC is used to trigger a popup when the user logged in, has not checked "remember me" and is idle for a certain amount of time
// It is applied to pages where there is no active workout happening
const withIdleTimer = <T extends {}>(Component: ComponentType<T>) => {
  return (props: T) => {
    const { setIdleLogoutPopup } = useSetPopup()
    const isLoggedIn = useIsloggedIn()

    const handleIdle = () => {
      const { stored: storedRememberMe } = handleStorage(TOKENNAME.rememberMe)
      const storedRememberMeBool = storedRememberMe && JSON.parse(storedRememberMe)
      if (!!storedRememberMeBool || !!storedRememberMe || !isLoggedIn) return
      if (!!isLoggedIn) setIdleLogoutPopup()
    }

    const { start } = useIdleTimer({
      timeout: 30000, // 30 sec
      onIdle: handleIdle,
      startManually: true,
    })

    useEffect(() => {
      start()
    }, [start])

    return <Component {...props} />
  }
}

export default withIdleTimer
