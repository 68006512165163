import { gql, useMutation } from '@apollo/client'
import { useState } from 'react'

import { handleAuthError, validateForm } from 'lib/auth-helpers'
import handleStorage, { TOKENNAME } from 'lib/handleStorage'
import { useAuthActions } from 'store/auth/selectors'

export const LOGIN = gql`
  mutation login($password: String!, $email: String!) {
    login(password: $password, email: $email)
  }
`

const useLoginMutation = (toggleLoginPopup: () => void) => {
  const { login: loginAction } = useAuthActions()
  const [error, setError] = useState<string>('')
  const { removeStored: removeToken } = handleStorage(TOKENNAME.userToken)
  const { setStored: setRememberMe, removeStored: removeRememberMe } = handleStorage(TOKENNAME.rememberMe)

  const [login] = useMutation(LOGIN, {
    context: { clientName: 'personal' },
  })

  const loginMutation = (loginForm: { [key: string]: string }, rememberMe: boolean) => {
    const handleLoginError = handleAuthError(setError)
    const wrongInput = validateForm(loginForm)

    if (!!wrongInput) {
      handleLoginError(wrongInput)
    } else {
      const { email, password } = loginForm
      removeToken()
      removeRememberMe()
      login({ variables: { email, password } })
        .then(({ data }) => {
          !!rememberMe && setRememberMe(JSON.stringify(rememberMe))
          loginAction(data.login)
          toggleLoginPopup()
        })
        .catch((err) => handleLoginError(err.message))
    }
  }

  return { loginMutation, error }
}

export default useLoginMutation
