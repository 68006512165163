import React from 'react'

import { toClasses } from 'lib/utilities'

export type ButtonColors = 'primary' | 'secondary' | 'tertiary' | 'light' | 'player2' | 'text'
interface ButtonStyle {
  small?: boolean
  className?: string
  icon?: boolean
  color?: ButtonColors
  fullColor?: boolean
  disabled?: boolean
  customClickEvent?: () => void
  children?: any
  size?: number | string
  width?: number
  square?: boolean
}

const initialButton: ButtonStyle = {
  disabled: false,
  square: false,
  icon: false,
  small: false,
  className: '',
  color: 'primary',
  fullColor: false,
  customClickEvent: () => {},
  children: 'no-title',
}

const Button: React.FC<ButtonStyle> = (props: ButtonStyle) => {
  const attributes = {
    disabled: props.disabled,
  }

  const customClick = () => {
    if (props.disabled) return
    if (props.customClickEvent) props.customClickEvent()
  }

  const classes = {
    Button: true,
    'Button--disabled': props.disabled,
    'Button--small': props.small,
    'Button--primary': props.color === 'primary',
    'Button--secondary': props.color === 'secondary',
    'Button--tertiary': props.color === 'tertiary',
    'Button--player2': props.color === 'player2',
    'Button--light': props.color === 'light',
    'Button--text': props.color === 'text',
    'Button--full': props.fullColor || props.disabled,
    Icon: props.icon,
    'Button--square': props.square,
  }

  return (
    <button
      style={{
        width: props.width ? props.width : props.size ? props.size : '',
        height: props.size ? props.size : '',
      }}
      onClick={() => customClick()}
      {...attributes}
      className={`${toClasses(classes)} ${props.className}`}
    >
      {props.children}
    </button>
  )
}

Button.defaultProps = initialButton
export default Button
