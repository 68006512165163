import useSound from 'use-sound'

import { useIsSoundActive } from 'store/config/selectors'

enum SOUNDS {
  singleBell = '/single-bell.mp3',
  tripleBell = '/triple-bell.mp3',
}

const usePlaySound = (sound: SOUNDS | string) => {
  const isSoundActive = useIsSoundActive()
  const volume = isSoundActive ? 0.1 : 0
  const [playSound] = useSound(sound, { volume })
  return playSound
}

export const useSingleBellSound = () => usePlaySound(SOUNDS.singleBell)

export const useTrippleBellSound = () => usePlaySound(SOUNDS.tripleBell)
