import React, { useState } from 'react'

import { WorkoutName } from 'interfaces/workout'
import { getWorkoutDuration } from 'lib/stats'
import { useNextStep } from 'lib/hooks/useNextStep'
import useSkipStep from 'lib/hooks/useSkipStep'
import useCountdown from 'lib/hooks/useCountdown'
import useHitToTriggerEvent from 'lib/hooks/useHitToTriggerEvent'
import { getValueStartByHitToggleEnv } from 'lib/env'
import { useTrippleBellSound } from 'lib/hooks/useSounds'
import { useActiveRound, useCurrentRound, useWorkoutActions, useWorkoutSession } from 'store/workout/selectors'
import Main from 'layout/main'
import Header from 'components/header/header'
import HeaderActive from 'components/header/active'
import RoundsOverview from 'components/rounds-overview'
import { CounterSizes } from 'components/counter/counter'
import CurrentCounter from 'components/counter/counter'
import NRMedia from 'components/nr-media/nr-media'
import HitMeBtn from 'components/hit-me-btn/hit-me-btn'
import { HitToStartText } from 'components/counter/fragments'

// Round overview / countdown between rounds
const RoundCurrentOverview = () => {
  const currentRound = useCurrentRound()
  const workoutSession = useWorkoutSession()
  const activeRound = useActiveRound()
  const nextStep = useNextStep()
  const playSound = useTrippleBellSound()
  const { decrementCurrentCount } = useWorkoutActions()

  const countDownAndEnableEvent = () => {
    decrementCurrentCount()
    playSound()
    enableEvent()
  }

  const handleNextStep = () => {
    playSound()
    nextStep()
  }

  const countdownCb = getValueStartByHitToggleEnv() ? countDownAndEnableEvent : handleNextStep

  const { enableEvent, eventEnabled } = useHitToTriggerEvent({ cb: handleNextStep })

  useCountdown({ initCount: currentRound.countdownSeconds, cb: countdownCb })

  useSkipStep({
    imgDownload: currentRound.image?.downloadLocation,
    videoUrl: currentRound.videoUrl,
  })

  const hasVideo = !!currentRound.videoUrl
  const [showCounter, setShowCounter] = useState<boolean>(!hasVideo)

  const timeLeft = getWorkoutDuration({ ...workoutSession, rounds: workoutSession.rounds.slice(activeRound - 1) })
  const headertext = workoutSession.name === WorkoutName.FREESTYLE ? 'Freestyle Overview' : `Round ${activeRound + 1}`

  return (
    <Main className="nextround">
      <div className="Round">
        <Header title={<h1>{headertext}</h1>} />
        <HeaderActive value={timeLeft} />
        <div className="Round__content">
          <RoundsOverview />
          <div className="Round__content__instructions">
            <NRMedia
              className="Round__content__instructions__media"
              isVideoAutoPlay
              imageLink={currentRound.image?.downloadLocation}
              videoUrl={currentRound.videoUrl}
              onVideoEnd={() => setShowCounter(true)}
              onVideoError={() => setShowCounter(true)}
            />
            <div className="Round__content__next-round-container">
              <div className="Round__content__next-round-box">
                {!!showCounter && (
                  <>
                    <CurrentCounter
                      size={CounterSizes.XSMALL}
                      endCounter={nextStep}
                      initCount={currentRound.countdownSeconds}
                      textEl={eventEnabled ? <HitToStartText /> : null}
                    />
                    <h4 className="Round__content__next-round-text">Next Round starts in</h4>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <HitMeBtn />
    </Main>
  )
}

export default RoundCurrentOverview
