import { INRWorkoutSessionRound } from 'interfaces/workout'
import { useActiveRound, useWorkoutSession } from 'store/workout/selectors'

const RoundsOverview: React.FC = () => {
  const { rounds } = useWorkoutSession()
  const activeRound = useActiveRound()

  return (
    <div className="RoundsOverview">
      <h5 className="RoundsOverview__title gray">Overview</h5>
      <div className="RoundsOverview__roundsWrapper">
        {rounds.map((round: INRWorkoutSessionRound, index: number) => {
          const roundDone = index < activeRound
          const isActiveRound = index === activeRound

          return (
            <div key={index} className={`RoundsOverview__roundsWrapper__round`}>
              <div className="RoundsOverview__roundsWrapper__round__title">
                <h3 className={`${isActiveRound ? '' : 'light'}`}>Round {index + 1}</h3>
              </div>
              <div className="RoundsOverview__roundsWrapper__round__bar">
                <div
                  className={`RoundsOverview__roundsWrapper__round__bar__fill ${
                    !roundDone && isActiveRound ? 'active' : ''
                  } ${roundDone || isActiveRound ? 'done' : ''}`}
                ></div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default RoundsOverview
