import { useEffect } from 'react'

import { useNextStep } from './useNextStep'
import { useWorkoutSession } from 'store/workout/selectors'
import { WorkoutName } from 'interfaces/workout'

// skip step if there is no image or video. This is used on countdown pages: round-exercise-countdown, round-overview
// This is to prevent the user having to sometimes sit through two countodnws (round, and then exercise)
const useSkipStep = ({ imgDownload = '', videoUrl = '' }: { imgDownload?: string; videoUrl?: string | null }) => {
  const workoutSession = useWorkoutSession()
  const nextStep = useNextStep()

  useEffect(() => {
    const isCustomWorkout = workoutSession.name !== (WorkoutName.FREESTYLE || WorkoutName.QUICKSTART)

    if (!imgDownload && !videoUrl && isCustomWorkout) nextStep()
  }, [nextStep, imgDownload, videoUrl, workoutSession.name])
}

export default useSkipStep
