import React from 'react'
import ReactDOM from 'react-dom/client'
import { ApolloProvider } from '@apollo/client'
import * as serviceWorker from './serviceWorker'

import App from './App'
import initApollo from './api/client'
import './index.scss'
import 'styles/globals.scss'
import 'styles/main.scss'
import 'bootstrap/dist/css/bootstrap-grid.min.css'

const client = initApollo()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
