import React, { useState } from 'react'
import ReactPlayer from 'react-player'

import { useIsSoundActive } from 'store/config/selectors'
import { NRVideoError, NRVideoPlayIcon, NRVideoProps } from 'components/nr-video'
import { Spinner } from 'components/spinner/spinner'
import './nr-video.scss'

const ClickVideo = ({ src, onVideoEnd, onError }: Omit<NRVideoProps, 'className' | 'isAutoPlay'>) => {
  const isSoundActive = useIsSoundActive()
  const [isVidPlaying, setIsVidPlaying] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handlePlayVideo = () => {
    setIsLoading(true)
    setIsVidPlaying(true)
  }

  return (
    <>
      {isError && <NRVideoError onError={onError} />}
      {isLoading && <Spinner className="NRVideo__loading" />}
      <ReactPlayer
        className={`${isLoading ? 'NRVideo__reactPlayer--hide' : 'NRVideo__reactPlayer'}`}
        width="100%"
        height="100%"
        url={src}
        playing={isVidPlaying}
        volume={isSoundActive ? 1 : 0}
        playsinline
        onError={() => setIsError(true)}
        onReady={() => setIsLoading(false)}
        onEnded={onVideoEnd}
        light={true}
        playIcon={<NRVideoPlayIcon handlePlayVideo={handlePlayVideo} />}
      />
    </>
  )
}

export default ClickVideo
