import { WorkoutName } from 'interfaces/workout'
import { pathsObj } from 'lib/constants/paths'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  useActiveExercise,
  useActiveRound,
  useWorkoutActions,
  useWorkoutSession,
  useCurrentRound,
} from 'store/workout/selectors'
import useUpdateExerciseStarted from './useUpdateExerciseStarted'

const { freestyle, workouts, rounds } = pathsObj
const { freestyleActive } = freestyle
const { workoutComplete } = workouts
const { roundOverview, roundComplete, exerciseCountdown, roundActive } = rounds

export const useNextStepQuickstart = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const quickstartNextStepObj = {
    [freestyleActive]: () => navigate(workoutComplete),
  }

  return quickstartNextStepObj[pathname]
}

const useNextStepFreestyle = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { rounds } = useWorkoutSession()
  const activeRound = useActiveRound()
  const { updateActiveRound } = useWorkoutActions()
  const updateExerciseStarted = useUpdateExerciseStarted()

  const freestyleNextStepObj = {
    [freestyleActive]: () => navigate(roundComplete),
    [roundComplete]: async () => {
      if (rounds.length > activeRound + 1) {
        updateActiveRound(activeRound + 1)
        await updateExerciseStarted()
        navigate(freestyleActive)
      } else {
        navigate(workoutComplete)
      }
    },
    [roundOverview]: async () => {
      await updateExerciseStarted()
      navigate(freestyleActive)
    },
  }

  return freestyleNextStepObj[pathname]
}

const useNextStepCustom = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { rounds } = useWorkoutSession()
  const currentRound = useCurrentRound()
  const activeRound = useActiveRound()
  const activeExercise = useActiveExercise()
  const { updateActiveRound, updateActiveExercise } = useWorkoutActions()
  const updateExerciseStarted = useUpdateExerciseStarted()

  const customNextStepObj = {
    [roundOverview]: async () => {
      await updateExerciseStarted()
      navigate(exerciseCountdown)
    },
    [exerciseCountdown]: async () => {
      await updateExerciseStarted()
      navigate(roundActive)
    },
    [roundActive]: () => {
      if (activeExercise + 1 < currentRound.exercises.length) {
        updateActiveExercise(activeExercise + 1)
        navigate(exerciseCountdown)
      } else {
        navigate(roundComplete)
      }
    },
    [roundComplete]: async () => {
      if (activeRound + 1 < rounds.length) {
        updateActiveRound(activeRound + 1)
        updateActiveExercise(0)
        navigate(roundOverview)
        await updateExerciseStarted()
      } else {
        navigate(workoutComplete)
      }
    },
  }

  return customNextStepObj[pathname]
}

export const useNextStep = () => {
  const { name } = useWorkoutSession()
  const workoutName = name === WorkoutName.QUICKSTART || name === WorkoutName.FREESTYLE ? name : 'custom'

  const nextStepObj: { [key: string]: () => void } = {
    [WorkoutName.QUICKSTART]: useNextStepQuickstart(),
    [WorkoutName.FREESTYLE]: useNextStepFreestyle(),
    custom: useNextStepCustom(),
  }

  return nextStepObj[workoutName]
}
