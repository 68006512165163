import { ReactNode } from 'react'

export enum ConnectionStates {
  GOOD = 'good',
  BAD = 'bad',
  NO_CONNECTION = 'noConnection',
}

export enum ConnectionIssueNames {
  WIFI = 'wifi',
  PACKET = 'packet',
  REBOOT = 'reboot',
  POWER = 'power',
  NONE = 'none',
}

export interface ConnectionStatus {
  status: ConnectionStates
  issue: ConnectionIssueNames
}

export interface ConnectionContent {
  title: string
  description: ReactNode
}

export interface ConnectionData {
  latestWifiStrength: number | null
  averageReceivedPackets: number | null
  latestRebootCount: number | null
}
