import { gql, useQuery } from '@apollo/client'
import { Me } from 'interfaces/user'
import { useIsloggedIn } from 'store/auth/selectors'

const WEIGHT = gql`
  {
    me {
      weightKg
    }
  }
`

export const useWeightQuery = () => {
  const isLoggedIn = useIsloggedIn()
  const { data, error } = useQuery<Me>(WEIGHT, {
    context: { clientName: 'personal' },
    skip: !isLoggedIn,
  })

  if (error) throw new Error('Could not fetch weight')

  return { data }
}
