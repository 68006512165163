import React, { ReactNode } from 'react'

import Icon, { IconNames } from 'components/icons/icons'
import { useSetPopup } from 'store/config/selectors'

interface Props {
  showClose?: boolean
  customCloseEvent?: () => void
  children: ReactNode
}

const PopupWrapper = ({ children, customCloseEvent, showClose = true }: Props) => {
  const { closePopup } = useSetPopup()

  return (
    <div className="popupBackground">
      <div className="popupWrapper">
        <div className="popupWrapper__header">
          <div className="popupWrapper__header__logo" />
          {showClose && <Icon name={IconNames.CROSS} size={32} customClickEvent={customCloseEvent || closePopup} />}
        </div>
        <div className="popupWrapper__body">{children}</div>
      </div>
    </div>
  )
}

export default PopupWrapper
