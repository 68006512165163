import { gql } from '@apollo/client'

export const CREATE_LEADERBOARD = gql`
  mutation createLeaderboard($challengeId: String!) {
    createLeaderboard(input: { challenge: { id: $challengeId } }) {
      id
    }
  }
`

export const CREATE_LEADERBOARD_RECORD = gql`
  mutation createLeaderboardRecord($score: Float!, $userId: String!, $leaderboardId: String!) {
    createLeaderboardRecord(input: { score: $score, user: { id: $userId }, leaderboard: { id: $leaderboardId } }) {
      id
      score
    }
  }
`

export const UPDATE_LEADERBOARD_RECORD = gql`
  mutation updateLeaderboardRecord($score: Float!, $recordId: String!, $userId: String!) {
    updateLeaderboardRecord(input: { score: $score, id: $recordId, user: { id: $userId } }) {
      id
    }
  }
`
