import Button from 'components/button'
import { UserWeight } from 'interfaces/user'
import { useAuthActions, useUserWeight } from 'store/auth/selectors'

const SingleWeightButton = ({ weight, text }: { weight: UserWeight; text: string }) => {
  const { setUserWeight } = useAuthActions()
  const userWeight = useUserWeight()
  const isActive = userWeight === weight

  return (
    <Button
      className={`singleWeightButton ${isActive ? 'singleWeightButton--active' : ''}`}
      customClickEvent={() => setUserWeight(weight)}
    >
      <h3>{text}</h3>
    </Button>
  )
}

const WeightButtons = ({ className = '' }: { className?: string }) => {
  return (
    <div className={`weightButtons ${className}`}>
      <SingleWeightButton weight={UserWeight.LIGHT} text="Light" />
      <SingleWeightButton weight={UserWeight.MEDIUM} text="Medium" />
      <SingleWeightButton weight={UserWeight.HEAVY} text="Heavy" />
    </div>
  )
}

export default WeightButtons
