import React from 'react'

import Button from 'components/button'
import useLogout from 'lib/hooks/useLogout'
import handleStorage, { TOKENNAME } from 'lib/handleStorage'
import { Counter, CounterSizes } from 'components/counter/counter'
import PopupWrapper from 'components/popup/wrapper/wrapper'
import { useSetPopup } from 'store/config/selectors'

const IdleLogoutPopup = () => {
  const logout = useLogout()
  const { closePopup } = useSetPopup()
  const { setStored } = handleStorage(TOKENNAME.rememberMe)

  const handleKeepLoggedIn = () => {
    closePopup()
    setStored(JSON.stringify(true))
  }

  const handleLogOut = () => {
    logout()
    closePopup()
  }

  return (
    <PopupWrapper showClose={false}>
      <div className="PopupIdleLogout">
        <h2>Logout</h2>
        <h3>Do you want to log out?</h3>
        <p>Did you save your data? Are you sure?</p>
        <div className="PopupIdleLogout__buttonBox">
          <Button customClickEvent={handleLogOut}>
            <h3>Yes</h3>
          </Button>
          <Button color="secondary" customClickEvent={handleKeepLoggedIn}>
            <h3>No</h3>
          </Button>
        </div>
        <Counter size={CounterSizes.XSMALL} isTimeInMin currentCount={900} endCounter={handleLogOut} />
      </div>
    </PopupWrapper>
  )
}

export default IdleLogoutPopup
