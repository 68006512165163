import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PopupWrapper from '../wrapper/wrapper'
import Button from 'components/button'
import { useSetPopup } from 'store/config/selectors'

const AccountCreatedPopup = () => {
  const { closePopup, setRegisterPopup } = useSetPopup()

  return (
    <PopupWrapper>
      <div className="popupAccountCreated">
        <h2>Check your mailbox!</h2>
        <h2 className="light">Finish your account with the link in the mail. Then log in and check your data! </h2>
        <h4 className="light">No mail received? Check your SPAM folder</h4>
      </div>
      <div className="popupAccountCreated__btnswWrapper">
        <Button customClickEvent={setRegisterPopup}>
          <h3>
            <FontAwesomeIcon icon={faChevronLeft} />
            Try again
          </h3>
        </Button>
        <Button fullColor customClickEvent={closePopup}>
          <h3>Finish</h3>
        </Button>
      </div>
    </PopupWrapper>
  )
}

export default AccountCreatedPopup
