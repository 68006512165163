import { useCallback, useState } from 'react'

const useLoginForm = () => {
  const [loginForm, setLoginForm] = useState<{ [key: string]: string }>({
    email: '',
    password: '',
  })
  const [rememberMe, setRememberMe] = useState<boolean>(false)

  const handleRemember = (evt: React.BaseSyntheticEvent) => {
    const { checked } = evt.target
    setRememberMe(checked)
  }

  const updateLoginForm = useCallback((evt: React.BaseSyntheticEvent) => {
    evt.preventDefault()
    const { name, value } = evt.target
    setLoginForm((prevState: { [key: string]: string }) => ({
      ...prevState,
      [name]: value,
    }))
  }, [])

  return { loginForm, updateLoginForm, handleRemember, rememberMe }
}

export default useLoginForm
