import { useId } from 'react'

type SingleDotProps = Pick<PaginationDotsProps, 'handleSelectActiveElement'> & { isActive: boolean }

interface PaginationDotsProps {
  activeElement: number
  numOfElements: number
  handleSelectActiveElement: (index: number) => void
}

const SingleDot = ({ isActive }: SingleDotProps) => (
  <div className={`singleDot ${isActive ? 'singleDot--active' : ''}`} />
)

const PaginationDots = ({ handleSelectActiveElement, activeElement, numOfElements }: PaginationDotsProps) => {
  const id = useId()

  return (
    <div className="paginationDots">
      {new Array(numOfElements).fill(undefined).map((_, i) => {
        const isActive = i === activeElement
        return <SingleDot key={id + i} isActive={isActive} handleSelectActiveElement={handleSelectActiveElement} />
      })}
    </div>
  )
}

export default PaginationDots
