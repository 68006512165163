import { gql, useMutation } from '@apollo/client'
import { useState } from 'react'
import { useSetPopup } from 'store/config/selectors'

export const FORGOT_PASSWORD = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email)
  }
`

const useForgotPasswordMutation = () => {
  const { setCheckEmailPopup } = useSetPopup()
  const [error, setError] = useState<string>('')

  const [forgotPassword] = useMutation(FORGOT_PASSWORD, {
    onError: (err) => {
      setError(err.message)
    },
    onCompleted() {
      setCheckEmailPopup()
    },
  })

  const forgotPasswordMutation = (email: string) => {
    forgotPassword({ variables: { email } })
  }

  return {
    error,
    forgotPasswordMutation,
  }
}

export default useForgotPasswordMutation
