import { ApolloClient, InMemoryCache, createHttpLink, split } from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'

let apolloClient: any = null

let apiKey = window.localStorage.getItem('NEXTROUND_API')
let apiKeyString: null | string = null

if (apiKey && JSON.parse(apiKey)) {
  apiKey = JSON.parse(apiKey)
  apiKeyString = apiKey?.toLowerCase().startsWith('basic') ? apiKey : `Basic ${apiKey}`
} else {
  apiKey = null
}

let token = window.localStorage.getItem('NEXTROUND_USER_TOKEN')
let tokenString: null | string = null

if (token) {
  tokenString = token?.toLowerCase().startsWith('bearer') ? token : `Bearer ${token}`
} else {
  token = null
}

const webSocketLinkProtocol = process.env.REACT_APP_API_UNSECURE ? 'ws://' : 'wss://'
const webSocketLinkUrl = `${process.env.REACT_APP_API_WS_URL ?? process.env.REACT_APP_API_URL}/graphql`

const wsLink = new WebSocketLink({
  uri: webSocketLinkProtocol + webSocketLinkUrl,
  options: {
    reconnect: true,
    connectionParams: () => ({
      Authorization: apiKeyString,
      'X-Authorization-Method': 'api',
    }),
  },
})

const httpLinkProtocol = process.env.REACT_APP_API_UNSECURE ? 'http://' : 'https://'
const httpLinkUrl = `${process.env.REACT_APP_API_URL}/graphql`

const httpLink = createHttpLink({
  uri: httpLinkProtocol + httpLinkUrl,
})

// For personal requests to the API - mostly auth-related requests, access is only granted if a token is provided, along with X-Authorization-Method: personal
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('NEXTROUND_USER_TOKEN')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'X-Authorization-Method': 'personal',
    },
  }
})

const personalLink = authLink.concat(httpLink)

// For active workout API requests, access is granted if an API key is provided, along with X-Authorization-Method: api
const apiLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: apiKeyString,
      'X-Authorization-Method': 'api',
    },
  }
})

const splitApiLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  apiLink.concat(wsLink),
  apiLink.concat(httpLink)
)

function createClient() {
  return new ApolloClient({
    link: split((operation) => operation.getContext().clientName === 'personal', personalLink, splitApiLink),
    cache: new InMemoryCache({
      addTypename: false,
      typePolicies: {
        Query: {
          fields: {
            me: {
              merge(existing = {}, incoming) {
                return { ...existing, ...incoming }
              },
            },
          },
        },
      },
    }),
  })
}

export default function initApollo() {
  if (!apolloClient) {
    apolloClient = createClient()
  }

  return apolloClient
}
