import WeightButtons from 'components/weight-buttons/weight-buttons'

const SetupFreestyleIntensity = () => {
  return (
    <div className="setupFreestyleIntensity">
      <h2 className="light">Set up Intensity</h2>
      <h4 className="light">Select intensity level</h4>
      <WeightButtons className="setupFreestyleIntensity__weightButtons" />
    </div>
  )
}

export default SetupFreestyleIntensity
