import { DifficultyLevel } from 'interfaces/workout'

export const pathsObj = {
  main: {
    home: '/',
    api: '/api',
  },
  workouts: {
    workouts: '/workouts',
    workoutComplete: '/workout-complete',
    workoutOverview: {
      default: '/workout-overview/:id/:level',
      getWorkoutOverview: (id: string, level: DifficultyLevel | string) => `/workout-overview/${id}/${level}`,
    },
  },
  rounds: {
    roundOverview: '/round-overview',
    roundActive: '/round-active',
    roundComplete: '/round-complete',
    exerciseCountdown: '/exercise-countdown',
  },
  freestyle: {
    freestyleActive: '/freestyle-active',
  },
}
