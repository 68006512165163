import { useWeightQuery } from 'api/queries/weight'
import { getEvaluatedRoundIntensity } from 'lib/intensity'
import { getRoundStats } from 'lib/stats'
import { getAverage } from 'lib/utilities'
import { getActiveWeight } from 'lib/weight'
import { useUserWeight } from 'store/auth/selectors'
import { useCurrentRound } from 'store/workout/selectors'

const StatsRoundComplete = () => {
  const currentRound = useCurrentRound()
  const { data: weightData } = useWeightQuery()
  const userWeight = useUserWeight()
  const weight = getActiveWeight(weightData, userWeight)
  const roundIntensity = getEvaluatedRoundIntensity(currentRound, weight)
  const avgIntensity = getAverage(roundIntensity)
  const roundedAvgIntensity = Math.round(avgIntensity * 10) / 10
  const { numOfHits, totalForce, avgSpeed } = getRoundStats(currentRound)

  return (
    <div className="statsRoundComplete">
      <div className="statsRoundComplete__row">
        <h3 className="statsRoundComplete__row--title light">Hits</h3>
        <h3 className="statsRoundComplete__row--value">{numOfHits}</h3>
      </div>
      <div className="statsRoundComplete__row">
        <h3 className="statsRoundComplete__row--title light">Total force</h3>
        <h3 className="statsRoundComplete__row--value">{totalForce}</h3>
      </div>
      <div className="statsRoundComplete__row">
        <h3 className="statsRoundComplete__row--title light">Avg. speed</h3>
        <h3 className="statsRoundComplete__row--value">{avgSpeed} hits/m</h3>
      </div>
      <div className="statsRoundComplete__row">
        <h3 className="statsRoundComplete__row--title light">Avg. intensity</h3>
        <h3 className="statsRoundComplete__row--value">{roundedAvgIntensity}</h3>
      </div>
    </div>
  )
}

export default StatsRoundComplete
