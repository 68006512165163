import React from 'react'

import useLoginForm from 'lib/hooks/useLoginForm'
import useLoginMutation from 'api/mutations/login'
import Button from 'components/button'
import PopupWrapper from 'components/popup/wrapper/wrapper'
import { useSetPopup } from 'store/config/selectors'
import Input from 'components/input/input'
import CheckBox from 'components/checkbox/checkbox'

const LoginPopup = () => {
  const { closePopup, setRegisterPopup, setForgotPasswordPopup } = useSetPopup()
  const { loginForm, updateLoginForm, rememberMe, handleRemember } = useLoginForm()
  const { loginMutation, error } = useLoginMutation(closePopup)

  const handleLogin = () => {
    loginMutation(loginForm, rememberMe)
  }

  return (
    <PopupWrapper>
      <div className="popupLogin">
        <h2>Login</h2>
        <div className="popupLogin__noAccount">
          <h4 className="light">No account yet?</h4>
          <Button color="text" customClickEvent={setRegisterPopup}>
            <h4>Start here</h4>
          </Button>
        </div>
        <Input
          type="email"
          name="email"
          id="email"
          placeholder="Email address"
          value={loginForm.email}
          onChange={updateLoginForm}
        />
        <Input
          type="password"
          name="password"
          id="password"
          placeholder="Password"
          value={loginForm.password}
          onChange={updateLoginForm}
        />
        <CheckBox isChecked={rememberMe} labelText="Remember me" onChange={handleRemember} />
        <p className="popupLogin__error">{error && error}</p>
        <div className="popupLogin__btnsWrapper">
          <Button color="text" customClickEvent={setForgotPasswordPopup}>
            <h4 className="light">Forgot your password?</h4>
          </Button>
          <Button fullColor customClickEvent={handleLogin}>
            <h3>Sign in</h3>
          </Button>
        </div>
      </div>
    </PopupWrapper>
  )
}

export default LoginPopup
