import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useLoginWithWorkoutMutation from 'api/mutations/login-with-workout'
import { useSetPopup } from 'store/config/selectors'
import useSaveResultsForm from 'lib/hooks/useSaveResultsForm'
import Button from 'components/button'
import Input from 'components/input/input'

const Form = ({ handlePrev }: { handlePrev: () => void }) => {
  const { setForgotPasswordPopup } = useSetPopup()
  const { error, loginWithWorkoutMutation } = useLoginWithWorkoutMutation()
  const { saveResultsForm, updateSaveResultsForm } = useSaveResultsForm()
  const { email, password } = saveResultsForm

  const handleConnectWorkout = () => {
    loginWithWorkoutMutation(email, password)
  }

  return (
    <div className="saveResultsPopup__form">
      <div className="saveResultsPopup__form__card">
        <h2 className="light">Fill in your credentials to receive the data</h2>
        <Input type="text" name="email" onChange={updateSaveResultsForm} value={email} placeholder="email" />
        <Input
          type="password"
          name="password"
          onChange={updateSaveResultsForm}
          value={password}
          placeholder="password"
        />
        <div className="saveResultsPopup__form__buttons">
          <Button color="text">
            <h4 className="light" onClick={setForgotPasswordPopup}>
              Forgot your password?
            </h4>
          </Button>
          <Button color="primary" fullColor customClickEvent={handleConnectWorkout}>
            <h3>Send</h3>
          </Button>
        </div>
        <p>{error ? error : null}</p>
      </div>
      <Button customClickEvent={handlePrev}>
        <h3>
          <FontAwesomeIcon icon={faChevronLeft} />
          Go back
        </h3>
      </Button>
    </div>
  )
}

export default Form
