interface TabsPanelProps {
  tabs: string[]
  activeIndex: number
  selectTab: (index: number) => void
}

const TabsPanel = ({ tabs, activeIndex, selectTab }: TabsPanelProps) => {
  const renderTabs = tabs.map((tab: string, index: number) => {
    return (
      <h5
        key={index}
        className={`tabsPanel__tab 
          ${activeIndex === index ? 'tabsPanel__tab--active' : 'light'}
        }`}
        onClick={() => selectTab(index)}
      >
        {tab}
      </h5>
    )
  })

  return <div className="tabsPanel__wrapper">{renderTabs}</div>
}

export default TabsPanel
