import { gql, useMutation } from '@apollo/client'
import { useState } from 'react'
import { useSetPopup } from 'store/config/selectors'
import { useWorkoutActions } from 'store/workout/selectors'

// All workout data is saved onMount when the user completes a workout. Connecting that workout to an account can be done via this call
export const CONNECT_WORKOUT_PIN = gql`
  mutation connectWorkoutWithPIN($workoutId: String!, $pin: String!, $userName: String!) {
    connectWorkoutWithPIN(workoutId: $workoutId, pin: $pin, userName: $userName)
  }
`

const useConnectWorkoutPinMutation = () => {
  const [error, setError] = useState<string>('')
  const { setWorkoutSavedPopup } = useSetPopup()
  const { updateWorkoutIsSaved } = useWorkoutActions()

  const [connectWorkoutWithPIN] = useMutation(CONNECT_WORKOUT_PIN, {
    onCompleted() {
      setWorkoutSavedPopup()
      updateWorkoutIsSaved(true)
    },
    onError: (err) => {
      setError(err.message)
    },
  })

  const connectWorkoutPinMutation = (workoutId: string, pin: string, userName: string) => {
    connectWorkoutWithPIN({
      variables: {
        workoutId,
        pin,
        userName,
      },
    })
  }

  return { error, connectWorkoutPinMutation }
}

export default useConnectWorkoutPinMutation
