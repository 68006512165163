import { gql } from '@apollo/client'

export const CONNECTION = gql`
  query connection {
    apiKey {
      tablet {
        bag {
          latestWifiStrength
          averageReceivedPackets
          latestRebootCount
        }
      }
    }
  }
`
