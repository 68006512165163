import React, { useId, useEffect, useState  } from 'react'
import { useCurrentCount, useCurrentExercise } from 'store/workout/selectors'
import { getEvaluatedIntensity, getHitsLastTenSeconds } from 'lib/intensity'
import SingleIntensity from './single'
import { useWeightQuery } from 'api/queries/weight'
import { useUserWeight } from 'store/auth/selectors'
import { getActiveWeight } from 'lib/weight'
import initApollo from 'api/client'
import { SERVER_TIME } from 'api/queries/server-time'

interface Props {
  isColumn?: boolean
}

const Intensity = ({ isColumn = false }: Props) => {
  const client = initApollo()
  const id = useId()
  const currentCount = useCurrentCount()
  const userWeight = useUserWeight()
  const { data } = useWeightQuery()
  const weight = getActiveWeight(data, userWeight)
  const { hits, seconds } = useCurrentExercise()
  let [serverTime, setServerTime] = useState<number | null>(null);

  const getUpdatedServerTime = async () => {
    try {
      const { data } = await client.query({
        query: SERVER_TIME,
        fetchPolicy: 'no-cache',
      });
      return data.getServerTime.serverTime;
    } catch (error) {
      console.error('Error fetching server time:', error);
      return null;
    }
  };

  useEffect(() => {
    const fetchServerTime = async () => {
      const time = await getUpdatedServerTime();
      setServerTime(time);
    };

    fetchServerTime();
    const intervalId = setInterval(fetchServerTime, 200);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  });

  const currentTime: number = serverTime !== null ? new Date(serverTime).getTime() : new Date().getTime();
  const exerciseSecond = seconds - currentCount + 1
  const hitsLastTenSeconds = getHitsLastTenSeconds(hits, currentTime)
  const evaluatedIntensity = getEvaluatedIntensity(hitsLastTenSeconds, weight, exerciseSecond)

  return (
    <div className={`intensity__wrapper ${isColumn ? 'intensity__wrapper--column' : ''}`}>
      <h3>Intensity</h3>
      <div className="intensity__row">
        {new Array(7).fill(undefined).map((_, i) => (
          <SingleIntensity key={id + i} currentIntensityValue={evaluatedIntensity} intensityNumber={i + 1} />
        ))}
      </div>
    </div>
  )
}

export default Intensity
