import React from 'react'
import { ResponsiveContainer, BarChart, XAxis, YAxis, Bar } from 'recharts'

import { getForceGraphData, getXaxisTicks, getAverageForcePercent, getRoundBarsData } from 'lib/graphs'
import { ForceGraphRoundProps } from 'interfaces/graph'
import CustomYAxis from './custom-y-axis'
import CustomXAxis from './custom-x-axis'
import { getRoundHits } from 'lib/stats'

const ForceGraphRound = ({
  roundData,
  maxForce,
  roundTime,
  roundComplete = false,
  graphWidth,
}: ForceGraphRoundProps) => {
  const currentRoundHits = getRoundHits(roundData)
  const avg = getAverageForcePercent(currentRoundHits)
  const barsArray = getRoundBarsData(roundData)
  const forceGraphData = getForceGraphData(barsArray)

  const firstValue = 0
  const lastValue = roundTime
  const { secondValue, thirdValue, fourthValue } = getXaxisTicks(firstValue, lastValue)

  const xAxisTicks = [firstValue, secondValue, thirdValue, fourthValue, lastValue]

  return (
    <>
      {roundComplete && (
        <>
          <CustomYAxis maxForce={maxForce} isForceGraph={true} roundComplete={roundComplete} avg={avg} />
          <CustomXAxis roundComplete={roundComplete} displayFirstTick={true} xAxisTicks={xAxisTicks} />
        </>
      )}
      <ResponsiveContainer width={!roundComplete ? graphWidth : '95%'} height="100%">
        <BarChart data={forceGraphData}>
          <XAxis tick={false} dataKey="index" type="number" domain={[0, forceGraphData.length]} />
          <YAxis domain={[0, maxForce]} tick={false} hide={true} />
          <Bar dataKey="force" fill="#00BFB9" radius={[10, 10, 0, 0]} maxBarSize={20} barSize={10} />
        </BarChart>
      </ResponsiveContainer>
    </>
  )
}

export default ForceGraphRound
