import React from 'react'

import Button from 'components/button'
import PopupWrapper from 'components/popup/wrapper/wrapper'
import { usePause, useSetPopup } from 'store/config/selectors'

const PausePopup = () => {
  const { closePopup } = useSetPopup()
  const { unpause } = usePause()

  const handleContinue = () => {
    unpause()
    closePopup()
  }

  return (
    <PopupWrapper customCloseEvent={handleContinue}>
      <div className="PopupPause">
        <h2>Pause workout</h2>
        <h3 className="light">You’ve paused the workout. Click on the button below whenever you wish to continue.</h3>
        <Button customClickEvent={handleContinue}>
          <h3>Continue</h3>
        </Button>
      </div>
    </PopupWrapper>
  )
}

export default PausePopup
