import React from 'react'

import { getYaxisTicks } from '../../lib/graphs'
import { CustomYAxisProps } from '../../interfaces/graph'
import AverageLine from './average-line'

const CustomYAxis: React.FC<CustomYAxisProps> = ({ maxForce, isForceGraph, roundComplete, avg }) => {
  const { firstTick, secondTick, thirdTick, fourthTick, fifthTick } = getYaxisTicks(maxForce)

  const ticks = isForceGraph ? [0, firstTick, secondTick, thirdTick, fourthTick, fifthTick] : [0, 1, 2, 3, 4, 5, 6, 7]

  const renderTicks = ticks.reverse().map((tick: number | null, index: number) => (
    <div className="roundsGraph__y-axis__tick" key={index}>
      <p className="light">{tick}</p>
      {(!isForceGraph || index !== 5) && (
        <hr
          className={`roundsGraph__y-axis__line ${roundComplete ? 'roundsGraph__y-axis__line--roundComplete' : ''}`}
        />
      )}
    </div>
  ))

  return (
    <div
      className={`roundsGraph__y-axis__wrapper ${roundComplete ? 'roundsGraph__y-axis__wrapper--roundComplete' : ''}`}
    >
      <AverageLine avg={avg} roundComplete={roundComplete} />
      {renderTicks}
    </div>
  )
}

export default CustomYAxis
