import { IconNames } from 'components/icons/icons'
import { ConnectionData, ConnectionIssueNames, ConnectionStates, ConnectionStatus } from 'interfaces/connection'

const getWifiStrengthGood = (latestWifiStrength: number | null) => {
  return latestWifiStrength !== null && latestWifiStrength >= -70 && latestWifiStrength <= 0
}

const getWifiStrengthOk = (latestWifiStrength: number | null) => {
  return latestWifiStrength !== null && latestWifiStrength >= -100 && latestWifiStrength <= 0
}

const getWifiStrengthBad = (latestWifiStrength: number | null) => {
  return latestWifiStrength !== null && latestWifiStrength < -70
}

const getAverageReceivedPacketsGood = (averageReceivedPackets: number | null) => {
  return averageReceivedPackets !== null && averageReceivedPackets >= 20
}

const getAverageReceivedPacketsOk = (averageReceivedPackets: number | null) => {
  return averageReceivedPackets !== null && averageReceivedPackets >= 0 && averageReceivedPackets <= 25
}

const getAverageReceivedPacketsBad = (averageReceivedPackets: number | null) => {
  return averageReceivedPackets !== null && averageReceivedPackets < 20
}

const getLatestRebootCountGood = (latestRebootCount: number | null) => {
  return latestRebootCount !== null && latestRebootCount >= 0 && latestRebootCount <= 2
}

const getLatestRebootCountBad = (latestRebootCount: number | null) => {
  return latestRebootCount !== null && latestRebootCount >= 3
}

export const getGoodStatus = (): ConnectionStatus => ({
  status: ConnectionStates.GOOD,
  issue: ConnectionIssueNames.NONE,
})

export const getConnectionColor = (connectionState?: ConnectionStates | null): 'red' | 'yellow' | 'green' => {
  const connectionColorMap: Record<ConnectionStates, 'red' | 'yellow' | 'green'> = {
    [ConnectionStates.NO_CONNECTION]: 'red',
    [ConnectionStates.BAD]: 'yellow',
    [ConnectionStates.GOOD]: 'green',
  }

  return connectionColorMap[connectionState ?? ConnectionStates.GOOD]
}

export const getConnectionIcon = (connectionState?: ConnectionStates) => {
  const connectionIconMap: Record<ConnectionStates, IconNames> = {
    [ConnectionStates.NO_CONNECTION]: IconNames.NO_CONNECTION,
    [ConnectionStates.BAD]: IconNames.BAD_CONNECTION,
    [ConnectionStates.GOOD]: IconNames.GOOD_CONNECTION,
  }

  return connectionIconMap[connectionState ?? ConnectionStates.GOOD]
}

export const getConnectionLabelText = (connectionState?: ConnectionStates): string => {
  const connectionLabelMap: Record<ConnectionStates, string> = {
    [ConnectionStates.NO_CONNECTION]: 'No connection',
    [ConnectionStates.BAD]: 'Connection warning',
    [ConnectionStates.GOOD]: 'Connection good',
  }

  return connectionLabelMap[connectionState ?? ConnectionStates.GOOD]
}

export const getCurrentConnectionIssue = (connectionState: ConnectionData): ConnectionStatus => {
  const { latestWifiStrength, averageReceivedPackets, latestRebootCount } = connectionState

  const wifiStrengthIsGood = getWifiStrengthGood(latestWifiStrength)
  const wifiStrengthIsOk = getWifiStrengthOk(latestWifiStrength)
  const wifiStrengthIsBad = getWifiStrengthBad(latestWifiStrength)
  const averageReceivedPacketsIsGood = getAverageReceivedPacketsGood(averageReceivedPackets)
  const averageReceivedPacketsIsOk = getAverageReceivedPacketsOk(averageReceivedPackets)
  const averageReceivedPacketsIsBad = getAverageReceivedPacketsBad(averageReceivedPackets)
  const latestRebootCountIsGood = getLatestRebootCountGood(latestRebootCount)
  const latestRebootCountIsBad = getLatestRebootCountBad(latestRebootCount)

  // Status 5: No connection (Red light)
  if (averageReceivedPackets === 0) {
    return {
      status: ConnectionStates.NO_CONNECTION,
      issue: ConnectionIssueNames.POWER, // Assuming POWER issue name is used for no connection scenarios
    }
  }

  // Status 1: Bag works well (Green light)
  if (wifiStrengthIsGood && averageReceivedPacketsIsGood && latestRebootCountIsGood) {
    return getGoodStatus()
  }

  // Status 2: Placement of the punching bag relative to router (Yellow light)
  if (wifiStrengthIsBad && averageReceivedPacketsIsGood && latestRebootCountIsGood) {
    return {
      status: ConnectionStates.BAD,
      issue: ConnectionIssueNames.WIFI,
    }
  }

  // Status 3: Internet quality is bad (Yellow light)
  if (wifiStrengthIsOk && averageReceivedPacketsIsBad && latestRebootCountIsGood) {
    return {
      status: ConnectionStates.BAD,
      issue: ConnectionIssueNames.PACKET,
    }
  }

  // Status 4: Bag is rebooting (Yellow light)
  if (wifiStrengthIsOk && averageReceivedPacketsIsOk && latestRebootCountIsBad) {
    return {
      status: ConnectionStates.BAD,
      issue: ConnectionIssueNames.REBOOT,
    }
  }

  // Default to good status if none of the above conditions are met
  return getGoodStatus()
}

export const checkIfConnected = (prevConnection: ConnectionData, currentConnection: ConnectionData) => {
  const prevIssue = getCurrentConnectionIssue(prevConnection)
  const currentIssue = getCurrentConnectionIssue(currentConnection)

  // if the previous issue was no connection and there is no current issue, we are connected
  return prevIssue?.status === ConnectionStates.NO_CONNECTION && !currentIssue
}
