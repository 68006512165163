import useWorkoutStore, { WorkoutStore } from 'store/workout'

export const useSavedWorkoutId = () => useWorkoutStore(({ savedWorkoutId }: WorkoutStore) => savedWorkoutId)

export const useWorkoutIsSaved = () => useWorkoutStore(({ workoutIsSaved }: WorkoutStore) => workoutIsSaved)

export const useCurrentCount = () => useWorkoutStore(({ currentCount }: WorkoutStore) => currentCount)

export const useWorkoutSession = () => useWorkoutStore(({ workoutSession }: WorkoutStore) => workoutSession)

export const useActiveRound = () => useWorkoutStore(({ activeRound }: WorkoutStore) => activeRound)

export const useActiveExercise = () => useWorkoutStore(({ activeExercise }: WorkoutStore) => activeExercise)

export const useCurrentRound = () => {
  const workoutSession = useWorkoutSession()
  const activeRound = useActiveRound()
  return workoutSession.rounds[activeRound]
}

export const useCurrentExercise = () => {
  const workoutSession = useWorkoutSession()
  const activeRound = useActiveRound()
  const activeExercise = useActiveExercise()
  return workoutSession.rounds[activeRound].exercises[activeExercise]
}

export const useWorkoutActions = () => useWorkoutStore(({ actions }: WorkoutStore) => actions)
