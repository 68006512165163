import React from 'react'

import { useMeQuery } from 'api/queries/me'
import useConnectWorkoutPinMutation from 'api/mutations/connect-workout-pin'
import useSaveLeaderboardRecord from 'lib/hooks/useSaveLeaderboardRecord'
import { useSavedWorkoutId } from 'store/workout/selectors'
import { useSetPopup } from 'store/config/selectors'
import PopupWrapper from 'components/popup/wrapper/wrapper'
import Button from 'components/button'

const SaveRusulsLoggedInPopup = () => {
  const savedWorkoutId = useSavedWorkoutId()
  const { closePopup } = useSetPopup()
  const { connectWorkoutPinMutation, error } = useConnectWorkoutPinMutation()
  const { data } = useMeQuery()
  const handleSaveLeaderboardRecord = useSaveLeaderboardRecord()

  const handleSaveWithLogin = async () => {
    if (!data || !savedWorkoutId) return
    const { userName, pin } = data.me

    connectWorkoutPinMutation(savedWorkoutId, pin, userName)
    handleSaveLeaderboardRecord()
  }

  return (
    <PopupWrapper>
      <h2>Save results</h2>
      <div className="PopupSaveResultsLoggedIn">
        <h3>Do you want to save your results?</h3>
        <div className="PopupSaveResultsLoggedIn__buttonBox">
          <Button customClickEvent={handleSaveWithLogin}>
            <h3>Yes</h3>
          </Button>
          <Button color="secondary" customClickEvent={closePopup}>
            <h3>No</h3>
          </Button>
        </div>
        <p>{error ? error : null}</p>
      </div>
    </PopupWrapper>
  )
}

export default SaveRusulsLoggedInPopup
