import {
  ConstraintName,
  INRWorkoutSession,
  INRWorkoutSessionChallenge,
  INRWorkoutSessionConstraint,
  INRWorkoutSessionExercise,
  INRWorkoutSessionRound,
} from 'interfaces/workout'
import { getAvgForce, getTotalForce } from './stats'
import { getEvaluatedExerciseIntensity } from './intensity'
import { UserWeight } from 'interfaces/user'
import { getAverage } from './utilities'


// These functions should be aligned with the personal app
// In the future, we might want to build a shared library for these functions, also when we expand to group app or additional apps

// In the data model, an exercise can have N amount of challenges. Design only allows for one challenge per exercise.
// On the workout complete screen, when challenges are completed, design only allows for about four challenge to be displayed at a time.
// "High score games", or "high score challenges" are technically just any challenges that don't have a min or max constraint. These constraints are filled in in the backoffice.
// The max of a constraint represents the maximum amount a user may reach within that challenge.
// The min of a constraint represents the minimum amount a user must reach within that challenge.
// The result of a challenge, compared against a constraint determines if the challenge was successful or not.
// In the data model a challenge can have N amount of constraints. Design only allows for one constraint per challenge.

export const findFirstExerciseWithChallengeInRound = (
  round: INRWorkoutSessionRound
): INRWorkoutSessionExercise | null =>
  round.exercises.find((exercise) => !!exercise.challenges && exercise.challenges.length) || null

export const checkIfExerciseHasChallenges = (exercise: INRWorkoutSessionExercise): boolean =>
  !!exercise.challenges && exercise.challenges.length ? true : false

export const checkIfRoundHasChallenges = (round: INRWorkoutSessionRound): boolean =>
  round.exercises.some(checkIfExerciseHasChallenges)

export const checkIfWorkoutHasChallenges = (workout: INRWorkoutSession): boolean =>
  workout.rounds.some(checkIfRoundHasChallenges)

export const getExerciseChallenge = (exercise: INRWorkoutSessionExercise) => exercise.challenges?.[0]! // an exercise only handles the first challenge in design

export const getChallengeConstriant = (challenge?: INRWorkoutSessionChallenge) => challenge?.constraints?.[0]! // a challenge only handles the first constraint in design

export const checkIsHighScoreChallenge = (challenge?: INRWorkoutSessionChallenge): boolean => {
  const constraint = getChallengeConstriant(challenge)
  if (!constraint) return false
  return !constraint.max && !constraint.min
}

const evaluateMinMax = (value: number, min: number | null, max: number | null) => {
  if (!!max && !!min) return value >= min && value <= max
  if (!!max && !min) return value <= max
  if (!max && !!min) return value >= min
  return false
}

export const getConstraintResult = (
  exercise: INRWorkoutSessionExercise,
  constraint: INRWorkoutSessionConstraint,
  weight: number
) => {
  switch (constraint?.type) {
    case ConstraintName.AvgForce:
      return getAvgForce(exercise.hits)
    case ConstraintName.Hits:
      return exercise.hits.length
    case ConstraintName.TotalForce:
      return getTotalForce(exercise.hits)
    case ConstraintName.AvgIntensity:
      return getAverage(getEvaluatedExerciseIntensity(exercise, weight))
    default:
      return 0
  }
}

export const getEvaluatedConstraint = (
  exercise: INRWorkoutSessionExercise,
  constraint: INRWorkoutSessionConstraint,
  weight: number = UserWeight.MEDIUM
) => {
  const result = getConstraintResult(exercise, constraint, weight)
  const isSuccess = evaluateMinMax(result, constraint.min, constraint.max)
  const isHighScore = !constraint.max && !constraint.min
  const showSuccess = isSuccess || isHighScore
  const roundedResult =
    constraint.type === ConstraintName.AvgIntensity ? Math.round(result * 10) / 10 : Math.round(result)
  const displayMin = constraint.min ? constraint.min : roundedResult
  const displayMax = constraint.max ? constraint.max : roundedResult

  return {
    ...constraint,
    isSuccess,
    isHighScore,
    showSuccess,
    displayMin,
    displayMax,
    result,
  }
}

export const getEvaluatedChallenge = (
  exercise: INRWorkoutSessionExercise,
  challenge: INRWorkoutSessionChallenge,
  weight: number = UserWeight.MEDIUM
) => {
  const constraints = challenge.constraints
  const constraintsResults = constraints.map((constraint) => getEvaluatedConstraint(exercise, constraint, weight))[0] // for now we only handle one constraint per challenge

  return {
    title: challenge.title,
    showSuccess: constraintsResults.showSuccess,
    isHighScoreChallenge: constraintsResults.isHighScore,
    result: constraintsResults.result,
    message: constraintsResults.showSuccess ? challenge.successMessage : challenge.failMessage,
    displayMax: constraintsResults.displayMax,
    displayMin: constraintsResults.displayMin,
  }
}
