import { useQuery, gql } from '@apollo/client'

const TABLET_BAGS = gql`
  query {
    apiKey {
      key
      tablet {
        reference
        bag {
          id
          reference
          name
        }
      }
    }
  }
`

export const useTabletsBagsQuery = () => useQuery(TABLET_BAGS)
