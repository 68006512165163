import { useQuery, gql } from '@apollo/client'
import { INRWorkoutTemplate } from 'interfaces/workout'

import { convertWorkoutToSession } from 'lib/workoutData'
import { useWorkoutActions } from 'store/workout/selectors'

const WORKOUT_TEMPLATE = gql`
  query ($id: String!) {
    workoutTemplate(input: { id: $id }) {
      name
      id
      workoutType
      videoUrl
      duration
      overviewImage {
        downloadLocation
      }
      variants {
        id
        difficultyLevel
        rounds {
          countdownSeconds
          breakSeconds
          videoUrl
          image {
            downloadLocation
          }
          exercises {
            breakSeconds
            seconds
            calories
            videoUrl
            image {
              downloadLocation
            }
            challenges {
              id
              title
              description
              successMessage
              failMessage
              leaderboard {
                id
                name
                createdAt
                updatedAt
                records {
                  id
                }
              }
              constraints {
                min
                max
                type
                showInUI
              }
            }
            moves {
              name
            }
          }
        }
      }
    }
  }
`

export const useWorkoutTemplateQuery = (id: string) => {
  const { updateWorkoutSession } = useWorkoutActions()

  return useQuery(WORKOUT_TEMPLATE, {
    variables: { id },
    onCompleted: ({ workoutTemplate }: { workoutTemplate: INRWorkoutTemplate[] }) => {
      updateWorkoutSession(convertWorkoutToSession(workoutTemplate[0]))
    },
    onError: (error) => {
      throw new Error(error.message)
    },
  })
}
