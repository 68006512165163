import { HowToFixBtn } from './fragments'
import SnackbarWrapper from './wrapper'

const ConnectionFailedSnackbar = () => {
  return (
    <SnackbarWrapper status="failed">
      <h5>Error...</h5>
      <h5 className="light">Failed to connect bag. Please check your set up</h5>
      <HowToFixBtn />
    </SnackbarWrapper>
  )
}

export default ConnectionFailedSnackbar
