export enum AppEnv {
  LOCAL = 'local',
  DEVELOP = 'develop',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export interface EnvConfig<T> {
  [AppEnv.LOCAL]: T
  [AppEnv.DEVELOP]: T
  [AppEnv.STAGING]: T
  [AppEnv.PRODUCTION]: T
}
