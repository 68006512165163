import React from 'react'

import PopupWrapper from 'components/popup/wrapper/wrapper'
import usePagination from 'lib/hooks/usePagination'
import Instructions from './instruction'
import Form from './form'

const SaveResultsPopup = () => {
  const { activeElement, handleNext, handlePrev } = usePagination()

  const elements = [<Instructions handleNext={handleNext} />, <Form handlePrev={handlePrev} />]

  return (
    <PopupWrapper>
      <h2 className="saveResultsPopup">Save results</h2>
      {elements[activeElement]}
    </PopupWrapper>
  )
}

export default SaveResultsPopup
