import React from 'react'

import { toFullTime } from 'lib/utilities'
import Button from 'components/button'
import Icon, { IconNames } from 'components/icons/icons'
import { useCurrentCount } from 'store/workout/selectors'
import { usePause, useSetPopup } from 'store/config/selectors'

interface HeaderActiveProps {
  exerciseText?: string
  roundText?: string
  value?: number
  text?: string
}

// TODO Rename props to be more descriptive. like roundName, exerciseTimerValue, etc.
const HeaderActive = ({ exerciseText = '', roundText = '', value, text }: HeaderActiveProps) => {
  const { setPausePopup, setQuitWorkoutPopup } = useSetPopup()
  const { pause } = usePause()

  const handlePausePopup = () => {
    pause()
    setPausePopup()
  }

  const handleQuitWorkoutPopup = () => {
    pause()
    setQuitWorkoutPopup()
  }

  return (
    <>
      <div className="row headerActive">
        <div className="col-4 headerActive__progress">
          <h5 className="light">{roundText}</h5>
          <h5 className="light">{exerciseText}</h5>
        </div>
        {/* // TODO Rename to ExerciseTimer */}
        <HeaderCounter text={text} value={value} />
        <div className="col-4 headerActive__tools">
          <Button fullColor icon square customClickEvent={handlePausePopup}>
            <Icon name={IconNames.PAUSE} size={24} />
          </Button>
          <Button fullColor color="secondary" customClickEvent={handleQuitWorkoutPopup}>
            <h3>Stop</h3>
          </Button>
        </div>
      </div>
    </>
  )
}

export default HeaderActive

// TODO reusability across workouts  & Rename to ExerciseTimer
function HeaderCounter({ value, text }: Omit<HeaderActiveProps, 'exerciseText' | 'roundText'>) {
  const currentCount = useCurrentCount()

  const counter = value ? toFullTime(value) : toFullTime(currentCount)

  return (
    <div className="col-4 headerActive__counterValue">
      {text && <h3>{text}</h3>}
      <h1 className="light">{counter}</h1>
    </div>
  )
}
