import { useConnectionState, useSetPopup } from 'store/config/selectors'

import Icon from 'components/icons/icons'
import { getConnectionIcon, getCurrentConnectionIssue } from 'lib/connection/connection'
import './connection-indicator.scss'

const round = (value: number | null) => Math.round(value ?? 0)

const ConnectionData = () => {
  const isProduction = process.env.REACT_APP_API_URL === 'api.nextroundboxing.com' // TODO handle using explicit env variable
  const { averageReceivedPackets, latestRebootCount, latestWifiStrength } = useConnectionState()

  return isProduction ? (
    <></>
  ) : (
    <div className="connectionIndicator__data">
      <p className="light">Packets: {round(averageReceivedPackets)}</p>
      <p className="light">Reboots: {round(latestRebootCount)}</p>
      <p className="light">Wifi: {round(latestWifiStrength)}</p>
    </div>
  )
}

const ConnectionIndicator = () => {
  const { setConnectionPopup } = useSetPopup()
  const connectionState = useConnectionState()
  const connectionIssue = getCurrentConnectionIssue(connectionState)
  const iconName = getConnectionIcon(connectionIssue?.status)
  const size = 36

  return (
    <div className="connectionIndicator">
      <ConnectionData />
      <Icon name={iconName} size={size} customClickEvent={setConnectionPopup} />
    </div>
  )
}

export default ConnectionIndicator
