import React, { ReactNode, useEffect, useState } from 'react'

import { useIsPaused } from 'store/config/selectors'
import { useCurrentCount } from 'store/workout/selectors'
import './counter.scss'

export enum CounterSizes {
  XSMALL = 'xs',
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
  XLARGE = 'xl',
}

interface CounterProps {
  currentCount: number
  initCount?: number
  size?: CounterSizes
  textEl?: ReactNode
  endCounter?: () => void
  isTimeInMin?: boolean
}

interface DynamicSize {
  height?: string
  secSize?: string
  fontSize?: string
  secTop?: string
  subTop?: string
  subFontSize?: string
  borderWidth?: string
}

export const Counter = ({ size, currentCount, initCount, endCounter, textEl, isTimeInMin = false }: CounterProps) => {
  const sizeObj: DynamicSize = {}
  switch (size) {
    case CounterSizes.XSMALL:
      sizeObj.borderWidth = '7px'
      sizeObj.height = '150px'
      sizeObj.fontSize = '70px'
      sizeObj.secSize = '30px'
      sizeObj.secTop = '40px'
      sizeObj.subFontSize = '60px'
      sizeObj.subTop = '100px'
      break
    case CounterSizes.SMALL:
      sizeObj.borderWidth = '20px'
      sizeObj.height = '320px'
      sizeObj.fontSize = '120px'
      sizeObj.secSize = '40px'
      sizeObj.secTop = '60px'
      sizeObj.subFontSize = '60px'
      sizeObj.subTop = '120px'
      break
    case CounterSizes.MEDIUM:
      sizeObj.borderWidth = '20px'
      sizeObj.height = '430px'
      sizeObj.fontSize = '160px'
      sizeObj.secSize = '60px'
      sizeObj.secTop = '120px'
      sizeObj.subFontSize = '60px'
      sizeObj.subTop = '220px'
      break
    case CounterSizes.LARGE:
      sizeObj.borderWidth = '30px'
      sizeObj.height = '500px'
      sizeObj.fontSize = '160px'
      sizeObj.secSize = '60px'
      sizeObj.secTop = '135px'
      sizeObj.subFontSize = '60px'
      sizeObj.subTop = '240px'
      break
    case CounterSizes.XLARGE:
      sizeObj.borderWidth = '30px'
      sizeObj.height = '560px'
      sizeObj.fontSize = '160px'
      sizeObj.secSize = '60px'
      sizeObj.secTop = '150px'
      sizeObj.subFontSize = '60px'
      sizeObj.subTop = '260px'
      break
  }

  const sizeStyle = {
    '--size': sizeObj.height,
    '--fontsize': sizeObj.fontSize,
    '--sectop': sizeObj.secTop,
    '--secsize': sizeObj.secSize,
    '--subtop': sizeObj.subTop,
    '--borderwidth': sizeObj.borderWidth,
  } as React.CSSProperties

  const isPaused = useIsPaused()
  const [ringStyle, setRingStyle] = useState({})
  const [msLeft, setMsLeft] = useState<number>(currentCount * 1000) // using ms instead of s will make the counter more consistent. When a lot of hits or other async functions are coming in, they seem to push the timer operation in the callnack queue, resulting in inconsistent seconds.

  const [initialRingOuter, setInitialRingOuter] = useState(0)
  const [initialRingInner, setInitialRingInner] = useState(0)

  useEffect(() => {
    if (initCount && currentCount < initCount) return

    setInitialRingOuter(360 / currentCount)
    setInitialRingInner(270 / currentCount)
    setMsLeft(currentCount * 1000)
  }, [currentCount, initCount])

  useEffect(() => {
    const refreshRate = 50

    const updateRingInterval = setInterval(() => {
      if (isPaused) return
      const outerValue = initialRingOuter * msLeft
      const innerValue = initialRingInner * (msLeft - 1000)

      if (outerValue / 1000 <= 0) return

      setMsLeft(msLeft - refreshRate)
      setRingStyle((prevRingStyle) => ({
        ...prevRingStyle,
        '--ringOuter': outerValue / 1000,
        '--ringInner': innerValue / 1000,
      }))
    }, refreshRate)
    return () => clearInterval(updateRingInterval)
  }, [initialRingInner, initialRingOuter, isPaused, msLeft])

  const currentValue = isTimeInMin ? Math.floor(currentCount / 60) : currentCount
  const timeUnit = isTimeInMin ? 'Min.' : 'Sec.'

  return (
    <div onClick={endCounter} className="counter" style={{ ...ringStyle, ...sizeStyle }}>
      <div id="ring" className="counter__outer"></div>
      <div className="counter__inner">
        <span id="seconds" className={`counter counter__sec`}>
          {currentValue}
          {<div className="counter__timeUnit">{timeUnit}</div>}
          {textEl}
        </span>
      </div>
    </div>
  )
}

const CurrentCounter = ({
  size = CounterSizes.SMALL,
  endCounter,
  initCount,
  textEl,
}: Omit<CounterProps, 'currentCount'>) => {
  const currentCount = useCurrentCount()

  return (
    <Counter initCount={initCount} endCounter={endCounter} currentCount={currentCount} size={size} textEl={textEl} />
  )
}

export default CurrentCounter
