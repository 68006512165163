import { useNavigate } from 'react-router-dom'
import React from 'react'

import PopupWrapper from 'components/popup/wrapper/wrapper'
import LoggedIn from './logged-in'
import { pathsObj } from 'lib/constants/paths'
import { useIsloggedIn } from 'store/auth/selectors'
import NotLoggedIn from './not-logged-in'
import { useSetPopup } from 'store/config/selectors'

const FreestyleSetup = () => {
  const { closePopup } = useSetPopup()
  const navigate = useNavigate()
  const isLoggedIn = useIsloggedIn()

  const startFreestyle = () => {
    closePopup()
    navigate(pathsObj.rounds.roundOverview)
  }

  return (
    <PopupWrapper>
      {isLoggedIn ? <LoggedIn startFreestyle={startFreestyle} /> : <NotLoggedIn startFreestyle={startFreestyle} />}
    </PopupWrapper>
  )
}

export default FreestyleSetup
