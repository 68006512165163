import { useEffect } from 'react'

import { WorkoutName } from 'interfaces/workout'
import { useCreateRoundMutation } from 'api/mutations/create-workout-round'
import { convertRoundToBackend } from '../workoutData'
import { useCurrentRound, useWorkoutSession } from 'store/workout/selectors'

const useRoundComplete = () => {
  const workoutSession = useWorkoutSession()
  const currentRound = useCurrentRound()

  const { roundId, createRoundMutation, isLoading } = useCreateRoundMutation()
  const isQuickstart = workoutSession.name === WorkoutName.QUICKSTART

  useEffect(() => {
    if (!!isQuickstart || !!roundId) return
    const backendSessions = convertRoundToBackend(currentRound)
    if (!isLoading) {
      createRoundMutation(backendSessions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, roundId, currentRound])

  return {
    roundId,
    isLoading
  }
}

export default useRoundComplete
