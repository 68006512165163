import React from 'react'

import Button from 'components/button'
import { useSetPopup } from 'store/config/selectors'
import { useIsloggedIn } from 'store/auth/selectors'
import { useSavedWorkoutId, useWorkoutIsSaved } from 'store/workout/selectors'
import './save-results.scss'

const SaveResults = () => {
  const isLoggedIn = useIsloggedIn()
  const savedWorkoutId = useSavedWorkoutId()
  const workoutIsSaved = useWorkoutIsSaved()
  const { setSaveResultsPopup, setSaveResultsLoggedInPopup } = useSetPopup()

  const handlePopup = () => {
    if (!isLoggedIn && !!savedWorkoutId) {
      setSaveResultsPopup()
    } else if (!!isLoggedIn) {
      setSaveResultsLoggedInPopup()
    }
  }

  return (
    <div className="saveResults">
      {workoutIsSaved ? (
        <Button disabled color="tertiary">
          <h4>Results are saved</h4>
        </Button>
      ) : (
        <Button customClickEvent={handlePopup} color="tertiary" fullColor>
          <h4>Save your results</h4>
        </Button>
      )}
    </div>
  )
}

export default SaveResults
