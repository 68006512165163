import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { gql, useSubscription } from '@apollo/client'

import { INRWorkoutSessionHit } from 'interfaces/workout'
import { pathsObj } from 'lib/constants/paths'
import { getInitQuickstartSession } from 'lib/initial'
import { useWorkoutActions } from 'store/workout/selectors'
import useUpdateExerciseStarted from './useUpdateExerciseStarted'

const HIT = gql`
  subscription quickstartHit {
    hit {
      force
    }
  }
`

const useStartQuickstart = (autoStart = true) => {
  const navigate = useNavigate()
  const { data } = useSubscription<{ hit: INRWorkoutSessionHit }>(HIT)
  const { updateActiveRound, updateWorkoutSession } = useWorkoutActions()
  const updateExerciseStarted = useUpdateExerciseStarted()

  const startQuickstart = useCallback(() => {
    updateWorkoutSession(getInitQuickstartSession())
    updateActiveRound(0)
    updateExerciseStarted()
    navigate(pathsObj.freestyle.freestyleActive)
  }, [navigate, updateWorkoutSession, updateExerciseStarted, updateActiveRound])

  useEffect(() => {
    if (autoStart && data && data.hit) {
      startQuickstart()
    }
  }, [autoStart, data, startQuickstart])

  return { startQuickstart, data }
}

export default useStartQuickstart
