export enum TOKENNAME {
  userToken = 'NEXTROUND_USER_TOKEN',
  rememberMe = 'NEXTROUND_REMEMBER_ME',
  api = 'NEXTROUND_API',
  workoutSession = 'NEXTROUND_WORKOUT_SESSION',
  workoutRounds = 'NEXTROUND_WORKOUT_ROUNDS',
}

interface HandleStorageOutput {
  stored: string
  setStored: (payload: string) => void
  removeStored: () => void
}

const handleStorage: (tokenName: TOKENNAME) => HandleStorageOutput = (tokenName) => {
  const stored = localStorage.getItem(tokenName) || ''

  const setStored = (payload: string) => localStorage.setItem(tokenName, payload)

  const removeStored = () => localStorage.removeItem(tokenName)

  return { stored, setStored, removeStored }
}

export default handleStorage
