import { useQuery, gql } from '@apollo/client'

//TODO cleanup, many of these are not needed
const WORKOUT_CATEGORIES = gql`
  {
    workoutTemplateCategory {
      id
      name
      rank
      templates {
        id
        name
        description
        workoutType
        duration
        rank
        image {
          downloadLocation
        }
        variants {
          difficultyLevel
          id
          rounds {
            countdownSeconds
            breakSeconds
            videoUrl
            exercises {
              videoUrl
              seconds
              breakSeconds
            }
          }
        }
      }
    }
  }
`

export const useWorkoutCategoriesQuery = () => useQuery(WORKOUT_CATEGORIES)
