import { useQuery } from '@apollo/client'

import { CONNECTION } from 'api/queries/connection'
import { useConfigActions } from 'store/config/selectors'

// This hook is used to fetch the connection data from the server and update the connection state in the store
// It is called every 3 seconds to keep the connection state updated. The results are rendered out in the ConnectionIndicator component in the header, which has a popup behind it that assists the user in troubleshooting their connection
const useConnection = () => {
  const { setConnectionState } = useConfigActions()

  useQuery(CONNECTION, {
    fetchPolicy: 'no-cache',
    pollInterval: 3000,
    onError: (error) => {
      throw new Error(error?.message ?? 'Error fetching connection data')
    },
    onCompleted: (data) => {
      if (data) {
        const connectionData = data?.apiKey?.[0]?.tablet?.[0]?.bag?.[0]

        const latestWifiStrength = connectionData?.latestWifiStrength ?? null
        const averageReceivedPackets = connectionData?.averageReceivedPackets ?? null
        const latestRebootCount = connectionData?.latestRebootCount ?? null

        setConnectionState({
          latestWifiStrength,
          averageReceivedPackets,
          latestRebootCount,
        })
      }
    },
  })
}

export default useConnection
