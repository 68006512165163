import './cta.scss'

const Cta = () => (
  <div className="cta">
    <h5 className="light">
      Curious about your performance?
      <br />
      <br />
      Check out our Club App and track your progress over time.
    </h5>
  </div>
)

export default Cta
