import { gql, useSubscription } from '@apollo/client'
import { INRWorkoutSessionHit } from 'interfaces/workout'
import { useEffect, useState } from 'react'

const HIT = gql`
  subscription eventHit {
    hit {
      force
    }
  }
`
const useHitToTriggerEvent = ({ cb }: { cb: () => void }) => {
  const [eventEnabled, setEventEnabled] = useState(false)
  const { data } = useSubscription<{ hit: INRWorkoutSessionHit }>(HIT, {
    skip: !eventEnabled,
  })

  const enableEvent = () => setEventEnabled(true)

  useEffect(() => {
    if (data && data.hit) cb()
  }, [data, cb])

  return { enableEvent, eventEnabled }
}

export default useHitToTriggerEvent
