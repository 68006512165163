import { useState, useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'

import handleStorage, { TOKENNAME } from 'lib/handleStorage'
import { useTabletsBagsQuery } from 'api/queries/tablets-bags'

const PUNCH = gql`
  mutation punch($bagId: String!) {
    punch(bagId: $bagId) {
      angle
    }
  }
`

const { stored: apiKey } = handleStorage(TOKENNAME.api)

const filterDataByKey = (data: any) => {
  return data.apiKey.find((item: any) => item.key === JSON.parse(apiKey))
}

const useDoHit = () => {
  const [bagId, setBagId] = useState<string>('')
  const { data } = useTabletsBagsQuery()

  const [doHit] = useMutation(PUNCH)

  const executeHit = () => {
    if (!bagId) return
    return doHit({ variables: { bagId } })
  }

  useEffect(() => {
    if (data && data.apiKey) {
      const filteredBagId = filterDataByKey(data)?.tablet[0]?.bag[0]?.id || ''
      setBagId(filteredBagId)
    }
  }, [data])

  return executeHit
}

export default useDoHit
