import React, { BaseSyntheticEvent, useState } from 'react'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useSignupWithWorkoutMutation from 'api/mutations/signup-with-workout'
import useSignupMutation from 'api/mutations/signup'
import Button from 'components/button'
import PopupWrapper from 'components/popup/wrapper/wrapper'
import { useSavedWorkoutId } from 'store/workout/selectors'
import { useSetPopup } from 'store/config/selectors'
import Input from 'components/input/input'

const RegisterButton = ({ email }: { email: string }) => {
  const savedWorkoutId = useSavedWorkoutId()
  const { signupMutation, error } = useSignupMutation()
  const { signupWithWorkoutMutation, error: withWorkoutError } = useSignupWithWorkoutMutation()

  const handleSignup = () => (savedWorkoutId ? signupWithWorkoutMutation(savedWorkoutId, email) : signupMutation(email))

  return (
    <>
      <p>{error ? error : withWorkoutError ? withWorkoutError : null}</p>
      <Button fullColor customClickEvent={handleSignup}>
        <h3>Save</h3>
      </Button>
    </>
  )
}

const RegisterPopup = () => {
  const savedWorkoutId = useSavedWorkoutId()
  const { setSaveResultsPopup, setLoginPopup } = useSetPopup()
  const [email, setEmail] = useState<string>('')

  const updateEmail = (evt: BaseSyntheticEvent) => {
    evt.preventDefault()
    setEmail(evt.target.value)
  }

  const handleBack = () => (savedWorkoutId ? setSaveResultsPopup() : setLoginPopup())

  return (
    <PopupWrapper>
      <div className="popupRegister">
        <h2>Start here!</h2>
        <h5 className="light">Fill in your e-mail to make an account</h5>
        <Input name="email" onChange={updateEmail} type="email" placeholder="E-mail adress" value={email} />
        <div className="popupRegister__btnsWrapper">
          <Button customClickEvent={handleBack}>
            <h3>
              <FontAwesomeIcon icon={faChevronLeft} />
              Go back
            </h3>
          </Button>
          <RegisterButton email={email} />
        </div>
      </div>
    </PopupWrapper>
  )
}

export default RegisterPopup
