import { useEffect } from 'react'

import { useCurrentCount, useWorkoutActions } from 'store/workout/selectors'
import { useIsPaused } from 'store/config/selectors'

// useCountdown handles the core logic of the countdown timer. The <Counter /> most often renders this value, but may also render other values when needed

interface Props {
  initCount: number
  cb: () => void
}

const useCountdown = ({ initCount, cb }: Props) => {
  const { updateCurrentCount, decrementCurrentCount } = useWorkoutActions()
  const currentCount = useCurrentCount()
  const isPaused = useIsPaused()

  const heartbeatInterval = 1000

  // initiate count value
  useEffect(() => {
    updateCurrentCount(initCount)
  }, [updateCurrentCount, initCount])

  useEffect(() => {
    // prevent execution if the timer is paused
    if (isPaused) return

    const beat = () => {
      if (currentCount > 1) {
        decrementCurrentCount()
      } else if (currentCount === 1) {
        cb()
      }
    }

    const nextBeat = new Date().getTime() + heartbeatInterval
    const nextTimeoutId = setInterval(beat, nextBeat - new Date().getTime())

    return () => clearInterval(nextTimeoutId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCount, isPaused])
}

export default useCountdown
