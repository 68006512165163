import { BaseSyntheticEvent, useState } from 'react'

import PopupWrapper from 'components/popup/wrapper/wrapper'
import Input from 'components/input/input'
import Button from 'components/button'
import useForgotPasswordMutation from 'api/mutations/forgot-password'

const ForgotPasswordPopup = () => {
  const { forgotPasswordMutation } = useForgotPasswordMutation()
  const [email, setEmail] = useState('')

  const updateEmail = (evt: BaseSyntheticEvent) => {
    evt.preventDefault()
    const { value } = evt.target
    setEmail(value)
  }

  const handleSignup = () => forgotPasswordMutation(email)

  return (
    <PopupWrapper>
      <div className="popupForgotPw">
        <h2>Forgot your password?</h2>
        <h4 className="light">No problem! Enter your e-mail below and set up a new one.</h4>
        <Input type="email" name="email" placeholder="E-mail adress" value={email} onChange={updateEmail} />
        <Button fullColor customClickEvent={handleSignup}>
          <h3>Reset password</h3>
        </Button>
      </div>
    </PopupWrapper>
  )
}

export default ForgotPasswordPopup
