import React from 'react'

import { useWeightQuery } from 'api/queries/weight'
import { useCurrentRound } from 'store/workout/selectors'
import { getEvaluatedChallenge, findFirstExerciseWithChallengeInRound, getExerciseChallenge } from 'lib/challenges'
import Icon, { IconNames } from 'components/icons/icons'
import './challenge-feedback.scss'

const ChallengeFeedback = () => {
  const currentRound = useCurrentRound()
  const firstExercise = findFirstExerciseWithChallengeInRound(currentRound)
  const { data } = useWeightQuery()

  if (!firstExercise) return <></>

  const challenge = getExerciseChallenge(firstExercise)

  const { showSuccess, message, displayMin, displayMax, isHighScoreChallenge } = getEvaluatedChallenge(
    firstExercise,
    challenge,
    data?.me?.weightKg
  )

  const challengeIcon = showSuccess ? IconNames.CHALLENGE_GLOVES_GREEN : IconNames.CHALLENGE_GLOVES_RED
  const punchIcon = showSuccess ? IconNames.GLOVE : IconNames.GLOVE_RED
  const msgClassName = `challenge-feedback__message${showSuccess ? '--success' : '--failed'}`
  const goalClassName = `challenge-feedback__goal--${showSuccess ? 'primary' : 'secondary'}`

  return (
    <div className="challenge-feedback">
      <div className="challenge-feedback__title">
        <Icon name={challengeIcon} size={90} />
        <h3>{firstExercise?.challenges![0].title}</h3>
      </div>
      <div className={msgClassName}>{message}</div>
      <h3 className="light">{firstExercise?.challenges![0].description}</h3>
      <div className="challenge-feedback__goal">
        <Icon name={punchIcon} size={70} />
        {!isHighScoreChallenge && (
          <>
            <h1 className={goalClassName}>{displayMax}</h1>
            <h1 className="challenge-feedback__goal--primary">/</h1>
          </>
        )}
        <h1>{displayMin}</h1>
      </div>
    </div>
  )
}

export default ChallengeFeedback
