import { useSetPopup } from 'store/config/selectors'

export const HowToFixBtn = () => {
  const { setConnectionPopup } = useSetPopup()
  const handleClick = () => setConnectionPopup()

  return (
    <button className="snackbarBtn" onClick={handleClick}>
      How to fix it
    </button>
  )
}
