import React from 'react'

import CurrentCounter, { CounterSizes } from 'components/counter/counter'
import { useActiveRound, useWorkoutSession } from 'store/workout/selectors'
import { useNextStep } from 'lib/hooks/useNextStep'
import { LetsGoText } from 'components/counter/fragments'

interface Props {
  initCount: number
}

export const RoundCompleteFooter = ({ initCount }: Props) => {
  const { rounds } = useWorkoutSession()
  const activeRound = useActiveRound()
  const nextStep = useNextStep()
  const counterText = activeRound < rounds.length - 1 ? <LetsGoText /> : null

  return (
    <div className="RoundComplete__footer">
      <div className="RoundComplete__footer__upcoming">
        {activeRound + 1 < rounds.length && (
          <h3>
            <span>Upcoming</span> Round {activeRound + 2}
          </h3>
        )}
        {activeRound + 1 === rounds.length && (
          <h3>
            <span>Upcoming</span> Workout Overview
          </h3>
        )}
      </div>
      <div className="RoundComplete__footer__countdown--count">
        <CurrentCounter size={CounterSizes.XSMALL} endCounter={nextStep} textEl={counterText} initCount={initCount} />
      </div>
    </div>
  )
}
