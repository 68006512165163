import React, { useState } from 'react'

import defaultImg from 'assets/images/quickstart.jpg'
import { getWorkoutDuration } from 'lib/stats'
import { useNextStep } from 'lib/hooks/useNextStep'
import useSkipStep from 'lib/hooks/useSkipStep'
import {
  useActiveExercise,
  useActiveRound,
  useCurrentExercise,
  useCurrentRound,
  useWorkoutSession,
} from 'store/workout/selectors'
import Main from 'layout/main'
import CurrentCounter, { CounterSizes } from 'components/counter/counter'
import HeaderActive from 'components/header/active'
import Header from 'components/header/header'
import { ChallengeCard } from 'components/challenge/challenge-card'
import NRMedia from 'components/nr-media/nr-media'
import useCountdown from 'lib/hooks/useCountdown'
import { useTrippleBellSound } from 'lib/hooks/useSounds'

interface RoundTitleProps {
  title?: string
  subTitle?: string
}

const RoundTitle = ({ title = '', subTitle = '' }: RoundTitleProps) => {
  return (
    <div className="roundTitle">
      <h3 className="light">
        <span>{title}</span>
        <span>{subTitle}</span>
      </h3>
    </div>
  )
}

// Exercise countdown, between exercises
// This is only used for custom workouts, as quickstart has 1 round and 1 exercise, and freestyle has N rounds with each 1 exercise
const RoundsExerciseCountdown = () => {
  const playSound = useTrippleBellSound()
  const workoutSession = useWorkoutSession()
  const activeExercise = useActiveExercise()
  const activeRound = useActiveRound()
  const currentExercise = useCurrentExercise()
  const currentRound = useCurrentRound()
  const nextStep = useNextStep()
  const hasChallenge = !!currentExercise.challenges?.length
  const hasVideo = !!currentExercise.videoUrl
  const hasImage = !!currentExercise.image?.downloadLocation

  const handleNextStep = () => {
    playSound()
    nextStep()
  }

  useSkipStep({ imgDownload: currentExercise.image?.downloadLocation, videoUrl: currentExercise.videoUrl })
  useCountdown({ initCount: currentRound.countdownSeconds, cb: handleNextStep })

  const [showCounter, setShowCounter] = useState<boolean>(!hasVideo)

  const timeLeft = getWorkoutDuration({ ...workoutSession, rounds: workoutSession.rounds.slice(activeRound - 1) })

  const renderOverview =
    hasVideo || hasImage ? (
      <NRMedia
        isVideoAutoPlay
        className="round_exercise_video"
        imageLink={currentExercise.image?.downloadLocation}
        videoUrl={currentExercise.videoUrl}
        onVideoEnd={() => setShowCounter(true)}
        onVideoError={() => setShowCounter(true)}
      />
    ) : hasChallenge ? (
      <ChallengeCard />
    ) : (
      <img className="round_exercise_video" src={defaultImg} alt="media" />
    )

  return (
    <Main className="nextround">
      <div className="round-complete-overview">
        <Header title={<h1>{`Round ${activeRound + 1}`}</h1>} />
        <HeaderActive value={timeLeft} />
        <div className="round-complete-overview__content__wrapper">
          <RoundTitle
            title={`Round ${activeRound + 1}`}
            subTitle={`What we will do in exercise ${activeExercise + 1}`}
          />
          <div className="round-complete-overview__content">
            {renderOverview}
            <div className="round_exercise_counter">
              {!!showCounter && (
                <CurrentCounter
                  initCount={currentRound.countdownSeconds}
                  endCounter={nextStep}
                  size={
                    window.innerWidth <= 960
                      ? CounterSizes.XSMALL
                      : window.innerWidth <= 1024 && hasVideo
                      ? CounterSizes.XSMALL
                      : CounterSizes.SMALL
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Main>
  )
}

export default RoundsExerciseCountdown
