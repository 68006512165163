//TODO: cleanup & seperation of concerns

import {
  INRWorkoutSession,
  INRWorkoutSessionExercise,
  INRWorkoutSessionHit,
  INRWorkoutSessionTime,
  INRWorkoutTemplate,
} from 'interfaces/workout'

// These functions should be aligned with the personal app
// In the future, we might want to build a shared library for these functions, also when we expand to group app or additional apps

export const calcPercentage = ({ partialValue, totalValue }: { partialValue: number; totalValue: number }) =>
  (100 * partialValue) / totalValue

export const convertHitsToTimedHits = (hits: INRWorkoutSessionHit[], started: Date | null): INRWorkoutSessionTime => {
  const timedHits: INRWorkoutSessionTime = {}

  hits.forEach((hit) => {
    const hitTimeInSeconds = Math.floor(calculateHitTimeInSeconds(hit.punchedAt, started)) // Ensure integer key

    if (!timedHits[hitTimeInSeconds]) {
      timedHits[hitTimeInSeconds] = []
    }

    timedHits[hitTimeInSeconds].push({ ...hit })
  })

  return timedHits
}

// Helper function to calculate hit time in seconds relative to the started time
function calculateHitTimeInSeconds(punchedAt: string, started: Date | null): number {
  // Assuming punchedAt is a string representing a timestamp in milliseconds
  const hitTimeInMilliseconds = parseInt(punchedAt)

  // If started time is provided, calculate offset
  if (started) {
    const startTimeInMilliseconds = started.getTime()
    return (hitTimeInMilliseconds - startTimeInMilliseconds) / 1000 // Convert to seconds
  } else {
    // If started time is not available, use raw timestamp as seconds
    return hitTimeInMilliseconds / 1000
  }
}

export const getExercisesFromWorkout = (workout: INRWorkoutSession): INRWorkoutSessionExercise[] =>
  workout.rounds.flatMap(({ exercises }) => exercises)

export const getWorkoutTemplateDuration = (workoutTemplate: INRWorkoutTemplate): string => {
  const duration = !!workoutTemplate.duration
    ? workoutTemplate.duration
    : getSummedUpWorkoutTemplateDuration(workoutTemplate)
  return toRoundedFullTime(duration)
}

export const getSummedUpWorkoutTemplateDuration = (workoutTemplate: INRWorkoutTemplate): number =>
  sumUp(workoutTemplate.variants[0].rounds.flatMap(({ exercises }) => exercises.flatMap(({ seconds }) => seconds)))

export const sumUp = (array: number[]): number => array.reduce((acc, cur) => acc + cur, 0)

export const getAverage = (array: number[]): number => array.reduce((a, b) => a + b, 0) / array.length

export const getMax2Decimal = (num: number) => Math.round((num + Number.EPSILON) * 100) / 100

// what even is this
export const getPercentage = (num: number) => (num / 1) * 100

export const toFullTime = (timeInSeconds: number): string => {
  const hours = String(Math.floor(timeInSeconds / 3600)).padStart(2, '0')
  const minutes = String(Math.floor((timeInSeconds % 3600) / 60)).padStart(2, '0')
  const seconds = String(timeInSeconds % 60).padStart(2, '0')
  return `${hours}:${minutes}:${seconds}`
}

export const toRoundedFullTime = (timeInSeconds: number): string => {
  const roundedTimeInSeconds = Math.round(timeInSeconds / 30) * 30
  return toFullTime(roundedTimeInSeconds)
}

export const addCommas = (nStr: any) => {
  nStr += ''
  let x = nStr.split('.')
  let x1 = x[0]
  let x2 = x.length > 1 ? '.' + x[1] : ''
  var rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    // eslint-disable-next-line no-useless-concat
    x1 = x1.replace(rgx, '$1' + '.' + '$2')
  }
  return x1 + x2
}

export const toClasses = (classList: { [key: string]: unknown }): string =>
  Object.entries(classList)
    .filter(([key, value]) => value)
    .map(([key, value]) => key)
    .join(' ')
