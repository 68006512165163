import React from 'react'

import Button from 'components/button'
import useDoHit from 'api/mutations/punch'
import './hit-me-btn.scss'

// This button is used for testing purposes only
// It simulates a punch on a boxing bag
// The mutation triggers a hit subscription with random values on the backend, which is then sent to the frontend (assuming the subscription is being listened to)
const HitMeBtn = () => {
  const doHit = useDoHit()

  const isProduction = process.env.REACT_APP_API_URL === 'api.nextroundboxing.com' // TODO handle this with explicit env vars

  return isProduction ? (
    <></>
  ) : (
    <div className="hitMeBtn">
      <Button customClickEvent={doHit}>
        <h3>Hit me!</h3>
      </Button>
    </div>
  )
}

export default HitMeBtn
