import {
  INRExercise,
  INRRound,
  INRWorkoutSession,
  INRWorkoutSessionExercise,
  INRWorkoutSessionRound,
  INRWorkoutTemplate,
  DifficultyLevel,
  INRWorkoutCategory,
} from 'interfaces/workout'
import handleStorage, { TOKENNAME } from './handleStorage'

// Some values are required for the backend, but not for the frontend
// These mappers are used to convert the frontend data to the backend data and vice versa
export const convertExerciseToBackend = (workoutExercise: INRWorkoutSessionExercise): INRExercise => {
  const { breakSeconds, calories = 0, seconds, hits, started } = workoutExercise

  return {
    breakSeconds,
    hits,
    calories,
    seconds,
    started,
  }
}

export const convertRoundToBackend = (workoutRound: INRWorkoutSessionRound): INRRound => {
  const { breakSeconds, countdownSeconds } = workoutRound
  const exercises: INRExercise[] = workoutRound.exercises.map(convertExerciseToBackend)

  return { breakSeconds, countdownSeconds, exercises }
}

export const convertSessionToBackend = (workoutSession: INRWorkoutSession) => {
  const { stored } = handleStorage(TOKENNAME.workoutRounds)
  const rounds = ((stored && JSON.parse(stored)) || []).map((id: string) => ({ id }))

  const name = workoutSession.name
  const workoutType = workoutSession.workoutType
  const difficultyLevel = workoutSession.level
  const templateId = workoutSession.id

  return { name, workoutType, difficultyLevel, rounds, templateId }
}

const convertExerciseToSession = (exercise: INRExercise): INRWorkoutSessionExercise => {
  const { breakSeconds, seconds, challenges = null, image, videoUrl, started } = exercise
  return {
    breakSeconds,
    challenges,
    seconds,
    image,
    hits: [],
    videoUrl,
    started,
  }
}

export const convertWorkoutToSession = (workoutTemplate: INRWorkoutTemplate): INRWorkoutSession => {
  const { id, name, videoUrl, overviewImage, variants, workoutType } = workoutTemplate

  return {
    id,
    name,
    videoUrl,
    overviewImage,
    workoutType,
    level: DifficultyLevel.PRO,
    rounds: variants[0].rounds.map((round) => ({
      ...round,
      exercises: round.exercises.map(convertExerciseToSession),
    })),
  }
}

export const roundIdToLocalStorage = (roundId: string) => {
  const { stored, setStored } = handleStorage(TOKENNAME.workoutRounds)

  if (roundId) {
    if (stored && JSON.parse(stored)) {
      const oldArray = JSON.parse(stored)

      if (oldArray.includes(roundId)) return
      const roundIdArray = JSON.stringify([...oldArray, roundId])
      setStored(roundIdArray)
    } else {
      const roundIdArray = JSON.stringify([roundId])
      setStored(roundIdArray)
    }
  }
}

export const orderWorkoutCategories = (workoutCategories: INRWorkoutCategory[]): INRWorkoutCategory[] => {
  return [...workoutCategories]
    .sort((a, b) => {
      if (a.rank === null && b.rank === null) return 0
      if (a.rank === null) return 1
      if (b.rank === null) return -1

      return a.rank - b.rank
    })
    .map((category) => ({
      ...category,
      templates: orderWorkoutTemplates(category.templates),
    }))
}

const orderWorkoutTemplates = (workoutTemplates: INRWorkoutTemplate[]): INRWorkoutTemplate[] => {
  return [...workoutTemplates].sort((a, b) => {
    if (a.rank === null && b.rank === null) return 0
    if (a.rank === null) return 1
    if (b.rank === null) return -1

    return a.rank - b.rank
  })
}
