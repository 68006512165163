import { Me, UserWeight, UserWeightLabel } from 'interfaces/user'

// These functions should be aligned with the personal app
// In the future, we might want to build a shared library for these functions, also when we expand to group app or additional apps

export const classifyUserWeight = (weight: number): UserWeightLabel => {
  if (weight < 65) return UserWeightLabel.LIGHT
  if (weight >= 65 && weight < 90) return UserWeightLabel.MEDIUM
  return UserWeightLabel.HEAVY
}

// compare the loggedIn user's weight with the weight from the global store
export const getActiveWeight = (user: Me | undefined, userWeight: UserWeight): number => {
  if (!user) return userWeight
  return user?.me.weightKg ? user?.me.weightKg : userWeight
}
