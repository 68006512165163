import React from 'react'

import { getWorkoutStats } from 'lib/stats'
import { addCommas } from 'lib/utilities'
import { useWorkoutSession } from 'store/workout/selectors'

const StatsWorkoutComplete = () => {
  const workoutSession = useWorkoutSession()
  const { avgForce, maxForce, minForce, avgSpeed, maxSpeed, minSpeed } = getWorkoutStats(workoutSession)

  return (
    <div className="row statsWorkoutComplete">
      <div className="col-6 statsWorkoutComplete__box">
        <h3 className="gray light avg">Avg. Force</h3>
        <h3 className="value statsAvg">{addCommas(avgForce)}</h3>
        <h5 className="gray light low">Low</h5>
        <h5 className="value statsLow">{addCommas(minForce)}</h5>
        <h5 className="gray light high">High</h5>
        <h5 className="value statsHigh">{addCommas(maxForce)}</h5>
      </div>
      <div className="col-6 statsWorkoutComplete__box">
        <h3 className="gray light avg">Avg. Speed</h3>
        <h3 className="value statsAvg">{avgSpeed}</h3>
        <h5 className="gray light low">Low</h5>
        <h5 className="value statsLow">{minSpeed}</h5>
        <h5 className="gray light high">High</h5>
        <h5 className="value statsHigh">{maxSpeed}</h5>
      </div>
    </div>
  )
}

export default StatsWorkoutComplete
