import {
  INRWorkoutSessionHit,
  INRWorkoutSession,
  INRWorkoutSessionRound,
  INRWorkoutSessionExercise,
} from 'interfaces/workout'
import { sumUp } from './utilities'
import { INRStats } from 'interfaces/stats'

// These functions should be aligned with the personal app
// In the future, we might want to build a shared library for these functions, also when we expand to group app or additional apps

export const getTotalForce = (hits: INRWorkoutSessionHit[]): number => {
  return hits.map((hit) => hit.force).reduce((a, b) => a + b, 0)
}

export const getMaxForce = (hits: INRWorkoutSessionHit[]): number => {
  if (!hits.length) return 0
  return Math.max(...hits.map((hit) => hit.force))
}

export const getMinForce = (hits: INRWorkoutSessionHit[]): number => {
  if (!hits.length) return 0
  return Math.min(...hits.map((hit) => hit.force))
}

export const getAvgForce = (hits: INRWorkoutSessionHit[]): number => {
  if (!hits.length) return 0
  return getTotalForce(hits) / hits.length
}

export const getRoundDuration = (round: INRWorkoutSessionRound): number =>
  round.exercises.flatMap((exercise) => exercise.seconds).reduce((a, b) => a + b, 0)

export const getWorkoutDuration = (workout: INRWorkoutSession): number =>
  workout.rounds.flatMap((round) => getRoundDuration(round)).reduce((a, b) => a + b, 0)

export const getRoundHits = (round: INRWorkoutSessionRound): INRWorkoutSessionHit[] => {
  return round.exercises.flatMap((exercise) => exercise.hits)
}

export const getWorkoutHits = (workoutSession: INRWorkoutSession): INRWorkoutSessionHit[] => {
  return workoutSession.rounds.flatMap(getRoundHits)
}

export const calcAvgSpeed = (numOfHits: number, duration: number): number => {
  return (numOfHits * 60) / duration
}

export const calcAvgSpeedPerExercise = (exercise: INRWorkoutSessionExercise): number => {
  const exerciseDuration = exercise.seconds
  const numOfHits = exercise.hits.length
  return calcAvgSpeed(numOfHits, exerciseDuration)
}

export const getAvgSpeedExerciseArray = (round: INRWorkoutSessionRound): number[] =>
  round.exercises.map(calcAvgSpeedPerExercise)

export const calcAvgSpeedPerRound = (round: INRWorkoutSessionRound): number => {
  const roundDuration = getRoundDuration(round)
  const roundHits = getRoundHits(round)
  return calcAvgSpeed(roundHits.length, roundDuration)
}

export const getAvgSpeedRoundArray = (workout: INRWorkoutSession): number[] => {
  return workout.rounds.map(calcAvgSpeedPerRound)
}

export const getWorkoutStats = (workout: INRWorkoutSession): INRStats => {
  const workoutHits = getWorkoutHits(workout)
  const duration = getWorkoutDuration(workout)
  const avgSpeedRounds = getAvgSpeedRoundArray(workout)
  const totalSpeed = sumUp(avgSpeedRounds)

  const avgSpeed = Math.round(totalSpeed / avgSpeedRounds.length)
  const maxSpeed = Math.round(Math.max(...avgSpeedRounds))
  const minSpeed = Math.round(Math.min(...avgSpeedRounds))

  const totalForce = Math.round(getTotalForce(workoutHits))
  const avgForce = Math.round(getAvgForce(workoutHits))
  const maxForce = Math.round(getMaxForce(workoutHits))
  const minForce = Math.round(getMinForce(workoutHits))

  return {
    duration,
    numOfHits: workoutHits.length,
    totalForce,
    maxForce,
    minForce,
    avgForce,
    avgSpeed,
    maxSpeed,
    minSpeed,
  }
}

export const getRoundStats = (round: INRWorkoutSessionRound): INRStats => {
  const roundHits = getRoundHits(round)
  const duration = getRoundDuration(round)
  const avgSpeedExercises = getAvgSpeedExerciseArray(round)
  const totalSpeed = sumUp(avgSpeedExercises)

  const avgSpeed = Math.round(totalSpeed / avgSpeedExercises.length)
  const maxSpeed = Math.round(Math.max(...avgSpeedExercises))
  const minSpeed = Math.round(Math.min(...avgSpeedExercises))

  const totalForce = Math.round(getTotalForce(roundHits))
  const avgForce = Math.round(getAvgForce(roundHits))
  const maxForce = Math.round(getMaxForce(roundHits))
  const minForce = Math.round(getMinForce(roundHits))

  return {
    duration,
    numOfHits: roundHits.length,
    totalForce,
    maxForce,
    minForce,
    avgForce,
    avgSpeed,
    maxSpeed,
    minSpeed,
  }
}
