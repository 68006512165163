import { useEffect, useState } from 'react'
import SnackbarWrapper from './wrapper'
import { useConfigActions } from 'store/config/selectors'

const ConnectedSnackbar = () => {
  const { setActiveSnackbar } = useConfigActions()
  const [showSnackbar, setShowSnackbar] = useState(true)

  useEffect(() => {
    const firstTimeout = setTimeout(() => {
      setShowSnackbar(false)
    }, 5000) // trigger slide up animation

    const secondTimeout = setTimeout(() => {
      setActiveSnackbar(null)
    }, 10000) // remove from DOM

    return () => {
      clearTimeout(firstTimeout)
      clearTimeout(secondTimeout)
    }
  }, [setActiveSnackbar])

  return (
    <SnackbarWrapper status="success" showSnackbar={showSnackbar}>
      <h5>Success</h5>
      <h5 className="light">Connected</h5>
    </SnackbarWrapper>
  )
}

export default ConnectedSnackbar
