import { INRWorkoutSessionRound, INRWorkoutSessionHit, INRWorkoutSessionExercise } from 'interfaces/workout'
import { getBreakPoints } from './hooks/useWindowSize'
import { convertHitsToTimedHits } from './utilities'

// These functions should be aligned with the personal app
// In the future, we might want to build a shared library for these functions, also when we expand to group app or additional apps
export const getYaxisTicks = (maxForce: number) => {
  const maxForceExists = isFinite(maxForce)

  return {
    firstTick: maxForceExists ? Math.round((maxForce / 100) * 20) : null,
    secondTick: maxForceExists ? Math.round((maxForce / 100) * 40) : null,
    thirdTick: maxForceExists ? Math.round((maxForce / 100) * 60) : null,
    fourthTick: maxForceExists ? Math.round((maxForce / 100) * 80) : null,
    fifthTick: maxForceExists ? maxForce : null,
  }
}

export const getXaxisTicks = (firstValue: number, lastValue: number) => {
  const secondValue = firstValue + ((lastValue - firstValue) / 100) * 25
  const thirdValue = firstValue + ((lastValue - firstValue) / 100) * 50
  const fourthValue = firstValue + ((lastValue - firstValue) / 100) * 75

  return { secondValue, thirdValue, fourthValue }
}

export const getBarsData = (exercise: INRWorkoutSessionExercise) => {
  const { seconds, hits, started } = exercise
  const timedHits = convertHitsToTimedHits(hits, started)
  const barsData = []
  let start = 0

  while (start <= seconds) {
    const bars = timedHits[start] ? timedHits[start].map((hit) => ({ force: hit.force })) : { force: 0 }
    barsData.push({ time: start, bars })
    start++
  }

  return barsData
}

export const getExerciseBarsData = ({ seconds, hits, started }: INRWorkoutSessionExercise) => {
  let start = 0
  const barsPerSecond = []
  const timedHits = convertHitsToTimedHits(hits, started)

  while (start <= seconds) {
    let bars: { force: number | null }[] = []
    if (timedHits[start]) {
      bars = timedHits[start].map((hit) => ({ force: hit.force }))
    } else {
      bars = [{ force: null }]
    }

    barsPerSecond.push(bars)
    start++
  }

  return barsPerSecond.flat()
}

export const getRoundBarsData = (round: INRWorkoutSessionRound) => round.exercises.flatMap(getExerciseBarsData)

export const getBarsArray = (round: INRWorkoutSessionRound) => {
  const barsArray = round.exercises.map((ex: any) => {
    const max = ex.seconds
    let start = 1
    const barsPerSecond = []
    const timedHits = convertHitsToTimedHits(ex.hits, ex.started)
    while (start <= max) {
      let bars = null
      if (timedHits[start]) {
        bars = timedHits[start].map((hit: any) => {
          return { force: hit.force }
        })
      } else {
        bars = { force: 0 }
      }
      barsPerSecond.push(bars)
      start++
    }
    return barsPerSecond.flat()
  })
  return barsArray.flat()
}

export const getForceGraphData = (barsArray: { force: number | null }[]) => {
  const forceGraphData = barsArray.map((hit, index: number) => ({ force: hit.force, index }))
  return forceGraphData
}

export const getAverageForcePercent = (allHits: INRWorkoutSessionHit[]) => {
  const forceHits = allHits.map((hit) => hit.force)
  const maxForce = Math.round(Math.max(...forceHits))
  const avgForce = calcAverageForce(allHits)
  const avgForcePercent = Math.round((avgForce / maxForce) * 100)

  return !isNaN(avgForcePercent) ? avgForcePercent : 0
}

export const calcAverageForce = (allHits: INRWorkoutSessionHit[]) => {
  let total: number = 0
  allHits.forEach((hit) => (total += hit.force))
  return Math.round(total / allHits.length)
}

export const getGraphWidth = (width: number | undefined) => {
  const windowSize = getBreakPoints(width)
  if (windowSize === 'xlarge') {
    return 915
  } else if (windowSize === 'large') {
    return 805
  } else if (windowSize === 'medium') {
    return 625
  } else {
    return 560
  }
}
