import confettiImg from 'assets/images/confetti.png'
import PopupWrapper from '../wrapper/wrapper'
import Button from 'components/button'
import { useSetPopup } from 'store/config/selectors'

const WorkoutSavedPopup = () => {
  const { closePopup } = useSetPopup()

  return (
    <PopupWrapper>
      <div className="workoutSavedPopup__wrapper">
        <img src={confettiImg} alt="workout-saved" />
        <h2>Your data has been saved!</h2>
        <Button color="primary" customClickEvent={closePopup}>
          <h3>Go back to overview</h3>
        </Button>
      </div>
    </PopupWrapper>
  )
}

export default WorkoutSavedPopup
