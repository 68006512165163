import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PopupWrapper from '../wrapper/wrapper'
import Button from 'components/button'
import { useSetPopup } from 'store/config/selectors'

const CheckEmailPopup = () => {
  const { setForgotPasswordPopup } = useSetPopup()

  return (
    <PopupWrapper>
      <div className="popupCheckEmail">
        <h2>Check your mailbox!</h2>
        <h2 className="light">A verification email has been sent</h2>
        <h4 className="light">No mail received? Check your SPAM folder</h4>
        <Button customClickEvent={setForgotPasswordPopup}>
          <h3>
            <FontAwesomeIcon icon={faChevronLeft} />
            Try again
          </h3>
        </Button>
      </div>
    </PopupWrapper>
  )
}

export default CheckEmailPopup
