import React from 'react'
import Icon, { IconNames } from 'components/icons/icons'
import Button from 'components/button'
import { useIsloggedIn } from 'store/auth/selectors'
import { useSetPopup } from 'store/config/selectors'
import './login-btn.scss'

const LoginBtn = () => {
  const isLoggedIn = useIsloggedIn()
  const { setLoginPopup, setLogoutPopup } = useSetPopup()
  const togglePopup = !!isLoggedIn ? setLogoutPopup : setLoginPopup

  return (
    <div className="loginBtn">
      <Button icon color="text" customClickEvent={togglePopup}>
        <h3>{!!isLoggedIn ? 'Logout' : 'Login'}</h3>
        <Icon name={!!isLoggedIn ? IconNames.LOGOUT : IconNames.LOGIN} size={24} />
      </Button>
    </div>
  )
}

export default LoginBtn
