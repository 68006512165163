import { ReactNode } from 'react'

import { ConnectionIssueNames } from 'interfaces/connection'
import { useConnectionState } from 'store/config/selectors'
import {
  getConnectionColor,
  getConnectionIcon,
  getConnectionLabelText,
  getCurrentConnectionIssue,
} from 'lib/connection/connection'
import Icon from 'components/icons/icons'

const WifiInfo = () => (
  <h5>
    <h5>Low Wi-Fi connection</h5>
    <h5 className="light">Move the punching bag closer to the router or install an amplifier.</h5>
  </h5>
)

const PacketInfo = () => (
  <>
    <h5>Connection low</h5>
    <h5 className="light">Please check your internet quality or try to reset your router.</h5>
  </>
)

const RebootInfo = () => (
  <>
    <h5>Bag rebooting</h5>
    <h5 className="light">
      Your bag is rebooting. Please try to unplug the power adapter and then after 5 seconds plug it in. Then check if
      the bag is working. If not, try a different power adapter. This warning will automatically disappear after 60
      minutes if the power supply is fixed.
    </h5>
  </>
)

const PowerInfo = () => (
  <>
    <h5>Bag is not connected</h5>
    <h5 className="light">
      Please try the following steps in this order:
      <br />
      1. Reconnect power supply and check if bag reconnects automatically <br />
      2. Check if NextRound appears in the Wi-Fi list. Check installation instructions for reconnecting the bag to the
      Wi-Fi. <br />
      3. Check if your tablet is connected to the Wi-Fi and workouts/videos are loading. If these steps don’t work,
      please contact us.
    </h5>
  </>
)

const GoodConnectionInfo = () => (
  <>
    <h5>Everything seems to be working! Enjoy!</h5>
    <h5 className="light">Still not working like it should? Try restarting this app and/or the tablet.</h5>
  </>
)

export const ConnectionLabel = () => {
  const connection = useConnectionState()
  const connectionIssue = getCurrentConnectionIssue(connection)

  const color = getConnectionColor(connectionIssue?.status)
  const text = getConnectionLabelText(connectionIssue?.status)
  const iconName = getConnectionIcon(connectionIssue?.status)

  return (
    <span className="connectionLabel" data-color={color}>
      <Icon name={iconName} size={36} />
      <h5>{text}</h5>
    </span>
  )
}

export const ConnectionInfo = () => {
  const connection = useConnectionState()
  const { status, issue } = getCurrentConnectionIssue(connection)
  const color = getConnectionColor(status)

  const connectionInfoMap: Record<ConnectionIssueNames, ReactNode> = {
    [ConnectionIssueNames.WIFI]: <WifiInfo />,
    [ConnectionIssueNames.PACKET]: <PacketInfo />,
    [ConnectionIssueNames.REBOOT]: <RebootInfo />,
    [ConnectionIssueNames.POWER]: <PowerInfo />,
    [ConnectionIssueNames.NONE]: <GoodConnectionInfo />,
  }

  return (
    <div className="connectionInfo" data-color={color}>
      {connectionInfoMap[issue]}
    </div>
  )
}
