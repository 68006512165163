import { useSubscription, gql } from '@apollo/client'

import { INRWorkoutSessionHit } from 'interfaces/workout'

const HIT = gql`
  subscription hit {
    hit {
      force
      angle
      height
      punchedAt
    }
  }
`

export const useHitSubscription = () => useSubscription<{ hit: INRWorkoutSessionHit }>(HIT)
