import { gql } from '@apollo/client'

export const CHALLENGE_TEMPLATE = gql`
  query challengeTemplate($id: String!) {
    challengeTemplate(input: { id: $id }) {
      leaderboard {
        id
        records {
          id
          score
          user {
            id
          }
        }
      }
    }
  }
`
