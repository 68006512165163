import React, { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

import { useConfigActions, useIsSoundActive, usePause, useSetPopup } from 'store/config/selectors'
import { pathsObj } from 'lib/constants/paths'
import Icon, { IconNames } from 'components/icons/icons'
import { useIsloggedIn } from 'store/auth/selectors'
import ConnectionIndicator from 'components/connection-indicator/connection-indicator'

// TODO change title to be a string or renderable component
const Header = ({ title = <></> }: { title?: ReactNode }) => {
  const navigate = useNavigate()
  const isLoggedIn = useIsloggedIn()
  const isSoundActive = useIsSoundActive()
  const { toggleSound } = useConfigActions()
  const { pause } = usePause()
  const { setLogoutPopup } = useSetPopup()

  const handleHomeRedirect = () => {
    navigate(pathsObj.main.home)
  }

  const handleLogoutPopup = () => {
    pause()
    setLogoutPopup()
  }

  return (
    <div className="header">
      <div className="header__logo">
        <Icon name={IconNames.LOGO_SMALL} size={65} customClickEvent={handleHomeRedirect} />
      </div>
      <div className="header__title">{title}</div>
      <div className="header__tools">
        <ConnectionIndicator />
        {!!isLoggedIn && <Icon name={IconNames.LOGOUT} size={48} customClickEvent={handleLogoutPopup} />}
        <Icon name={isSoundActive ? IconNames.SPEAKER : IconNames.MUTE} size={48} customClickEvent={toggleSound} />
      </div>
    </div>
  )
}

export default Header
