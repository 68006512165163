import { CSSProperties } from 'react'

interface Props {
  currentIntensityValue: number
  intensityNumber: number
}

const intensityColors: { [key: number]: string } = {
  1: '#60df63',
  2: '#b5f342',
  3: '#fbf926',
  4: '#fdbf24',
  5: '#ff8822',
  6: '#ff6421',
  7: '#ff4921',
}

const SingleIntensity = ({ currentIntensityValue, intensityNumber }: Props) => {
  const isActive = currentIntensityValue === intensityNumber
  const isPassed = currentIntensityValue > intensityNumber
  const isColored = isActive || isPassed
  const color = intensityColors[intensityNumber]
  const style = { '--background-color': isColored ? color : 'transparent' } as CSSProperties

  return (
    <div className="singleIntensity__wrapper">
      <div className={`singleIntensity__number ${isActive ? 'singleIntensity__number--active' : ''}`}>
        {intensityNumber}
      </div>
      <div className={`singleIntensity__color ${!isColored ? 'singleIntensity__color--empty' : ''}`} style={style} />
    </div>
  )
}

export default SingleIntensity
