import Button from 'components/button'
import Icon, { IconNames } from 'components/icons/icons'

interface SingleSettingProps {
  value: number
  name: string
  handleAdd: () => void
  handleRemove: () => void
}

interface SetupFreestyleRoundsProps {
  rounds: number
  seconds: number
  rest: number
  addSeconds: () => void
  removeSeconds: () => void
  addRound: () => void
  removeRound: () => void
  addRest: () => void
  removeRest: () => void
}

const FreestyleSingleSetting = ({ value, name, handleAdd, handleRemove }: SingleSettingProps) => {
  return (
    <div className="setupFreestyleRounds__setting">
      <div className="setupFreestyleRounds__setting__buttons">
        <h4>{value}</h4>
        <Button square icon customClickEvent={handleRemove}>
          <Icon name={IconNames.MINUS} size={15} />
        </Button>
        <Button square icon customClickEvent={handleAdd}>
          <Icon name={IconNames.PLUS} size={20} />
        </Button>
      </div>
      <h4 className="light">{name}</h4>
    </div>
  )
}

const SetupFreestyleRounds = ({
  rounds,
  seconds,
  rest,
  addSeconds,
  removeSeconds,
  addRound,
  removeRound,
  addRest,
  removeRest,
}: SetupFreestyleRoundsProps) => {
  return (
    <div className="setupFreestyleRounds">
      <h2 className="light">Set up Freestyle training</h2>
      <FreestyleSingleSetting name="Rounds" value={rounds} handleAdd={addRound} handleRemove={removeRound} />
      <FreestyleSingleSetting
        name="Seconds per round"
        value={seconds}
        handleAdd={addSeconds}
        handleRemove={removeSeconds}
      />
      <FreestyleSingleSetting
        name="Seconds rest time between rounds"
        value={rest}
        handleAdd={addRest}
        handleRemove={removeRest}
      />
    </div>
  )
}

export default SetupFreestyleRounds
