import { ReactNode } from 'react'

import { SnackbarNames } from 'interfaces/snackbar'
import useConfigStore from 'store/config'
import ConnectedSnackbar from './connected'
import RebootingSnackbar from './rebooting'
import ConnectionFailedSnackbar from './connection-failed'

const snackbarObj: Record<SnackbarNames, ReactNode> = {
  [SnackbarNames.CONNECTED]: <ConnectedSnackbar />,
  [SnackbarNames.REBOOTING]: <RebootingSnackbar />,
  [SnackbarNames.CONNECTION_FAILED]: <ConnectionFailedSnackbar />,
}

const Snackbar = () => {
  const { activeSnackbar } = useConfigStore()
  if (!activeSnackbar) return null

  return <>{snackbarObj[activeSnackbar as SnackbarNames]}</>
}

export default Snackbar
