// Calculate the final avg speed of hits in one exercise.

import { INRWorkoutSessionHit } from 'interfaces/workout'

/*
    We only need the following two parameters: 
    t = time in seconde
    h = total hits in that round

    Let's say I start a round, and after 5 seconds my total hits are 20. 
    The following formula will say how fast I was over the last 5 seconds: 

    Speed at t(5) = (h at t(5) - h at t(0)) * 12
    In that example, it would be: 
    Speed at t(5) = (20 - 0) * 12 = 240 hits/minute

    Let's say after 1 second I had already 8 hits and after 6 seconds I had 22 hits. 
    My speed at t(6) would be: (22-8)*12 = 168 hits/minute 

    after 7 seconds I had 24 hits and after 12 seconds I had 30 hits. 
    My speed at t(12) would be: (30-24)*12 = 240 hits/minute
*/
export function calcHitsAvgSpeedOver5sec(exerciseDuration: number, hits: INRWorkoutSessionHit[]): number {
  if (hits.length === 0) return 0

  const currentDate = new Date().getTime()
  let p1: number = 0,
    p2: number = 0

  if (exerciseDuration <= 5) {
    p1 = 0
    p2 = hits.length
  } else if (exerciseDuration >= 5) {
    p1 = hits.filter((h: any) => currentDate - h.punchedAt >= 5000).length
    p2 = hits.length
  } else {
    p1 = 0
    p2 = 0
  }

  const avg: number = (p2 - p1) * 12

  return Number(avg.toFixed(2))
}

// Calculate the excercise duration in seconds.
// It runs every time the counter changes
export function calcExerciseDuration(currentCount: number, exerciseAsSeconds: number): number {
  return currentCount - exerciseAsSeconds
}
