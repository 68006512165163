import NRVideo from 'components/nr-video'
import { Spinner } from 'components/spinner/spinner'

interface Props {
  className?: string
  imageLink?: string
  videoUrl?: null | string
  isVideoAutoPlay: boolean
  onVideoEnd?: () => void
  onVideoError?: () => void
  loading?: boolean
}

// workouts, rounds and exercises can have a video or img (or neither) to explain to the user what is going to happen
const NRMedia = ({
  className = '',
  imageLink = '',
  videoUrl = '',
  isVideoAutoPlay,
  onVideoEnd,
  onVideoError,
  loading,
}: Props) => {
  if (loading) return <Spinner className={className} />
  if (!!videoUrl) {
    return (
      <NRVideo
        className={className}
        src={videoUrl ?? ''}
        isAutoPlay={isVideoAutoPlay}
        onVideoEnd={onVideoEnd}
        onError={onVideoError}
      />
    )
  }

  if (!!imageLink) return <img className={className} src={imageLink} alt="media" />

  return <img alt="" />
}

export default NRMedia
