import Button from 'components/button'
import SetupFreestyleRounds from './setup-rounds'
import useFreestyleSetup from 'lib/hooks/useFreestyleSetup'

interface Props {
  startFreestyle: () => void
}

const LoggedIn = ({ startFreestyle }: Props) => {
  const { rounds, seconds, rest, addSeconds, removeSeconds, addRound, removeRound, addRest, removeRest } =
    useFreestyleSetup()

  return (
    <>
      <SetupFreestyleRounds
        rounds={rounds}
        seconds={seconds}
        rest={rest}
        addSeconds={addSeconds}
        removeSeconds={removeSeconds}
        addRound={addRound}
        removeRound={removeRound}
        addRest={addRest}
        removeRest={removeRest}
      />
      <div className="popupFreestyle__buttons">
        <Button fullColor customClickEvent={startFreestyle} className="popupFreestyle__buttons--right">
          <h3>Start Freestyle</h3>
        </Button>
      </div>
    </>
  )
}

export default LoggedIn
