import React from 'react'

import { toRoundedFullTime } from '../../lib/utilities'
import Icon, { IconNames } from '../icons/icons'
import { useCurrentExercise } from 'store/workout/selectors'
import './challenge-card.scss'

export const ChallengeCard = () => {
  const { challenges, seconds } = useCurrentExercise()
  const { title, description } = challenges![0]
  return (
    <div className="challenge-card">
      <div className="challenge-card__wrapper">
        <div className="challenge-card__title">
          <h3>Challenge</h3>
          <h2>{title}</h2>
          <Icon name={IconNames.INFO_WHITE} size={40} />
        </div>
        <div className="challenge-card__content">
          <Icon name={IconNames.CHALLENGE_GLOVES} size={140} />
          <div className="challenge-card__content__text">
            <h4>{description}</h4>
            <h5 className="challenge-card__content__time light">
              <Icon name={IconNames.CLOCK} size={20} />
              {toRoundedFullTime(seconds)}
            </h5>
          </div>
        </div>
      </div>
    </div>
  )
}
