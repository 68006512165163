import React from 'react'
import AnimatedNumber from 'animated-number-react'

import { getTotalForce } from 'lib/stats'
import { calcHitsAvgSpeedOver5sec } from 'lib/calc-punch-speed'
import { useCurrentCount, useCurrentExercise } from 'store/workout/selectors'

const StatsRoundActive = () => {
  // useCurrentCount to force rerender, to live update the speed stats
  useCurrentCount()
  const { seconds, hits } = useCurrentExercise()

  const avgSpeed = calcHitsAvgSpeedOver5sec(seconds, hits)
  const totalForce = Math.round(getTotalForce(hits))
  const totalHits = hits.length
  const liveSpeed = avgSpeed ?? 0
  const lastForce = totalHits ? Math.round(hits[totalHits - 1].force) : 0

  return (
    <div className="statsRoundActive">
      <div className="statsRoundActive__total">
        <h2 className="light">Speed</h2>
        <div className="statsRoundActive__box">
          <div className="statsRoundActive__singleStat">
            <h3 className="key light">Total hits</h3>
            <h3 className="value">{totalHits}</h3>
          </div>
          <div className="statsRoundActive__border" />
          <div className="statsRoundActive__singleStat">
            <h3 className="key light">Live speed</h3>
            <h3 className="value">{liveSpeed}</h3>
          </div>
        </div>
      </div>
      <div className="statsRoundActive__last">
        <h2 className="light">Force</h2>
        <div className="statsRoundActive__box">
          <div className="statsRoundActive__singleStat">
            <h3 className="key light">Total</h3>
            <h3 className="value">
              <AnimatedNumber value={totalForce} formatValue={(value: number) => value.toFixed(0)} duration={300} />
            </h3>
          </div>
          <div className="statsRoundActive__border" />
          <div className="statsRoundActive__singleStat">
            <h3 className="key light">Last</h3>
            <h3 className="value">{lastForce}</h3>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StatsRoundActive
