import { CounterSizes } from '../components/counter/counter'

const getCounterSize = (screenWidth: number) => {
  return screenWidth >= 1280
    ? CounterSizes.SMALL
    : screenWidth >= 1024
    ? CounterSizes.SMALL
    : CounterSizes.XSMALL
}

export default getCounterSize
